import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
  combineReducers,
} from "redux";

import thunk from "redux-thunk";
import authReducer from "./Authentication/auth.reducer.js";
import singleProductReducer from "./SingleProduct/singleProduct.reducer.js";
import profileReducer from "./Profile/profile.reducer.js";
import ownerShipReducer from "./OwnerShip/ownersip.reducer.js";
import userInfoReducer from "./UserInformation/userInfo.reducers.js";

import PackagingReducer from "./Packaging/packaging.reducer.js";

import productInventoryReducer from "./ProductInventory/productInventory.reducer.js";
import trackingReducer from "./Tracking/tracking.reducer.js";
import inventoryOwnerShipReducer from "./InventoryOwnership/InventoryOwnership.reducer.js";
import PackageOwnerShipReducer from "./PackageOwnership/PackageOwnership.reducer.js";

import packagingLevel1Reducer from "./PackagingLevel1/packagingLevel1.reducer.js";
import dashboardReducer from "./Dashboard/dashborad.reducer.js";
import landingReducer from "./Landing/landing.reducer.js";
import WareHouseReducer from "./Warehouse/warehouse.reducer.js";
import NextOwnerReducer from "./NextOwner/nextowner.reducer.js";
import warrantyReducer from "./Warranty/warranty.reducer.js";
import SupportReducer from "./Support/support.reducer.js";
import LogisticReportReducer from "./LogisticReports/logictic.reducer.js";
import TransportationReducer from "./Transportation/transportation.reducer.js";
import AddUserReducer from "./AddUser/adduser.reducer.js";
import ReportReducer from "./Report/report.reducer.js";
import explorerReducer from "./Explorer/explorer.reducer.js";


const rootReducer = combineReducers({
  authManager: authReducer,
  singleProductManager: singleProductReducer,
  profileManager: profileReducer,
  ownerShipManager: ownerShipReducer,
  userInfoManager: userInfoReducer,
  PackagingManager: PackagingReducer,
  productInventoryManager: productInventoryReducer,
  trackingReducer: trackingReducer,
  inventoryOwnerShipManager: inventoryOwnerShipReducer,
  packageOwnershipManager: PackageOwnerShipReducer,
  packagingLevel1Manager: packagingLevel1Reducer,
  dashboardManager: dashboardReducer,
  LandingReducer: landingReducer,
  WareHouseManager: WareHouseReducer,
  NextOwnerManager: NextOwnerReducer,
  warrantyReducer: warrantyReducer,
  SupportManager: SupportReducer,
  LogisticReportManager: LogisticReportReducer,
  TransportationManager:TransportationReducer,
  AddUserManager:AddUserReducer,
  ReportManager: ReportReducer,
  ExplorerManager:explorerReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
