import React from "react";
import exclamatory from "../../../assets/images/icons/exclamatory-icon.svg";
import apiDocHead from "../../../assets/images/icons/api-doc-head.svg";
import PageList from "../../../reusable_components/pageList";
import Navbar from "../../../layouts/navbar";
import BottomNavbar from "../../../layouts/bottomNavbar";
import DocSidebar from "../docSidebar";
import urlPasteBlack from "../../../assets/images/icons/url-paste-black.svg";

export default function ApiErrorHandling() {
  return (
    <div className="paye-layout">
      <PageList />
      <div className="content-layout">
        <Navbar />
        <section className="product-screen api-doc-screen">
          <DocSidebar />

          <div className="w-100 api-document m-0">
            <div className="api-doc-head d-flex align-items-center gap-2 gx-0">
              <img src={apiDocHead} alt="" />
              <span className="fw600 fz28 text-dark">Error Handling</span>
            </div>

            <div className="documentation">
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                Exceptions allow error handling to be organized cleanly in a
                central or high-level place within the program structure.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                In each case, Holografs will return a response with an
                appropriate status code and content-type. The body of the
                response will include any additional details regarding the
                nature of the error.
              </h3>
              <br />
              <div className="black-box-inside mb-2">
                <div className="d-flex align-items-center gap-2">
                  <a className="btn btn-gradient-border endpoints-insideCardBtn rounded-1">
                    <div className="fw400 fz14">
                      <div className="px-3 gradientText--radial">
                        <span className="fz16 fw600">Example</span>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="endpoints-inside-text">
                  <p className="text-green fw400 fz12 mb-0"></p>

                  <div className="fz12 array-values">
                    <div className="orange-text">
                      Curl -X DELETE http://api.example.com/foo/bar <br />
                      Accept: application/json
                    </div>
                  </div>
                </div>
              </div>{" "}
              <br />
              <div className="black-box-inside mb-2">
                <div className="d-flex align-items-center gap-2">
                  <a className="btn btn-gradient-border endpoints-insideCardBtn rounded-1">
                    <div className="fw400 fz14">
                      <div className="px-3 gradientText--radial">
                        <span className="fz16 fw600">Response</span>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="endpoints-inside-text">
                  <p className="text-green fw400 fz12 mb-0"></p>

                  <div className="fz12 array-values">
                    <div className="orange-text">
                      Curl -X DELETE http://api.example.com/foo/bar Accept:
                      application/json <br />
                      HTTP/1.1 405 Method Not Allowed <br />
                      Content-Type: application/json <br />
                      Content-Length: 42
                    </div>{" "}
                    <br />
                    <div className="grayText fz20">
                      <span className="pink-text">
                        {"{"}
                        <br />
                      </span>
                      "detail": "Method 'DELETE' not allowed."
                      <br />
                      <span className="pink-text">{"}"}</span>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <br />
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                Validation errors are handled slightly differently, and will
                include the field names as the keys in the response. If the
                validation error was not specific to a particular field then it
                will use the "non_field_errors" key, or whatever string value
                has been set for the NON_FIELD_ERRORS_KEY setting.
              </h3>
              <br />
              <br />
              <div className="black-box-inside mb-2">
                <div className="d-flex align-items-center gap-2">
                  <a className="btn btn-gradient-border endpoints-insideCardBtn rounded-1">
                    <div className="fw400 fz14">
                      <div className="px-3 gradientText--radial">
                        <span className="fz16 fw600">Example</span>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="endpoints-inside-text">
                  <p className="text-green fw400 fz12 mb-0"></p>

                  <div className="fz12 array-values">
                    <div className="orange-text">
                      HTTP/1.1 400 Bad Request <br />
                      Content-Type: application/json <br />
                      Content-Length: 94
                    </div>{" "}
                    <br />
                    <div className="grayText fz20">
                      <span className="pink-text">
                        {"{"}
                        <br />
                      </span>{" "}
                      "amount": <span className="pink-text">[</span>"A valid
                      integer is required."<span className="pink-text">]</span>,
                      <br />
                      "description": <span className="pink-text">[</span> "This
                      field may not be blank."
                      <span className="pink-text">]</span>
                      <br />
                      <span className="pink-text">{"}"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <BottomNavbar />

      {/* <!-- Modal --> */}
      <div
        className="modal fade m-modal"
        id="sureModal"
        tabindex="-1"
        aria-labelledby="sureModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="border-0 modal-content">
            <div className="modal_wrapper border-white-2 bdrs10 main-shadow">
              <div className="main-block">
                <div className="content-inner">
                  <img src={exclamatory} alt="" className="mx-auto" />

                  <div className="inner-text">
                    <h3 className="fz60 fw300">Are You Sure!</h3>
                    <p className="fz46 mb-0 lh-1 fw600">
                      You want to cancel the process?
                    </p>
                  </div>

                  <div className="btn-row">
                    <button
                      data-bs-dismiss="modal"
                      className="btn my-2 fz14 bdrs10 btn-dark-outlined black-default"
                    >
                      <span className="fz14 gradientText--radial fw400">
                        No!
                      </span>
                    </button>

                    <a
                      href="./index.html"
                      className="btn my-2 fz14 bdrs10 btn-dark-outlined modal-confirm-btn"
                    >
                      <span className="fz14">Yes</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
