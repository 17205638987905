import {
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FAILURE,
  ADD_ITEM_SUCCESS,
  ADD_ITEM_FAILURE,
  VERIFY_OWNER_SUCCESS,
  VERIFY_OWNER_FAILURE,
  CHANGE_OWNER_FAILURE,
  CHANGE_OWNER_SUCCESS,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  GET_OWNER_DETAIL_BY_ID_SUCCESS,
  GET_OWNER_DETAIL_BY_ID_FAILURE,
  GET_USER_BY_ID_FAILURE,
  GET_USER_BY_ID_SUCCESS,
  LOADER_FALSE,
  LOADER_TRUE,
  GET_ITEM_FROM_RESP_SUCCESS,
  GET_ITEM_FROM_RESP_FAILURE,
  UPDATE_BATCH_FAILURE,
  UPDATE_BATCH_SUCCESS,
  GET_ALL_CATEGORY_SUCCESS,
  GET_ALL_CATEGORY_FAILURE,
  PRODUCT_BY_ID_SUCCESS,
  PRODUCT_BY_ID_FAILURE,
} from "./productInventory.actionTypes";

const initialState = {
  allProducts: [],
  addItems: null,
  ownerList: [],
  loader: false,
  itemsFromResp: {},
  Batch: {},
  categoryList: null,
  productById: [],
};

export default function productInventoryReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        allProducts: payload,
      };

    case GET_ALL_PRODUCTS_FAILURE:
      return state;

    case ADD_ITEM_SUCCESS:
      return {
        ...state,
        addItems: payload,
      };

    case ADD_ITEM_FAILURE:
      return state;

    case GET_ITEM_FROM_RESP_SUCCESS:
      return {
        ...state,
        itemsFromResp: payload,
      };

    case GET_ITEM_FROM_RESP_FAILURE:
      return state;

    case UPDATE_BATCH_SUCCESS:
      return {
        ...state,
        Batch: payload,
      };

    case UPDATE_BATCH_FAILURE:
      return state;

    case VERIFY_OWNER_SUCCESS:
      return {
        ...state,
        verifyOwner: payload,
      };

    case VERIFY_OWNER_FAILURE:
      return state;

    case CHANGE_OWNER_SUCCESS:
      return {
        ...state,
        newOwnerDetails: payload,
      };

    case LOADER_TRUE:
      return {
        ...state,
        loader: true,
      };

    case LOADER_FALSE:
      return {
        ...state,
        loader: false,
      };

    case CHANGE_OWNER_FAILURE:
      return state;

    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        productById: payload,
      };
    case GET_PRODUCT_FAILURE:
      return {
        ...state,
        message: "",
      };
    case GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        ownerList: payload,
      };
    case GET_USER_BY_ID_FAILURE:
      return {
        ...state,
        message: "",
      };
      case GET_ALL_CATEGORY_SUCCESS:
        return {
          ...state,
          categoryList: payload,
        };
      case GET_ALL_CATEGORY_FAILURE:
        return {
          ...state,
          message: "",
        };
        case PRODUCT_BY_ID_SUCCESS:
          return {
            ...state,
            productById: payload,
          };
        case PRODUCT_BY_ID_FAILURE:
          return {
            ...state,
            message: "",
          };

    default:
      return state;
  }
}
