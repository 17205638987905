import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import apiDocHead from "../../assets/images/icons/api-doc-head.svg";

export default function DocSidebar() {
  let { pathname } = useLocation();
  const [active, setActive] = useState("");
  const [showContent, setShowContent] = useState("");

  const introductionComponent = ["/doc-introduction"];
  const endPointPagesComponent = ["/doc-api-endpoints"];
  const authenticationComponent = ["/doc-authentication"];
  const reqAndResComponent = ["/doc-formats"];
  const usageComponent = ["/doc-apiusage"];
  const errorHandlingComponent = ["/doc-errorhandling"];
  const keyGenerateComponent = ["/doc-key-generation"];
  const keyManagementComponent = ["/doc-key-management"];
  const keySecurityComponent = ["/doc-key-security-guidelines"];
  const sdksComponent = ["/doc-sdks"];
  
  useEffect(() => {
    switch (true) {
      case introductionComponent.includes(pathname):
        setActive("introduction");
        break;
      case endPointPagesComponent.includes(pathname):
        setActive("endpoint");
        break;
      case authenticationComponent.includes(pathname):
        setActive("authentication");
        break;
      case reqAndResComponent.includes(pathname):
        setActive("reqAndRes");
        break;
      case usageComponent.includes(pathname):
        setActive("apiUsage");
        break;
      case errorHandlingComponent.includes(pathname):
        setActive("errorhandling");
        break;
      case keyGenerateComponent.includes(pathname):
        setActive("keyGenerate");
        break;
      case keyManagementComponent.includes(pathname):
        setActive("keyManagement");
        break;
      case keySecurityComponent.includes(pathname):
        setActive("securityGuidelines");
        break;
      case sdksComponent.includes(pathname):
        setActive("sdks");
        break;
      default:
    }
  }, [active]);

  const introduction = ["/doc-introduction","/doc-api-endpoints","/doc-authentication","/doc-formats","/doc-apiusage","/doc-errorhandling" ];
  const keyManagement = ["/doc-key-generation","/doc-key-management", "/doc-key-security-guidelines"];
  const developerRes = ["/doc-sdks"];

  useEffect(() => {
    switch (true) {
      case introduction.includes(pathname):
        setShowContent("documentation");
        break;
        case keyManagement.includes(pathname):
          setShowContent("keyManagement");
          break;
      case developerRes.includes(pathname):
        setShowContent("developerResource");
        break;
      default:
    }
  }, [showContent]);

  return (
    <div className="bdrs10 border-white-2 block-widget product-screen__graphics support__right document-title">
      <div className="block-wavy-dark api-integrationBg">
        <div className="lh-1 left">
          <h3 className="mb-1 gradientText--radial">
            <span className="fw600 lh1-5 fz24">API Integration Document</span>
          </h3>
          {/* <p className="text-white m-0 fw400 fz14">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy{" "}
          </p> */}
        </div>
      </div>

      <div
        className="ps-0 mt-2 accordion gap-0"
        id="accordionPanelsStayOpenExample"
      >
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed text-dark"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              <div className="title-list d-flex align-items-center gap-3">
                <img src={apiDocHead} alt="" />
                <span className="fz16 fw600">API Documentation</span>
              </div>
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseOne"
            // className="accordion-collapse collapse show"
            className={`accordion-collapse collapse ${showContent === "documentation" ? "show" : ""}`}
          >
            <div className="accordion-body">
              <div className="m-0 position-relative">
                <div className="vertical-line"></div>
                <ul className="list-inline m-0 doc-links fz14">
                  <li className="fz14 fw400">
                    <Link
                      to="/doc-introduction"
                      className={`${active === "introduction" ? "active" : ""}`}
                    >
                      Introduction
                    </Link>
                  </li>
                  <li className="fz14 fw400">
                    <Link
                      to="/doc-api-endpoints"
                      className={`${active === "endpoint" ? "active" : ""}`}
                    >
                      API Endpoints
                    </Link>
                  </li>
                  <li className="fz14 fw400">
                    <Link
                      to="/doc-authentication"
                      className={`${
                        active === "authentication" ? "active" : ""
                      }`}
                    >
                      Authentication
                    </Link>
                  </li>
                  <li className="fz14 fw400">
                    <Link
                      to="/doc-formats"
                      className={`${active === "reqAndRes" ? "active" : ""}`}
                    >
                      Request and Response Formats
                    </Link>
                  </li>
                  <li className="fz14 fw400">
                    <Link
                      to="/doc-apiusage"
                      className={`${active === "apiUsage" ? "active" : ""}`}
                    >
                      Usage Examples
                    </Link>
                  </li>
                  <li className="fz14 fw400">
                    <Link
                      to="/doc-errorhandling"
                      className={`${
                        active === "errorhandling" ? "active" : ""
                      }`}
                    >
                      Error Handling
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed text-dark"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseTwo"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseTwo"
            >
              <div class="title-list d-flex align-items-center gap-3" >
                <img src={apiDocHead} alt="" />
                <span class="fz16 fw600">API Key Managment</span>
              </div>
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseTwo"
            // className="accordion-collapse collapse"
            className={`accordion-collapse collapse ${showContent === "keyManagement" ? "show" : ""}`}
          >
            <div className="accordion-body">
              <div className="m-0 position-relative">
                <div className="vertical-line"></div>
                <ul className="list-inline m-0 doc-links fz14">
                  <li className="fz14 fw400">
                    <Link
                      to="/doc-key-generation"
                      className={`${active === "keyGenerate" ? "active" : ""}`}
                    >
                      API Key Generation
                    </Link>
                  </li>
                  <li className="fz14 fw400">
                    <Link
                      to="/doc-key-management"
                      className={`${
                        active === "keyManagement" ? "active" : ""
                      }`}
                    >
                      Key Management
                    </Link>
                  </li>
                  <li className="fz14 fw400">
                    <Link
                      to="/doc-key-security-guidelines"
                      className={`${
                        active === "securityGuidelines" ? "active" : ""
                      }`}
                    >
                      Security Guidelines
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed text-dark"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseThree"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseThree"
            >
              <div className="title-list d-flex align-items-center gap-3" >
                <img src={apiDocHead} alt="" />
                <span className="fz16 fw600">Developer Resources</span>
              </div>
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseThree"
            // className="accordion-collapse collapse"
            className={`accordion-collapse collapse ${showContent === "developerResource" ? "show" : ""}`}
          >
            <div className="accordion-body">
              <div className="m-0 position-relative">
                <div className="vertical-line"></div>
                <ul className="list-inline m-0 doc-links fz14">
                  <li className="fz14 fw400">
                    <span>
                      {" "}
                      <Link
                        to="/doc-sdks"
                        className={`${active === "sdks" ? "active" : ""}`}
                      >
                        SDKs and Libraries
                      </Link>
                    </span>
                  </li>
                  {/* <li className="fz14 fw400">
                          <a href="#">Code Samples</a>
                        </li>
                        <li className="fz14 fw400">
                          <a href="#">Getting Started</a>
                        </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
