import {
  ADD_WAREHOUSE_FAILURE,
  ADD_WAREHOUSE_SUCCESS,
  GET_ALL_WAREHOUSE_FAILURE,
  GET_ALL_WAREHOUSE_SUCCESS,
  GET_SINGLE_WAREHOUSE_FAILURE,
  GET_SINGLE_WAREHOUSE_SUCCESS,
} from "./warehouse.actiontypes";

const initialState = {
    addedwareHouseID:null,
    warehouseDetails:{},
    AllWarehouses:[]
};

export default function WareHouseReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ADD_WAREHOUSE_SUCCESS:
      return {
        ...state,
        addedwareHouseID: payload,
      };
    case ADD_WAREHOUSE_FAILURE:
      return {
        ...state,
        addedwareHouseID: null,
      };
    case GET_SINGLE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouseDetails: payload,
      };

    case GET_SINGLE_WAREHOUSE_FAILURE:
      return {
        ...state,
        warehouseDetails: {},
      };

    case GET_ALL_WAREHOUSE_SUCCESS:
      return {
        ...state,
        AllWarehouses: payload,
      };

    case GET_ALL_WAREHOUSE_FAILURE:
      return {
        ...state,
        AllWarehouses: [],
      };

    default:
      return state;
  }
}
