import {
  GET_INVENTORY_OWNER_SUCCESS,
  GET_INVENTORY_OWNER_FAILURE,
  REQUEST_INVENTORY_OWNERSHIP_SUCCESS,
  REQUEST_INVENTORY_OWNERSHIP_FAILURE,
  APPROVE_INVENTORY_OWNERSHIP_SUCCESS,
  APPROVE_INVENTORY_OWNERSHIP_FAILURE,
  GET_INVENTORY_OWNERSHIP_STATUS_SUCCESS,
  GET_INVENTORY_OWNERSHIP_STATUS_FAILURE,
  GET_INVENTORY_ALL_SENT_REQUEST_SUCCESS,
  GET_INVENTORY_ALL_SENT_REQUEST_FAILURE,
  GET_INVENTORY_OWNER_DETAILS_SUCCESS,
  GET_INVENTORY_OWNER_DETAILS_FAILURE,
  GET_INVENTORY_ALL_RECEIVED_REQUEST_SUCCESS,
  GET_INVENTORY_ALL_RECEIVED_REQUEST_FAILURE,
} from "./InventoryOwnership.actionTypes";

import {
  GET_ALL_INVENTORY_SENT_REQUEST,
  GET_ALL_INVENTORY_REQUEST,
  APPROVE_INVENTORY_OWNERSHIP,
  GET_INVENTORY_OWNERSHIP_STATUS,
  REQUEST_INVENTORY_OWNERSHIP,
  GET_INVENTORY_OWNER,
  GET_USER_DETAILS,
} from "../../services/Api";
import { getAllProductsById } from "../Tracking/tracking.actionTypes";
import axios from "axios";
import { toast } from "react-toastify";
import SuccessToast from "../../reusable_components/SuccessToast.jsx";

export const getInventoryOwnerDetails = (id, from_date) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const userEmail = JSON.parse(localStorage.getItem("userEmail")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let res = await axios.get(`${GET_USER_DETAILS}/${id}`, {
      headers,
    });
    let ownerDetails = res.data;
    dispatch({
      type: GET_INVENTORY_OWNER_DETAILS_SUCCESS,
      payload: {
        ownerDetails: { ...ownerDetails, from_date },
      },
    });
    // if (userEmail == ownerDetails?.email) {
    //   console.log("MATCHED");
    //   dispatch({
    //     type: GET_OWNER_DETAILS_SUCCESS,
    //     payload: {
    //       ownerDetails: { ...ownerDetails, from_date },
    //     },
    //   });
    // } else {
    //   console.log("NOT MATCHED");
    //   dispatch({
    //     type: GET_OWNER_DETAILS_SUCCESS,
    //     payload: {
    //       ownerDetails: { ...ownerDetails, from_date },
    //     },
    //   });
    // }
  } catch (err) {
    console.log({ EROOR: err });
    dispatch({
      type: GET_INVENTORY_OWNER_DETAILS_FAILURE,
    });
  }
};

export const checkForInventoryPreviousRequest = (id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let res = await axios.get(`${GET_ALL_INVENTORY_SENT_REQUEST}`, {
      headers,
    });

    let rqst_id_array = res?.data?.filter((el) => {

      
      if (el.product?.id == id) {
        let status = el?.status;
        if (status == "request") {
          dispatch({
            type: GET_INVENTORY_ALL_SENT_REQUEST_SUCCESS,
            payload: status,
          });
        }
      }
    });
    dispatch({
      type: GET_INVENTORY_ALL_SENT_REQUEST_SUCCESS,
      payload: "",
    });
  } catch (err) {
    console.log({ Error: err });
    dispatch({
      type: GET_INVENTORY_ALL_SENT_REQUEST_FAILURE,
      payload: "",
    });
  }
};

export const handleSearchInventoryOwners = (id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const userEmail = JSON.parse(localStorage.getItem("userEmail")) || null;
  // dispatch(getQRCode(id, token));
  dispatch(checkForInventoryPreviousRequest(id));
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let res = await axios.get(`${GET_INVENTORY_OWNER}/${id}`, {
      headers,
    });

    dispatch({
      type: GET_INVENTORY_OWNER_SUCCESS,
    });

    //let ownerID = res?.data?.owners[0]?.owner;
    let ownerArr = res?.data?.owners;
    let RevereseOwner = [...ownerArr]?.reverse();

    RevereseOwner &&
      RevereseOwner?.forEach((el, i) => {
        let ownerID = el?.owner?.id;
        let from_date = el?.from_date;

        dispatch(getInventoryOwnerDetails(ownerID, from_date));
      });

    dispatch(getAllProductsById(id)); // GET INventory ? Details
  } catch (err) {
    toast(<SuccessToast content={'Please enter a valid ID..!'} />, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
    
    console.log({ Error: err });
    dispatch({
      type: GET_INVENTORY_OWNER_FAILURE,
    });
  }
};

export const getInventoryRequestStatus = (id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let res = await axios.get(`${GET_INVENTORY_OWNERSHIP_STATUS}/${id}`, {
      headers,
    });
    let status = res?.data?.owners?.status;
    dispatch({
      type: GET_INVENTORY_OWNERSHIP_STATUS_SUCCESS,
      payload: status,
    });
  } catch (err) {
    console.log({ Error: err });
    dispatch({
      type: GET_INVENTORY_OWNERSHIP_STATUS_FAILURE,
    });
  }
};

export const getAllInventoryRequests = () => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let res = await axios.get(`${GET_ALL_INVENTORY_REQUEST}`, { headers });
    let reqArr = res?.data?.requests;
    let ReverseReqArr = [...reqArr]?.reverse();
    
    dispatch({
      type: GET_INVENTORY_ALL_RECEIVED_REQUEST_SUCCESS,
      payload: ReverseReqArr,
    });
  } catch (err) {
    dispatch({
      type: GET_INVENTORY_ALL_RECEIVED_REQUEST_FAILURE,
    });
  }
};

export const handleInventoryApproveRequest = (id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let res = await axios.post(
      `${APPROVE_INVENTORY_OWNERSHIP}`,
      { request_id: id },
      { headers }
    );
    if (res.data) {
      toast.success("Ownership Approved...!", {
        position: toast.POSITION.TOP_CENTER,
      });

      dispatch({
        type: APPROVE_INVENTORY_OWNERSHIP_SUCCESS,
      });

      dispatch(getAllInventoryRequests());
    }
  } catch (err) {
    dispatch({
      type: APPROVE_INVENTORY_OWNERSHIP_FAILURE,
    });
  }
};

export const handleInventoryOwnershipRequest = (id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let res = await axios.post(
      `${REQUEST_INVENTORY_OWNERSHIP}`,
      { stock_item: id },
      { headers }
    );

    // localStorage.setItem("request_id", JSON.stringify(res.data.request_id));

    dispatch({
      type: REQUEST_INVENTORY_OWNERSHIP_SUCCESS,
      payload: res?.data?.request_id,
    });
    dispatch(getAllInventoryRequests());
    dispatch(getInventoryRequestStatus(res?.data?.request_id));
    dispatch(handleSearchInventoryOwners(id));
  } catch (err) {
    console.log({ Error: err });
    dispatch({
      type: REQUEST_INVENTORY_OWNERSHIP_FAILURE,
    });
  }
};
