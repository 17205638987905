import {
  GET_ALL_PACKAGE_FAILURE,
  GET_ALL_PACKAGE_SUCCESS,
  GET_SINGLE_PACKAGE_FAILURE,
  GET_SINGLE_PACKAGE_SUCCESS,
  ADD_PACKAGE_SUCCESS,
  ADD_PACKAGE_FAILURE,
  GET_ITEM_FROM_PACKAGE_FAILURE,
  GET_ITEM_FROM_PACKAGE_SUCCESS,
  ADD_ITEM_TO_PACKAGE_FAILURE,
  ADD_ITEM_TO_PACKAGE_SUCCESS,
  GET_PACKAGE_ITEM_DETAILS_FAILURE,
  GET_PACKAGE_ITEM_DETAILS_SUCCESS,
  SEARCH_SINGLE_PACKAGE_FAILURE,
  SEARCH_SINGLE_PACKAGE_SUCCESS,
  LOADER_FALSE,
  LOADER_TRUE,
  ADD_OUTSOURCE_PACKAGING_FAILURE,
  ADD_OUTSOURCE_PACKAGING_SUCCESS
} from "./packaging.actionTypes";

const initialState = {
  packageId: null,
  packageArray: [],
  packageAdded: null,
  packageItems: null,
  AllListedItemsDetails: [],
  ContentData:[],
  SearchedPackageDetails:null,
  loader:false,
  OutsourceID:null,
};

export default function PackagingReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ADD_PACKAGE_SUCCESS:
      return {
        ...state,
        packageId: payload?.id,
      };

    case ADD_PACKAGE_FAILURE:
      return {
        ...state,
        packageId: null,
      };

    case GET_ALL_PACKAGE_SUCCESS:
      return {
        ...state,
        packageArray: payload,
      };

    case GET_ALL_PACKAGE_FAILURE:
      return {
        ...state,
        packageArray: [],
      };

    case GET_SINGLE_PACKAGE_SUCCESS:
      return {
        ...state,
        packageAdded: payload,
      };

    case GET_SINGLE_PACKAGE_FAILURE:
      return {
        ...state,
        packageAdded: null,
      };

    case ADD_ITEM_TO_PACKAGE_SUCCESS:
      return state;
    case ADD_ITEM_TO_PACKAGE_FAILURE:
      return state;

    case GET_ITEM_FROM_PACKAGE_SUCCESS:
      return {
        ...state,
        packageItems: payload.packageitems,
        ContentData: payload.ContentData,
      };

    case GET_ITEM_FROM_PACKAGE_FAILURE:
      return {
        ...state,
        packageItems: null,
        ContentData: [],
        
      };
    case ADD_OUTSOURCE_PACKAGING_SUCCESS:
        return {
          ...state,
          OutsourceID: payload, 
        };
    case ADD_OUTSOURCE_PACKAGING_FAILURE:
          return {
            ...state,
            OutsourceID: null, 
          };

    case GET_PACKAGE_ITEM_DETAILS_SUCCESS:
      return {
        ...state,
        AllListedItemsDetails: payload.detailsArr,
      };

    case GET_PACKAGE_ITEM_DETAILS_FAILURE:
      return {
        ...state,
        AllListedItemsDetails: [],
      };

    case SEARCH_SINGLE_PACKAGE_SUCCESS:
      return {
        ...state,
        SearchedPackageDetails: payload,
      };

    case SEARCH_SINGLE_PACKAGE_FAILURE:
      return {
        ...state,
        SearchedPackageDetails: null,
      };

    case LOADER_TRUE:
      return {
        ...state,
        loader: true,
      };

    case LOADER_FALSE:
      return {
        ...state,
        loader: false,
      };

    default:
      return state;
  }
}
