import React, { useState } from "react";
import contactLayer0 from "../../assets/images/landing/contact/layer0.png";
import contactLayer1 from "../../assets/images/landing/contact/layer1.svg";
import contactLayer2 from "../../assets/images/landing/contact/layer2.png";
import { sendQuery } from "../../Redux/Landing/landing.action";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import SuccessToast from "../../reusable_components/SuccessToast";
import headerLogo from "../../assets/images/landing/header-logo.svg";
import ErrorToast from "../../reusable_components/ErrorToast";

export default function ContactSection({ logo }) {
  const dispatch = useDispatch();
  const [query, setQuery] = useState({ fullname: "", email: "", phone: "", message: "" });
  const [isDisabled, setIsDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuery({ ...query, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payLoad = {
      fullname: query.fullname,
      email: query.email,
      query: query.message,
      mobile: query.phone
    };
    if (query.fullname !=="" && query.email !== "" && query.message !=="" && query.phone !=="" ) {
      setIsDisabled(true)
      dispatch(sendQuery(payLoad));
      toast(<SuccessToast content={'Query successfully sent'} />, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      setQuery({ fullname: '', email: '', message: '', phone:''});
      setTimeout(()=>{
        setIsDisabled(false)
      }, 4000)
    }else{
      toast(<ErrorToast content={'All fields are required'} />, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };
  return (
    <>
      {/* <section className="contactSec" id="chat">
        <div className="container">
          <div className="contactSec__flex">
            <div className="contactSec__left">
              {logo &&
                <div className="d-flex justify-content-center">
                  <img src={headerLogo} alt="logo" />
                </div>
              }
              <div className="contactSec__head">
                <h2 className="head1 fade-in">Write to us!</h2>
                <p className="desc fade-in">
                  We are here to assist you 24/7. Email us so we can reach you
                  out as soon as possible.
                </p>
              </div>
              <form>
                <div className="input-wrapper">
                  <div className="input-label">What should we call you?</div>
                  <input
                    type="text"
                    name="fullname"
                    placeholder="Full name"
                    value={query.fullname}
                    onChange={handleChange}
                  />
                </div>

                <div className="input-wrapper">
                  <div className="input-label">How to contact you?</div>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email address"
                    value={query.email}
                    onChange={handleChange}
                  />
                </div>

                <div className="input-wrapper">
                  <div className="input-label">What’s on your mind?</div>
                  <textarea
                    name="message"
                    placeholder="Tell us about your issues we will contact you as soon as possible."
                    value={query.message}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div className="text-center">
                  <button
                    className="button-dark"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                  >
                    <span className="hgt" >Send query</span>
                  </button>
                </div>
              </form>
            </div>
            <div className="contactSec__right">
              <h2 className="head1 fade-in">
                <span className="hgt">24/7</span>
              </h2>
              <p className="desc2 fade-in">Assisstance at your service</p>
              <div className="contactSec__img">
                <img className="contactSec__layer0" src={contactLayer0} />
                <img className="contactSec__layer1" src={contactLayer1} />
                <img className="contactSec__layer2" src={contactLayer2} />
              </div>
            </div>
          </div>
        </div>
      </section> */}
       <section class="contactSec" id="chat">
          <div class="container">
            <div class="contactSec__flex">
              <div class="contactSec__left">
                <div class="contactSec__head">
                  <h2 class="head1 fade-in">Write to us!</h2>
                  <p class="desc fade-in">
                    We are here to assist you 24/7. Email us so we can reach
                    you out as soon as possible.
                  </p>
                </div>
                <form>
                  <div class="input-wrapper">
                    <div class="input-label">What should we call you?</div>
                    <input
                    type="text"
                    name="fullname"
                    placeholder="Full name"
                    value={query.fullname}
                    onChange={handleChange}
                  />
                  </div>

                  <div class="input-wrapper">
                    <div class="input-label">How to contact you?</div>
                    <input
                    type="text"
                    name="email"
                    placeholder="Email address"
                    value={query.email}
                    onChange={handleChange}
                  />
                  </div>

                   <div class="input-wrapper">
                    <div class="input-label">Phone Number</div>
                    <input
                    type="text"
                    name="phone"
                    placeholder="phone number"
                    value={query.phone}
                    onChange={handleChange}  
                  />
                  </div> 

                  <div class="input-wrapper">
                    <div class="input-label">What’s on your mind?</div>
                    <textarea
                    name="message"
                    placeholder="Tell us about your issues we will contact you as soon as possible."
                    value={query.message}
                    onChange={handleChange}
                  ></textarea>
                  </div>

                  <div class="text-center">
                  <button
                    className="button-dark"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                  >
                    <span className="hgt" >Send query</span>
                  </button>
                  </div>
                </form>
              </div>
              <div class="contactSec__right">
                <h2 class="head1 fade-in"><span class="hgt">24/7</span></h2>
                <p class="desc2 fade-in">Assisstance at your service</p>
                <div class="contactSec__img">
                  <img class="contactSec__layer0" src={contactLayer0} />
                  <img class="contactSec__layer1" src={contactLayer1} />
                  <img class="contactSec__layer2" src={contactLayer2} />
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  );
}
