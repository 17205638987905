import {
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_SUCCESS,
} from "./report.actionTypes";

const initialState = {
  allUsers: [],
};

export default function ReportReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        allUsers: payload,
      };
    case GET_ALL_USERS_FAILURE:
      return {
        ...state,
        message: "",
      };
    default:
      return state;
  }
}
