import React from 'react';
import alertToasterImage from '../assets/images/icons/toaster-alert.svg';

const ErrorToast = ({ content }) => {
  return (
    <div className="add-product-modal-content">
            <div className="p-0 m-0 modal-header">
    <div className="header-topBar toaster-success">
      <h3 className="mb-0 d-inline-flex">
        {/* Uncomment the line below if you want to include the arrow icon */}
        {/* <img src="./assets/images/icons/icon-arrow.svg" alt=""> */}
        <img className="alert-img" src={alertToasterImage} alt="Alert Toaster" />
        <span className="m-0 fw600 fz24 rejected-message">{content}</span>
      </h3>
    </div>
    </div>
    </div>
  );
};

export default ErrorToast;
