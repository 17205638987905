export const GET_PACKAGE_OWNER_SUCCESS = "package/ownership/GET_PACKAGE_OWNER_SUCCESS";
export const GET_PACKAGE_OWNER_FAILURE = "package/ownership/GET_PACKAGE_OWNER_FAILURE";
export const REQUEST_PACKAGE_OWNERSHIP_SUCCESS =
  "package/ownership/REQUEST_PACKAGE_OWNERSHIP_SUCCESS";
export const REQUEST_PACKAGE_OWNERSHIP_FAILURE =
  "package/ownership/REQUEST_PACKAGE_OWNERSHIP_FAILURE";
export const APPROVE_PACKAGE_OWNERSHIP_SUCCESS =
  "package/ownership/APPROVE_PACKAGE_OWNERSHIP_SUCCESS";
export const APPROVE_PACKAGE_OWNERSHIP_FAILURE =
  "package/ownership/APPROVE_PACKAGE_OWNERSHIP_FAILURE";
export const GET_PACKAGE_OWNERSHIP_STATUS_SUCCESS =
  "package/ownership/GET_PACKAGE_OWNERSHIP_STATUS_SUCCESS";
export const GET_PACKAGE_OWNERSHIP_STATUS_FAILURE =
  "package/ownership/GET_PACKAGE_OWNERSHIP_STATUS_FAILURE";
export const GET_PACKAGE_ALL_SENT_REQUEST_SUCCESS =
  "package/ownership/GET_PACKAGE_ALL_SENT_REQUEST_SUCCESS";
export const GET_PACKAGE_ALL_SENT_REQUEST_FAILURE =
  "package/ownership/GET_PACKAGE_ALL_SENT_REQUEST_FAILURE";
export const GET_PACKAGE_OWNER_DETAILS_SUCCESS =
  "package/ownership/GET_PACKAGE_OWNER_DETAILS_SUCCESS";
export const GET_PACKAGE_OWNER_DETAILS_FAILURE =
  "package/ownership/GET_PACKAGE_OWNER_DETAILS_FAILURE";
export const GET_PACKAGE_ALL_RECEIVED_REQUEST_SUCCESS =
  "package/ownership/GET_PACKAGE_ALL_RECEIVED_REQUEST_SUCCESS";
export const GET_PACKAGE_ALL_RECEIVED_REQUEST_FAILURE =
  "package/ownership/GET_PACKAGE_ALL_RECEIVED_REQUEST_FAILURE";

export const DISPLAY_REQUEST_DATA_SUCCESS =
  "display/DISPLAY_REQUEST_DATA_SUCCESS";

export const DISPLAY_REQUEST_DATA_FAILURE = "display/DISPLAY_REQUEST_DATA_FAILURE";
export const LOADER_TRUE = "package/ownership/LOADER_TRUE";
export const LOADER_FALSE = "package/ownership/LOADER_FALSE";
  