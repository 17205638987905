import {
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_SUCCESS,
  AUTH_OTP_SUCCESS,
  AUTH_OTP_FAILURE,
} from "./auth.actionTypes";

const initialState = {
  data: {
    isAuthenticated: false,
    otpSent: false,
    token: "",
    user: "",
    message: "",
  },
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case AUTH_OTP_SUCCESS:
      return {
        ...state,
        data: { ...state.data, otpSent: true, message: "", user: payload.email },
      };

    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        data: { ...state.data, isAuthenticated: true, token: payload.token ,message:""},
      };
    case AUTH_OTP_FAILURE:
      return {...state,data:{...state.data, message:payload.message}};

    case AUTH_LOGIN_FAILURE:
      return {...state,
    data:{
        ...state.data, message:payload.message
    }}

    default:
      return state;
  }
}
