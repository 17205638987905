import {
  GET_PACKAGE_OWNER_SUCCESS,
  GET_PACKAGE_OWNER_FAILURE,
  REQUEST_PACKAGE_OWNERSHIP_SUCCESS,
  REQUEST_PACKAGE_OWNERSHIP_FAILURE,
  APPROVE_PACKAGE_OWNERSHIP_SUCCESS,
  APPROVE_PACKAGE_OWNERSHIP_FAILURE,
  GET_PACKAGE_OWNERSHIP_STATUS_SUCCESS,
  GET_PACKAGE_OWNERSHIP_STATUS_FAILURE,
  GET_PACKAGE_ALL_SENT_REQUEST_SUCCESS,
  GET_PACKAGE_ALL_SENT_REQUEST_FAILURE,
  GET_PACKAGE_OWNER_DETAILS_SUCCESS,
  GET_PACKAGE_OWNER_DETAILS_FAILURE,
  GET_PACKAGE_ALL_RECEIVED_REQUEST_SUCCESS,
  GET_PACKAGE_ALL_RECEIVED_REQUEST_FAILURE,
  DISPLAY_REQUEST_DATA_FAILURE,
  DISPLAY_REQUEST_DATA_SUCCESS,
  LOADER_TRUE,
  LOADER_FALSE
} from "./PackageOwnership.actionTypes.js";

const initialState = {
  currentStatus: "",
  ownerId: null,
  requestId: null,
  ownerDetails: [],
  ProductDetails: null,
  ownerActive: false,
  AllReceivedRequestArray: [],
  DisplayData: "Inventory",
  loader:false,
};


export default function PackageOwnerShipReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case GET_PACKAGE_ALL_SENT_REQUEST_SUCCESS:
      return {
        ...state,
        currentStatus: payload,
      };

    case GET_PACKAGE_ALL_SENT_REQUEST_FAILURE:
      return {
        ...state,
        currentStatus: payload,
      };

    case GET_PACKAGE_OWNER_DETAILS_SUCCESS:
      return {
        ...state,
        ownerDetails: [...state.ownerDetails, payload.ownerDetails],
      };
    case GET_PACKAGE_OWNER_DETAILS_FAILURE:
      return {
        ...state,
        ownerDetails: [],
      };

    case GET_PACKAGE_OWNER_SUCCESS:
      return {
        ...state,
        currentStatus: "",
        ownerDetails: [],
      };

    case GET_PACKAGE_OWNER_FAILURE:
      return {
        ...state,
        currentStatus: "",
        ownerDetails: [],
      };

    case GET_PACKAGE_OWNERSHIP_STATUS_SUCCESS:
      return {
        ...state,
        currentStatus: payload,
      };
    case GET_PACKAGE_OWNERSHIP_STATUS_FAILURE:
      return {
        ...state,
        currentStatus: "",
      };

    case GET_PACKAGE_ALL_RECEIVED_REQUEST_SUCCESS:
      return {
        ...state,
        AllReceivedRequestArray: payload,
      };

    case GET_PACKAGE_ALL_RECEIVED_REQUEST_FAILURE:
      return {
        ...state,
        AllReceivedRequestArray: [],
      };

    case APPROVE_PACKAGE_OWNERSHIP_SUCCESS:
      return state;

    case APPROVE_PACKAGE_OWNERSHIP_FAILURE:
      return state;

    case REQUEST_PACKAGE_OWNERSHIP_SUCCESS:
      return {
        ...state,
        requestId: payload,
      };
    case REQUEST_PACKAGE_OWNERSHIP_FAILURE:
      return {
        ...state,
        requestId: null,
      };

    case DISPLAY_REQUEST_DATA_SUCCESS:
      console.log("my value", payload);
      return {
        ...state,
        DisplayData: payload,
      };

    case DISPLAY_REQUEST_DATA_FAILURE:
      return {
        ...state,
        DisplayData: "Inventory",
      };

    case LOADER_TRUE:
      return {
        ...state,
        loader: true,
      };
    case LOADER_FALSE:
      return {
        ...state,
        loader: false,
      };

    default:
      return state;
  }
}
 