import React, { useEffect, useState } from "react";
import "../../assets/styles/landing.css";
import Footer from "../../layouts/footer";
import Info from "./info";
import { Link as NavigateLink, useLocation, useNavigate } from "react-router-dom";
import ContactSection from "./contactSection";
import headerLogo from "../../assets/images/landing/header-logo.svg";
import leftDown from "../../assets/images/landing/truck/truck-left-down.svg";
import rightDown from "../../assets/images/landing/truck/truck-right-down.svg";
import leftUp from "../../assets/images/landing/truck/truck-left-up.svg";
import rightUp from "../../assets/images/landing/truck/truck-right-up.svg";
import down from "../../assets/images/landing/truck/truck-down.svg";
import right from "../../assets/images/landing/truck/truck-right.svg";
import upload from "../../assets/images/landing/hero/upload.svg";
import heroArrow from "../../assets/images/landing/hero-arrow.svg";
import heroMob from "../../assets/images/landing/hero/hero-mob-img.svg";
import heroMobTruck from "../../assets/images/landing/hero/hero-mob-truck.svg";
import img1 from "../../assets/images/landing/partners/hyundai.svg";
import img2 from "../../assets/images/landing/partners/connect-broadbrand.svg";
import img3 from "../../assets/images/landing/partners/signotox.svg";
import img4 from "../../assets/images/landing/partners/smart-ping.svg";
import img5 from "../../assets/images/landing/partners/infodart.svg";
import img6 from "../../assets/images/landing/partners/kenstar.svg";
import img7 from "../../assets/images/landing/partners/dhoot-hospital.svg";
import layer0Tracking from "../../assets/images/landing/tracking/layer0.png";
import layer1Tracking from "../../assets/images/landing/tracking/layer1.png";
import layer2Tracking from "../../assets/images/landing/tracking/layer2.svg";
import mobLayer0Tracking from "../../assets/images/landing/tracking/mob-layer-0.png";
import mobLayer1Tracking from "../../assets/images/landing/tracking/mob-layer-1.svg";
import mobThumb1Tracking from "../../assets/images/landing/tracking/mob-thumb1.png";
import mobThumb2Tracking from "../../assets/images/landing/tracking/mob-thumb2.png";
import mobThumb3Tracking from "../../assets/images/landing/tracking/mob-thumb3.png";
import mobThumb4Tracking from "../../assets/images/landing/tracking/mob-thumb4.png";
import arrow from "../../assets/images/landing/features/arrow.svg";
import featureImg1Layer0 from "../../assets/images/landing/features/img1/layer0.png";
import featureImg1Layer1 from "../../assets/images/landing/features/img1/layer1.png";
import featureImg1Layer2 from "../../assets/images/landing/features/img1/layer2.png";
import featureImg3Layer0 from "../../assets/images/landing/features/img3/layer0.png";
import featureImg3Layer1 from "../../assets/images/landing/features/img3/layer1.png";
import featureImg3Layer2 from "../../assets/images/landing/features/img3/layer2.png";
import featureImg2Layer0 from "../../assets/images/landing/features/img4/layer0.png";
import featureImg2Layer1 from "../../assets/images/landing/features/img4/layer1.png";
import featureImg2Layer2 from "../../assets/images/landing/features/img4/layer2.png";
import journeyLayer0 from "../../assets/images/landing/journey/layer0.png";
import journeyLayer1 from "../../assets/images/landing/journey/layer1.svg";
import dotmove1 from "../../assets/images/landing/journey/dotmove1.svg";
import hiwMobSvg from "../../assets/images/landing/journey/hiw-mob-svg.svg";
import hiwMobPng from "../../assets/images/landing/journey/hiw-mob-svg.png";
import deco1 from "../../assets/images/landing/features/deco1.svg";
import numbersDeco1 from "../../assets/images/landing/numbers/deco1.svg";
import chooseLayer0 from "../../assets/images/landing/choose/layer0.png";
import chooseLayer1 from "../../assets/images/landing/choose/layer1.svg";
import chooseMobThumb1 from "../../assets/images/landing/choose/mob-thumb-1.png";
import chooseMobThumb2 from "../../assets/images/landing/choose/mob-thumb-2.png";
import chooseMobThumb3 from "../../assets/images/landing/choose/mob-thumb-3.png";
import chooseMobThumb4 from "../../assets/images/landing/choose/mob-thumb-4.png";
import chooseMobThumb5 from "../../assets/images/landing/choose/mob-thumb-5.png";
import chooseMobThumb6 from "../../assets/images/landing/choose/mob-thumb-6.png";
import chooseMobThumb7 from "../../assets/images/landing/choose/mob-thumb-7.png";
import heroImg from "../../assets/images/landing/hero/hero-img.svg";
import menuArrowLeft from "../../assets/images/landing/menu-arrow-left.svg";
import menuLogo from "../../assets/images/landing/menu-logo.svg";
import social1 from "../../assets/images/landing/social1.svg";
import social2 from "../../assets/images/landing/social2.svg";
import social3 from "../../assets/images/landing/social3.svg";
import social4 from "../../assets/images/landing/social4.svg";
import leaf from "../../assets/images/landing/subscribe/leaf.svg";
import img2Layer0 from "../../assets/images/landing/features/img2/layer0.png";
import img2Layer1 from "../../assets/images/landing/features/img2/layer1.png";
import img2Layer2 from "../../assets/images/landing/features/img2/layer2.png";
import danger from "../../assets/images/downloaddanger.png";
import {
  Link as ScrollLink,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";

import {
  GET_ITEM_DETAILS,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_DETAILS_ID,
  GET_PRODUCT_DETAILS_QRCODE,
  UPLOAD_QR,
} from "../../services/Api";
import Loader from "../../reusable_components/loader";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

export default function LandingPage() {
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const [showToast, setShowToast] = useState(false);
 
  

  const location = useLocation();
  const stateValue = new URLSearchParams(location.search).get('state');

  const handleSetActive = (to) => {
    setActiveSection(to);
    const searchParams = new URLSearchParams(location.search);
    stateValue && 
    
    searchParams.delete('state');
    navigate(`?${searchParams.toString()}`);
  };

  useEffect(() => {
    stateValue && scrollToSection(stateValue)
  }, [stateValue]);

  const scrollToSection = (section) => {
    setActiveSection(section);

    scroll.scrollTo(section, {
      spy: true,
      smooth: true,
      offset: -50,
      duration: 500,
    });
  };

  const CustomToast = () => (
    <div style={{ display: "flex", backgroundImage: "none" }}>
      <img src={danger} alt="Error" width="55" height="50" />
      <span style={{ marginLeft: "5px" }}>
        {" "}
        Invalid! It indicates counterfeiting. Please report it immediately.
      </span>
    </div>
  );

  const customToastContainerStyle = {
    width: "360px",
    color: "#000000",
    fontSize: "15px",
    position: "fixed",
    top: "0",
    left: "50%",
    transform: "translateX(-50%)",
  };

  const customToastContainer = {
    width: "100%",
  };

  useEffect(() => {
    let script = document.createElement("script");
    script.id = "scriptId";
    script.src = "/assets/scripts/landing.js";
    document.body.append(script);
    const handlePopState = (event) => {
      if (event.state === null)  {
        console.log("Back Button Pressed")
      }
     
    };
    window.addEventListener('popstate', handlePopState);
    
    return () => {
      const scriptElement = document.getElementById("scriptId");
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
  
  useEffect(() => {
    // Check if the page has been reloaded before
    const hasPageBeenReloaded = localStorage.getItem('hasPageBeenReloaded');
    // If not reloaded before, reload the page and set the flag in localStorage
    if (!hasPageBeenReloaded) {
      localStorage.setItem('hasPageBeenReloaded', 'true');
      window.location.reload(false);
    }
      // Cleanup function to reset the flag when the component is unmounted
    return () => {
      localStorage.removeItem('hasPageBeenReloaded');
    };
  }, []);

  const getDetails = async (id) => {
    try {
      let res = await axios.get(`${GET_PRODUCT_DETAILS_ID}/${id}`);
      let data = res?.data;

      if (data) {
        navigate(`/productTracking/${data?.display_id}`, { state: data });
      }
    } catch (error) {
      console.log({ error });
      toast.error(<CustomToast />, { style: customToastContainerStyle });
    }
  };

  const handleSubmit = async (e) => {
    if (id && !selectedImage) {
      const isPackId = id.startsWith("PACK");
      const isInveId = id.startsWith("INVE");
      if (isPackId || isInveId) {
        navigate(`/productTracking/${id}`);
      } else {
        setShowToast(true);
        toast.error(<CustomToast />, { style: customToastContainerStyle });
      }
    }

    if (selectedImage) {
      const formData = new FormData();
      formData.append("image", selectedImage);
      try {
        await axios.post(GET_PRODUCT_DETAILS_QRCODE, formData).then((resp) => {
          if (resp) {
            const isPackId = resp?.data?.display_id.startsWith("PACK");
            const isInveId = resp?.data?.display_id.startsWith("INVE");
            if (isPackId || isInveId) {
              navigate(`/productTracking/${resp?.data?.display_id}`);
              //getDetails(resp?.data?.display_id);
            } else {
              setShowToast(true);
              toast.error(<CustomToast />, {
                style: customToastContainerStyle,
              });
            }
          }
        });
      } catch (err) {
        setShowToast(true);
        console.log({ "error msg": err });
        toast.error(<CustomToast />, {
          style: customToastContainerStyle,
        });
      }
    }
  };

  const selectQR = async (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setId(file?.name);
  };

  const handleIdChange = (e) => {
    setId(e.target.value);
    setSelectedImage(null);
  };

  return (
    <div className="landing">
      <header className="headerSec">
        <div className="container">
          <nav className="headerSec__inner">
            <NavigateLink className="headerSec__logo" to="#">
              <img src={headerLogo} alt="logo" />
            </NavigateLink>

            <ul className="headerSec__links">
              <li className={activeSection === "home" ? "active" : ""}>
                <ScrollLink
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  onSetActive={()=>handleSetActive("home")}
                >
                  Home
                </ScrollLink>
              </li>
              <li className={activeSection === "why" ? "active" : ""}>
                <ScrollLink
                  to="why"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  onSetActive={()=>handleSetActive("why")}
                >
                  About Holografs
                </ScrollLink>
              </li>
              <li className={activeSection === "values" ? "active" : ""}>
                <ScrollLink to="values"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  onSetActive={()=>handleSetActive("values")}
                  >Unique Values</ScrollLink>
              </li>
              <li className={activeSection === "how" ? "active" : ""}>
                <ScrollLink
                  to="how"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  onSetActive={()=>handleSetActive("how")}
                >
                  How it works?
                </ScrollLink>
              </li>
              <li className={activeSection === "faq" ? "active" : ""}>
                <ScrollLink to="faq"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={500}
                  onSetActive={()=>handleSetActive("faq")}
                  >FAQ</ScrollLink>
              </li>
              <li className={activeSection === "chat" ? "active" : ""}>
                <ScrollLink
                  to="chat"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={500}
                  onSetActive={()=>handleSetActive("chat")}
                >
                  Let's have a chat!
                </ScrollLink>
              </li>
            </ul>

            <NavigateLink className="headerSec__btn" to="/login">
              <span>SIGN IN</span>
            </NavigateLink>

            <NavigateLink className="d-lg-none menu-btn" to="#">
              <span>MENU</span>
              <img src={menuArrowLeft} alt="menu button" />
            </NavigateLink>
          </nav>
        </div>
      </header>

      <aside className="menuSec">
        <div className="menuSec__inner">
          <div className="menuSec__head">
            <div className="text-start">
              <NavigateLink className="d-lg-none menu-btn" to="#">
                <span>MENU</span>
                <img src={menuArrowLeft} alt="menu button" />
              </NavigateLink>
            </div>
            <div className="menuSec__head-logo">
              <img src={menuLogo} alt="logo" />
            </div>
            <div id="" className="menuSec__head-desc">
              a cutting-edge Blockchain as a Service
            </div>
            <div>
              <NavigateLink className="button-light" to="#">
                <span>
                  <span className="hgt">Login</span>
                </span>
              </NavigateLink>
            </div>
          </div>

          <ul className="menuSec__links">
            <li className="active">
              <NavigateLink to="#">
                <span className="hgt">Home</span>
              </NavigateLink>
            </li>
            <li>
              <NavigateLink to="#">
                <span className="hgt">About Holografs</span>
              </NavigateLink>
            </li>
            <li>
              <NavigateLink to="#">
                <span className="hgt">Unique Values</span>
              </NavigateLink>
            </li>
            <li>
              <NavigateLink to="#">
                <span className="hgt">How it works?</span>
              </NavigateLink>
            </li>
            <li>
              <NavigateLink to="#">
                <span className="hgt">FAQ</span>
              </NavigateLink>
            </li>
            <li>
              <NavigateLink to="#">
                <span className="hgt">Let's have a chat!</span>
              </NavigateLink>
            </li>
          </ul>

          <div className="menuSec__foot">
            <div>
              <span className="hgt">2023 copyright.</span>
            </div>

            <div className="menuSec__social">
              <NavigateLink to="#">
                <img src={social1} />
              </NavigateLink>
              <NavigateLink to="#">
                <img src={social2} />
              </NavigateLink>
              <NavigateLink to="#">
                <img src={social3} />
              </NavigateLink>
              <NavigateLink to="#">
                <img src={social4} />
              </NavigateLink>
            </div>
          </div>
        </div>
      </aside>

      <div id="smooth-wrapper">
        <div id="smooth-content" className="position-relative">
          <div className="truckSec downwards desk-only">
            <img className="truckSec__left-down" src={leftDown} alt="truck" />
            <img className="truckSec__right-down" src={rightDown} alt="truck" />
            <img className="truckSec__left-up" src={leftUp} alt="truck" />
            <img className="truckSec__right-up" src={rightUp} alt="truck" />
            <img className="truckSec__down" src={down} alt="truck" />
            <img className="truckSec__up" src={down} alt="truck" />
            <img className="truckSec__right" src={right} alt="truck" />
          </div>

          <section className="heroSec" id="home">
            <div className="container">
              <div className="heroSec__content">
                <h1 className="head1 fade-in">
                  A Cutting-Edge Blockchain As A Service
                </h1>
                <p className="desc fade-in">
                  Holografs provides a transparent and secure platform for
                  verifying product authenticity, tracking provenance, and
                  enabling smart ownership.
                </p>
                <div className="search-product fade-in">
                  <div className="search-product__inner">
                    <div className="search-product__upload prefix upload-click">
                      <div className="btn-fab">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={selectQR}
                        />
                        <img src={upload} alt="upload" />
                      </div>
                    </div>
                    <input
                      type="text"
                      placeholder="Search product by ID or upload QR.."
                      value={id}
                      onChange={handleIdChange}
                    />
                    <button onClick={handleSubmit}>
                      <span className="hgt">Search</span>
                    </button>
                  </div>
                </div>
                <div className="heroSec__arrow fade-in">
                  <img src={heroArrow} alt="scroll down" />
                </div>
              </div>
            </div>
            <div
              className="desk-only heroSec__img replaceWithSVG fade-in fade-in-delay-0.35"
              data-src={heroImg}
            ></div>
            <div className="mob-only heroSec__img-mob fade-in fade-in-delay-0.35">
              <img className="heroSec__img-mob-layer0" src={heroMob} />
              <img
                className="heroSec__img-mob-layer1 replaceWithSVG"
                src={heroMobTruck}
              />
            </div>
          </section>

          <section className="partnersSec">
            <h2 className="">Partners we are dealing with</h2>
            <div className="owl-carousel owl-theme">
              <div className="item">
                <div className="partnersSec__single">
                  <div className="partnersSec__img">
                    <img src={img1} />
                  </div>
                  {/* <div className="partnersSec__name">APPLE</div> */}
                </div>
              </div>
              <div className="item">
                <div className="partnersSec__single">
                  <div className="partnersSec__img">
                    <img src={img2} />
                  </div>
                  {/* <div className="partnersSec__name">AMAZONE</div> */}
                </div>
              </div>
              <div className="item item-duellex">
                <div className="partnersSec__single">
                  <div className="partnersSec__img">
                    <img src={img3} />
                  </div>
                  {/* <div className="partnersSec__name">COCACOLA</div> */}
                </div>
              </div>
              <div className="item">
                <div className="partnersSec__single">
                  <div className="partnersSec__img">
                    <img src={img4} />
                  </div>
                  {/* <div className="partnersSec__name">NIKE</div> */}
                </div>
              </div>
              <div className="item">
                <div className="partnersSec__single">
                  <div className="partnersSec__img">
                    <img src={img5} />
                  </div>
                  {/* <div className="partnersSec__name">APPLE</div> */}
                </div>
              </div>
              <div className="item">
                <div className="partnersSec__single">
                  <div className="partnersSec__img">
                    <img src={img6} />
                  </div>
                  {/* <div className="partnersSec__name">AMAZONE</div> */}
                </div>
              </div>
              <div className="item item-duellex">
                <div className="partnersSec__single">
                  <div className="partnersSec__img">
                    <img src={img7} />
                  </div>
                  {/* <div className="partnersSec__name">COCACOLA</div> */}
                </div>
              </div>
              <div className="item">
                <div className="partnersSec__single">
                  <div className="partnersSec__img">
                    <img src={img1} />
                  </div>
                  {/* <div className="partnersSec__name">NIKE</div> */}
                </div>
              </div>
              <div className="item">
                <div className="partnersSec__single">
                  <div className="partnersSec__img">
                    <img src={img2} />
                  </div>
                  {/* <div className="partnersSec__name">APPLE</div> */}
                </div>
              </div>
              <div className="item">
                <div className="partnersSec__single">
                  <div className="partnersSec__img">
                    <img src={img3} />
                  </div>
                  {/* <div className="partnersSec__name">AMAZONE</div> */}
                </div>
              </div>
              <div className="item item-duellex">
                <div className="partnersSec__single">
                  <div className="partnersSec__img">
                    <img src={img4} />
                  </div>
                  {/* <div className="partnersSec__name">COCACOLA</div> */}
                </div>
              </div>
              <div className="item">
                <div className="partnersSec__single">
                  <div className="partnersSec__img">
                    <img src={img5} />
                  </div>
                  {/* <div className="partnersSec__name">NIKE</div> */}
                </div>
              </div>
            </div>
          </section>

          <section className="liveTrackSec" id="about">
            <div className="container">
              <div className="section-title-wrapper">
                <h2 className="head1 fade-in">
                  Live Tracking for your Inventories!
                </h2>
                <p className="desc fade-in">
                  Holograf's live tracking offers real-time control and complete
                  visibility of your inventory, keeping you informed every step
                  of the way.
                </p>
                <div className="button-wrapper fade-in">
                  <NavigateLink className="button-dark" to="/login">
                    <span className="hgt">Let's Start</span>
                  </NavigateLink>
                </div>
              </div>

              <div className="liveTrackSec__img desk-only">
                <img className="liveTrackSec__layer0" src={layer0Tracking} />
                <img
                  className="liveTrackSec__layer1 fade-in"
                  src={layer1Tracking}
                />
                <img
                  className="liveTrackSec__layer2 fade-in"
                  src={layer2Tracking}
                />
              </div>
            </div>

            <div className="liveTrackSec__img mob-only">
              <div className="liveTrackSec__img">
                <img className="liveTrackSec__layer0" src={mobLayer0Tracking} />
                <img
                  className="liveTrackSec__layer1 fade-in"
                  src={mobLayer1Tracking}
                />
              </div>

              <div className="container">
                <div className="liveTrackSec__desc fade-in">
                  <div className="liveTrackSec__desc-row">
                    <img
                      className="liveTrackSec__desc-img"
                      src={mobThumb1Tracking}
                      alt=""
                    />
                    <div className="liveTrackSec__desc-text">
                      Track your product via ID or upload product QR
                    </div>
                    <div className="liveTrackSec__desc-number">
                      <span className="hgt">1.</span>
                    </div>
                  </div>
                  <div className="liveTrackSec__desc-row">
                    <img
                      className="liveTrackSec__desc-img"
                      src={mobThumb2Tracking}
                      alt=""
                    />
                    <div className="liveTrackSec__desc-text">
                      Keep a track of your product analytics
                    </div>
                    <div className="liveTrackSec__desc-number">
                      <span className="hgt">2.</span>
                    </div>
                  </div>
                  <div className="liveTrackSec__desc-row">
                    <img
                      className="liveTrackSec__desc-img"
                      src={mobThumb3Tracking}
                      alt=""
                    />
                    <div className="liveTrackSec__desc-text">
                      Manage your inventories
                    </div>
                    <div className="liveTrackSec__desc-number">
                      <span className="hgt">3.</span>
                    </div>
                  </div>
                  <div className="liveTrackSec__desc-row">
                    <img
                      className="liveTrackSec__desc-img"
                      src={mobThumb4Tracking}
                      alt=""
                    />
                    <div className="liveTrackSec__desc-text">
                      24/7 Help and support
                    </div>
                    <div className="liveTrackSec__desc-number">
                      <span className="hgt">4.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="featuresSec" id="values">
            <div id="about-holograf" className="container">
              <h2 className="head1 fade-in">
                Exciting <span className="hgt">Features</span>
                <img className="d-inline-block" src={leaf} alt="..." />
              </h2>
              <p className="desc fade-in">
                Empowering Your Supply Chain with Innovation
              </p>

              <div className="featuresSec__row">
                <div className="featuresSec__col">
                  <div className="featuresSec__single item1 fade-in">
                    <div className="featuresSec__content">
                      <div className="featuresSec__title">
                        <span className="hgt">INVENTORY MANAGMENT</span>
                      </div>
                      <p className="featuresSec__text">
                        Streamline and optimize your inventory with Holograf's
                        toolset for peak supply chain efficiency.
                      </p>
                      <NavigateLink className="featuresSec__btn" to="#">
                        <img src={arrow} alt="" />
                      </NavigateLink>
                    </div>
                    <div className="featuresSec__img img1">
                      <img
                        className="featuresSec__layer0"
                        src={featureImg1Layer0}
                        alt=""
                      />
                      <img
                        className="featuresSec__layer1"
                        src={featureImg1Layer1}
                        alt=""
                      />
                      <img
                        className="featuresSec__layer2"
                        src={featureImg1Layer2}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="featuresSec__single item2 image-left fade-in">
                    <div className="featuresSec__content">
                      <div className="featuresSec__title">
                        <span className="hgt">SECURE DATA SHARE</span>
                      </div>
                      <p className="featuresSec__text">
                        Protect your sensitive information with advanced
                        security protocols.
                      </p>
                      <NavigateLink className="featuresSec__btn" to="#">
                        <img src={arrow} alt="" />
                      </NavigateLink>
                    </div>
                    <div className="featuresSec__img img3">
                      <img
                        className="featuresSec__layer0"
                        src={featureImg3Layer0}
                        alt=""
                      />
                      <img
                        className="featuresSec__layer1"
                        src={featureImg3Layer1}
                        alt=""
                      />
                      <img
                        className="featuresSec__layer2"
                        src={featureImg3Layer2}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="featuresSec__col">
                  <div className="featuresSec__single item3 fade-in">
                    <div className="featuresSec__content">
                      <div className="featuresSec__title">
                        <span className="hgt">PRODUCT TRACKING</span>
                      </div>
                      <p className="featuresSec__text">
                        Gain real-time visibility into your product's journey,
                        ensuring safety and reliability.
                      </p>
                      <NavigateLink className="featuresSec__btn" to="#">
                        <img src={arrow} alt="" />
                      </NavigateLink>
                    </div>
                    <div className="featuresSec__img img2">
                      <img
                        className="featuresSec__layer0"
                        src={img2Layer0}
                        alt=""
                      />
                      <img
                        className="featuresSec__layer1"
                        src={img2Layer1}
                        alt=""
                      />
                      <img
                        className="featuresSec__layer2"
                        src={img2Layer2}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="featuresSec__single item4 image-left fade-in">
                    <div className="featuresSec__content">
                      <div className="featuresSec__title">
                        <span className="hgt">REPORT ANALYTICS</span>
                      </div>
                      <p className="featuresSec__text">
                        Unlock valuable insights to enhance efficiency with
                        Holograf's analytics.
                      </p>
                      <NavigateLink className="featuresSec__btn" to="#">
                        <img src={arrow} alt="" />
                      </NavigateLink>
                    </div>
                    <div className="featuresSec__img img4">
                      <img
                        className="featuresSec__layer0"
                        src={featureImg2Layer0}
                        alt=""
                      />
                      <img
                        className="featuresSec__layer1"
                        src={featureImg2Layer1}
                        alt=""
                      />
                      <img
                        className="featuresSec__layer2"
                        src={featureImg2Layer2}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="featuresSec__deco1" src={deco1} alt="" />
          </section>

          <section className="journeySec" id="how">
            <div className="container">
              <div className="section-title-wrapper">
                <h2 className="head1 fade-in">
                  Secure and efficient tracking journey!
                </h2>
                <p className="desc fade-in">
                  Our platform guarantees the safety and accuracy of every step
                  in your supply chain, offering complete peace of mind
                </p>
                <div className="button-wrapper fade-in">
                  <NavigateLink className="button-dark" to="/login">
                    <span className="hgt">Let's Start</span>
                  </NavigateLink>
                </div>
              </div>
              <div className="desk-only journeySec__img">
                <img className="journeySec__layer0" src={journeyLayer0} />
                <img
                  className="journeySec__layer1 replaceWithSVG"
                  src={journeyLayer1}
                />
                <div className="trackDotsMoving">
                  <img className="trackdotmove1" src={dotmove1} alt="dot" />
                  <img className="trackdotmove2" src={dotmove1} alt="dot" />
                  <img className="trackdotmove3" src={dotmove1} alt="dot" />
                  <img className="trackdotmove4" src={dotmove1} alt="dot" />
                </div>
              </div>
              <div className="mob-only journeySec__mobImg">
                <img className="journeySec__mobImg-layer0" src={hiwMobPng} />
                <img
                  className="journeySec__mobImg-layer1 replaceWithSVG"
                  src={hiwMobSvg}
                />
              </div>
            </div>
          </section>

          <section className="numbersSec">
            <div className="container">
              <div className="numbersSec__flex">
                <div className="numbersSec__single">
                  <div className="numbersSec__count">
                    <span className="hgt">
                      <span className="animate-number">1030</span>+
                    </span>
                  </div>
                  <div className="numbersSec__text">
                    New companies <br />
                    Registered
                  </div>
                </div>
                <div className="numbersSec__single">
                  <div className="numbersSec__count">
                    <span className="hgt">
                      <span className="animate-number">300</span>+
                    </span>
                  </div>
                  <div className="numbersSec__text">
                    Inventories around <br />
                    the globe
                  </div>
                </div>
                <div className="numbersSec__single">
                  <div className="numbersSec__count">
                    <span className="hgt">
                      <span className="animate-number">300</span>+
                    </span>
                  </div>
                  <div className="numbersSec__text">Tracking inprogress</div>
                </div>

                <div className="numbersSec__single">
                  <div className="numbersSec__count">
                    <span className="hgt">
                      <span className="animate-number">300</span>+
                    </span>
                  </div>
                  <div className="numbersSec__text">
                    Inventories around <br />
                    the globe
                  </div>
                </div>
              </div>
            </div>
            <img
              className="numbersSec__deco1"
              src={numbersDeco1}
              alt="decoration"
            />
          </section>

          <section className="whyUsSec" id="why">
            <div className="container">
              <div className="whyUsSec__head">
                <h2 className="head1 fade-in">Why choose us?</h2>
                <p className="desc fade-in">
                  Our advanced platform guarantees product authenticity and
                  streamlines supply chains. We offer real-time tracking,
                  compliance with regulations, and user-friendly interfaces,
                  empowering your business for a brighter, safer future.
                </p>
              </div>
              <div className="whyUsSec__img desk-only">
                <img className="whyUsSec__layer0" src={chooseLayer0} />
                <img
                  className="whyUsSec__layer1 replaceWithSVG"
                  src={chooseLayer1}
                />
                <div className="whyDotsMoving">
                  <div className="whydotmove1"></div>
                  <div className="whydotmove2"></div>
                  <div className="whydotmove3"></div>
                  <div className="whydotmove4"></div>
                  <div className="whydotmove5"></div>
                  <div className="whydotmove6"></div>
                  <div className="whydotmove7"></div>
                  <div className="whydotmove8"></div>
                </div>
              </div>

              <div className="whyUsSec__desc mob-only fade-in">
                <div className="whyUsSec__desc-row">
                  <div className="whyUsSec__desc-col">
                    <div className="whyUsSec__desc-single">
                      <img src={chooseMobThumb1} alt="" />
                      <div>Traceability and Transparency</div>
                    </div>
                  </div>
                  <div className="whyUsSec__desc-col">
                    <div className="whyUsSec__desc-single">
                      <img src={chooseMobThumb2} alt="" />
                      <div>Wholesale Product</div>
                    </div>
                  </div>
                </div>
                <div className="whyUsSec__desc-row">
                  <div className="whyUsSec__desc-col">
                    <div className="whyUsSec__desc-single">
                      <img src={chooseMobThumb3} alt="" />
                      <div>Product Authenticity</div>
                    </div>
                  </div>
                  <div className="whyUsSec__desc-col">
                    <div className="whyUsSec__desc-single">
                      <img src={chooseMobThumb4} alt="" />
                      <div>Analytics and Insights</div>
                    </div>
                  </div>
                </div>
                <div className="whyUsSec__desc-row">
                  <div className="whyUsSec__desc-col">
                    <div className="whyUsSec__desc-single">
                      <img src={chooseMobThumb5} alt="" />
                      <div>Fraud Preventions</div>
                    </div>
                  </div>
                  <div className="whyUsSec__desc-col">
                    <div className="whyUsSec__desc-single">
                      <img src={chooseMobThumb6} alt="" />
                      <div>Ownership Flow</div>
                    </div>
                  </div>
                </div>
                <div className="whyUsSec__desc-row">
                  <div className="whyUsSec__desc-col">
                    <div className="whyUsSec__desc-single">
                      <img src={chooseMobThumb7} alt="" />
                      <div>Incentive Model for B2B Product Exchange</div>
                    </div>
                  </div>
                  <div className="whyUsSec__desc-col"></div>
                </div>
              </div>
            </div>
          </section>

          <ContactSection />

          <Info />

          <Footer selectQR={selectQR} handleIdChange={handleIdChange} handleSubmit={handleSubmit} id={id} />
        </div>
        {/* <!-- #smooth-content --> */}
      </div>
      {/* <!-- #smooth-wrapper --> */}
      {showToast ? (
        <ToastContainer style={customToastContainer} />
      ) : (
        <ToastContainer />
      )}
    </div>
  );
}
