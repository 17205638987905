import { SEND_QUERY_FAILURE, SEND_QUERY_SUCCESS } from "./landing.actionTypes";

const initialState = {
  Query: [],

};

export default function landingReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {

    case SEND_QUERY_SUCCESS:
      return {
        ...state,
        Query: payload,
      };

    case SEND_QUERY_FAILURE:
      return {
        ...state,
        Query: [],
      };

    default:
      return state;
  }
}
