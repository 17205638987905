import React, { useState, useEffect } from "react";
import logo from "../assets/images/logo.svg";
import mNavLogo from "../assets/images/m-nav-logo.svg";
import mIconNavProductWhite from "../assets/images/icons/m-icon-nav-product-white.svg";
import mIconNavProductGradient from "../assets/images/icons/m-icon-nav-product-gradient.svg";
import mIconNavInventoriesWhite from "../assets/images/icons/m-icon-nav-inventories-white.svg";
import mIconNavInventoriesGradient from "../assets/images/icons/m-icon-nav-inventories-gradient.svg";
import mIconNavTrackingWhite from "../assets/images/icons/m-icon-nav-tracking-white.svg";
import mIconNavTrackingGradient from "../assets/images/icons/m-icon-nav-tracking-gradient.svg";
import mIconNavActivitiesWhite from "../assets/images/icons/m-icon-nav-activities-white.svg";
import mIconNavActivitiesGradient from "../assets/images/icons/m-icon-nav-activities-gradient.svg";
import { Link,useNavigate,useNavigation } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function BottomNavbar() {
  let { pathname } = useLocation();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState("");
  
  const handleDashboard =()=>{
    navigate("/dashboard")
    // window.location.reload(false);
  }

  useEffect(() => {
    if (dashboardPages.includes(pathname)) {
      setActivePage("dashboard");
    } else if (activitiesPages.includes(pathname)) {
      setActivePage("activities");
    } else if (productPages.includes(pathname)) {
      setActivePage("inventory");
    } else if (trackingPages.includes(pathname)) {
      setActivePage("tracking");
    } else if (pathname.startsWith("/ownership/")) {
      setActivePage("tracking");
    } else if (logisticsPages.includes(pathname)) {
      setActivePage("logistics");
    } else if (reportPages.includes(pathname)) {
      setActivePage("inventory-report");
    }else if (warrantyPages.includes(pathname)) {
      setActivePage("warranty");
    }else if (documentation.includes(pathname)) {
      setActivePage("doc");
    }
  }, [pathname]);

  const dashboardPages = ["/dashboard"];
  const activitiesPages = ["/activities"];
  const reportPages = ["/inventory-report", "/logistics-report", "/counterfeit-report"];
  const productPages = [
    // "/add-product",
    // "/detailed-information",
    // "/product-preview",
    // "/sinventoryView",
    // "/csv",
    // "/csv/basic-data",
    // "/csv/basic-data/mapping",
    // "/csv/basic-data/mapping/preview",
    "/inventoryView",
    "/product-details/:id",
  ];

  const trackingPages = [
    "/tracking",
    "/tracking-status",
    "/ownership",
    "/ownershipinventory",
    "/ownershippackage",
  ];
  const logisticsPages = [
    "/logistics-listings",
    "/pakage-information",
    "/pakaging-outsourcing-details",
    "/pakaging-basic-details",
    "/pakaging-add-items",
    "/pakaging-details-preview",
    "/warehouse-types",
    "/warehouse-basic-details",
    "/nextowner-basic-details",
    "/transportation-types",
    "/transportation-outsourcing-details",
    "/transportation-mode",
    "/inventory-preview",
    "/inventory-view",
  ];

  const warrantyPages = ["/warranty"];
  const documentation = ["/doc-introduction", "/doc-api-endpoints","/doc-authentication","/doc-formats","/doc-apiusage","/doc-errorhandling","/doc-key-generation","/doc-key-management","/doc-key-security-guidelines","/doc-sdks"];

  return (
    <nav className="w-100 bottom-nav">
      <div className="h-100 d-none d-md-block bottom-nav__logo">
        <span className="w-100 h-100">
          <img src={logo} alt="" />
        </span>
      </div>
      <ul className="list-inline m-0 h-100 font-tertiary bottom-nav__menu">
        <li onClick={handleDashboard}>
          <Link
            to="#"
            className={`fz20 nav-links ${
              activePage === "dashboard" ? "active-link" : ""
            }`}
          >
            <img src={mNavLogo} alt="" />
            <span>Dashboard</span>
          </Link>
        </li>

        <li>
          <Link
            to="/inventoryView"
            className={`fz20 nav-links ${
              activePage === "inventory" ? "active-link" : ""
            }`}
          >
            <div className="toggle-image">
              <img className="t-white" src={mIconNavProductWhite} alt="" />
              <img
                className="t-gradient"
                src={mIconNavProductGradient}
                alt=""
              />
            </div>
            <span>Inventory</span>
          </Link>
        </li>
        <li>
          <Link
            to="/logistics-listings"
            className={`fz20 nav-links ${
              activePage === "logistics" ? "active-link" : ""
            }`}
          >
            <div className="toggle-image">
              <img className="t-white" src={mIconNavInventoriesWhite} alt="" />
              <img
                className="t-gradient"
                src={mIconNavInventoriesGradient}
                alt=""
              />
            </div>
            <span>Logistics</span>
          </Link>
        </li>
        <li>
          <Link
            to="/tracking"
            className={`fz20 nav-links ${
              activePage === "tracking" ? "active-link" : ""
            }`}
          >
            <div className="toggle-image">
              <img className="t-white" src={mIconNavTrackingWhite} alt="" />
              <img
                className="t-gradient"
                src={mIconNavTrackingGradient}
                alt=""
              />
            </div>
            <span>Tracking</span>
          </Link>
        </li>
        <li>
          <Link
            to="/inventory-report"
            className={`fz20 nav-links ${
              activePage === "inventory-report" ? "active-link" : ""
            }`}
          >
            <div className="toggle-image">
              <img className="t-white" src={mIconNavActivitiesWhite} alt="" />
              <img
                className="t-gradient"
                src={mIconNavActivitiesGradient}
                alt=""
              />
            </div>
            <span>Reports</span>
          </Link>
        </li>
        {/* <li className="d-none d-md-block" onClick={handleRedirect}> */}
        <li className="d-none d-md-block">
          <Link
            to="/warranty"
            className={`fz20 nav-links ${
              activePage === "warranty" ? "active-link" : ""
            }`}
          >
            <span>Warranty</span>
          </Link>
        </li>
        <li className="d-none d-md-block">
          <Link
            to="/doc-introduction"
            className={`fz20 nav-links ${
              activePage === "doc" ? "active-link" : ""
            }`}
          >
            <span>API Docs</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}
