export const ADD_NEXTOWNER_SUCCESS = "logistics/ADD_NEXTOWNER_SUCCESS";
export const ADD_NEXTOWNER_FAILURE = "logistics/ADD_NEXTOWNER_FAILURE";
export const GET_SINGLE_NEXTOWNER_SUCCESS = "logistics/GET_SINGLE_NEXTOWNER_SUCCESS";
export const GET_SINGLE_NEXTOWNER_FAILURE = "logistics/GET_SINGLE_NEXTOWNER_FAILURE";
export const GET_ALL_NEXTOWNER_SUCCESS = "logistics/GET_ALL_NEXTOWNER_SUCCESS";
export const GET_ALL_NEXTOWNER_FAILURE = "logistics/GET_ALL_NEXTOWNER_FAILURE";
export const UPDATE_NEXTOWNER_SUCCESS = "logistics/UPDATE_NEXTOWNER_SUCCESS";
export const UPDATE_NEXTOWNER_FAILURE = "logistics/UPDATE_NEXTOWNER_FAILURE";
export const All_CATAGORY_ID_SUCCESS = "logistics/All_CATAGORY_ID_SUCCESS";
export const All_CATAGORY_ID_FAILURE = "logistics/All_CATAGORY_ID_FAILURE";
