import React, { useEffect, useState } from "react";
import BottomNavbar from "./bottomNavbar";
import Navbar from "./navbar";
import PageList from "../reusable_components/pageList";
import { useDispatch, useSelector } from "react-redux";


import {
  getAllInventoryRequests,
  handleInventoryApproveRequest,
} from "../Redux/InventoryOwnership/InventoryOwnership.action";
import { getAllPackageRequests,handlePackageApproveRequest } from "../Redux/PackageOwnership/PackageOwnership.action";
import {
  formatDate,
  formatHour,
  formatMinute,
  formatSecond,
} from "../services/utils";

const Notification = () => {
  const [selectedData, setSelectedData] = useState("Inventory");
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state);
  const authState = useSelector((store) => store.authManager);
  const InventoryownerShipState = useSelector(
    (store) => store.inventoryOwnerShipManager
  );
  const PackageownerShipState = useSelector(
    (store) => store.packageOwnershipManager
  );

  console.log("PackageownerShipState", PackageownerShipState);
  const DisplayData = PackageownerShipState?.DisplayData;
  
console.log("DisplayData", DisplayData);
  const AllReceivedInventoryRequestArray =
    InventoryownerShipState?.AllReceivedRequestArray;

  const AllReceivedPackageRequestArray =
    PackageownerShipState?.AllReceivedRequestArray;

  
 
  
  useEffect(() => {
    dispatch(getAllInventoryRequests());
    dispatch(getAllPackageRequests());
  }, [DisplayData]);

  return (
    <div className="paye-layout">
      <PageList />
      <div className="content-layout">
        <Navbar />

        <section className="product-screen">
          <div className="w-100 border-white-2 bdrs10 main-shadow block-widget custom-data-table">
            <div className="top-row">
              <div className="cdt-length top-row__left">
                <h2 className="fw500 mb-0 fz14">Alert On</h2>
                <p className="mb-0 fst-italic fz12 fw300">
                  Updated 03hrs:23min ago.
                </p>
              </div>

              <div className="btn-top-group pt-2 pb-1">
                <label htmlFor="" className="label fz14 fw500">
                  Selected 0/100
                </label>
                <div className="td-action">
                  <button className="btn main-shadow td-action--accept">
                    <span className="fw600 fz14 gradientText">Accept all</span>
                  </button>
                  <button className="btn td-action--reject">
                    <span className="fw600 fz14">Reject all</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              {DisplayData == "Inventory" ? (
                <>
                  {" "}
                  {AllReceivedInventoryRequestArray?.length ? (
                    <table className="w-100 table holograf-table hover">
                      <thead>
                        <tr>
                          <th>
                            <span className="select-all">
                              <div className="lh-1 checkbox-wrapper">
                                <input id="all-01" type="checkbox" />
                                <label htmlFor="all-01">
                                  <span></span>
                                  <div>
                                    <b className="fw500 fz14">Select</b>
                                  </div>
                                </label>
                              </div>
                            </span>
                          </th>
                          <th>
                            <span className="fz14 fw500">Id</span>
                          </th>
                          
                          <th>
                            <span className="fz14 fw500 justify-content-center">
                              Brand
                            </span>
                          </th>
                          <th>
                            <span className="fz14 fw500">Request By</span>
                          </th>
                          <th>
                            <span className="fz14 fw500 justify-content-center">
                              Status
                            </span>
                          </th>
                          <th>
                            <span className="fz14 fw500 justify-content-center">
                              Action
                            </span>
                          </th>
                          <th>
                            <span className="fz14 fw500">Date</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {AllReceivedInventoryRequestArray.map((user, index) => {
                          if (user.status == "request") {
                            return (
                              <tr key={index}>
                                <td className="text-center">
                                  <div className="lh-1 checkbox-wrapper">
                                    <input id="action-01" type="checkbox" />
                                    <label htmlFor="action-01">
                                      <span></span>
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <span className="fz12 id_cell">
                                    {user?.stock_item?.display_id}
                                  </span>
                                </td>

                                <td className="text-center">
                                  <div className="brnd_btn_black">
                                    <span>
                                      {user?.stock_item?.product?.brand}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="fw600 fz12">
                                    {user?.new_item_owner?.email}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <div className={`n-status n-status--pending`}>
                                    <span className="fw600 fz14 gradientText">
                                      Pending
                                    </span>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div className="td-action">
                                    <button
                                      onClick={() =>
                                        dispatch(
                                          handleInventoryApproveRequest(
                                            user?.id
                                          )
                                        )
                                      }
                                      className="btn main-shadow td-action--accept"
                                    >
                                      <span className="fw600 fz14 gradientText">
                                        Accept
                                      </span>
                                    </button>
                                    <button className="btn td-action--reject">
                                      <span className="fw600 fz14">Reject</span>
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <span className="d-block mb-2">
                                    {formatDate(user?.stock_item?.created_at)}{" "}
                                  </span>
                                  <span className="d-block">
                                    {formatHour(user?.stock_item?.created_at)}hr:{" "}
                                    {formatMinute(user?.stock_item?.created_at)}min :{" "}
                                    {formatSecond(user?.stock_item?.created_at)}sec
                                    (EST)
                                  </span>
                                </td>
                              </tr>
                            );
                          } else if (user.status == "approved") {
                            return (
                              <tr key={index}>
                                <td className="text-center">
                                  <div className="lh-1 checkbox-wrapper">
                                    <input id="action-03" type="checkbox" />
                                    <label htmlFor="action-03">
                                      <span></span>
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <span className="fz12 id_cell">
                                    {user?.stock_item?.display_id}
                                  </span>
                                </td>

                                <td className="text-center">
                                  <div className="brnd_btn_black">
                                    <span>
                                      {user?.stock_item?.product?.brand}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <span className="fw600 fz12">
                                    {user?.new_item_owner?.email}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <div className="n-status n-status--responded">
                                    <span className="fz14">Responded</span>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div className="td-action">
                                    <button className="btn td-action--accepted">
                                      <span className="fw600 fz14">
                                        Accepted
                                      </span>
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <span className="d-block mb-2">
                                    {formatDate(user?.stock_item?.created_at)}{" "}
                                  </span>
                                  <span className="d-block">
                                    {formatHour(user?.stock_item?.created_at)}
                                    hr:{" "}
                                    {formatMinute(user?.stock_item?.created_at)}
                                    min :{" "}
                                    {formatSecond(user?.stock_item?.created_at)}
                                    sec (EST)
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <h5 style={{ textAlign: "center", color: "green" }}>
                      No Pending Request....!
                    </h5>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  {AllReceivedPackageRequestArray?.length ? (
                    <table className="w-100 table holograf-table hover">
                      <thead>
                        <tr>
                          <th>
                            <span className="select-all">
                              <div className="lh-1 checkbox-wrapper">
                                <input id="all-01" type="checkbox" />
                                <label htmlFor="all-01">
                                  <span></span>
                                  <div>
                                    <b className="fw500 fz14">Select</b>
                                  </div>
                                </label>
                              </div>
                            </span>
                          </th>
                          <th>
                            <span className="fz14 fw500">Package Id</span>
                          </th>
                          
                          <th>
                            <span className="fz14 fw500 justify-content-center">
                              Brand
                            </span>
                          </th>
                          <th>
                            <span className="fz14 fw500">Request By</span>
                          </th>
                          <th>
                            <span className="fz14 fw500 justify-content-center">
                              Status
                            </span>
                          </th>
                          <th>
                            <span className="fz14 fw500 justify-content-center">
                              Action
                            </span>
                          </th>
                          <th>
                            <span className="fz14 fw500">Date</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {AllReceivedPackageRequestArray.map((user, index) => {
                          if (user.status == "request") {
                            return (
                              <tr key={index}>
                                <td className="text-center">
                                  <div className="lh-1 checkbox-wrapper">
                                    <input id="action-01" type="checkbox" />
                                    <label htmlFor="action-01">
                                      <span></span>
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <span className="fz12 id_cell">
                                    {user?.package?.display_id}
                                  </span>
                                </td>

                                <td className="text-center">
                                  <div className="brnd_btn_black">
                                    <span>{user?.package?.brand_name}</span>
                                  </div>
                                </td>
                                <td>
                                  <span className="fw600 fz12">
                                    {user?.new_package_owner?.email}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <div className={`n-status n-status--pending`}>
                                    <span className="fw600 fz14 gradientText">
                                      Pending
                                    </span>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div className="td-action">
                                    <button
                                      onClick={() =>
                                        dispatch(
                                          handlePackageApproveRequest(user?.id)
                                        )
                                      }
                                      className="btn main-shadow td-action--accept"
                                    >
                                      <span className="fw600 fz14 gradientText">
                                        Accept
                                      </span>
                                    </button>
                                    <button className="btn td-action--reject">
                                      <span className="fw600 fz14">Reject</span>
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <span className="d-block mb-2">
                                    {formatDate(user?.package?.created_at)}{" "}
                                  </span>
                                  <span className="d-block">
                                    {formatHour(user?.package?.created_at)}
                                    hr:{" "}
                                    {formatMinute(user?.package?.created_at)}
                                    min :{" "}
                                    {formatSecond(user?.package?.created_at)}
                                    sec (EST)
                                  </span>
                                </td>
                              </tr>
                            );
                          } else if (user.status == "approved") {
                            return (
                              <tr key={index}>
                                <td className="text-center">
                                  <div className="lh-1 checkbox-wrapper">
                                    <input id="action-03" type="checkbox" />
                                    <label htmlFor="action-03">
                                      <span></span>
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <span className="fz12 id_cell">
                                    {user?.package?.display_id}
                                  </span>
                                </td>

                                <td className="text-center">
                                  <div className="brnd_btn_black">
                                    <span>{user?.package?.brand_name}</span>
                                  </div>
                                </td>
                                <td>
                                  <span className="fw600 fz12">
                                    {user?.new_package_owner?.email}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <div className="n-status n-status--responded">
                                    <span className="fz14">Responded</span>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div className="td-action">
                                    <button className="btn td-action--accepted">
                                      <span className="fw600 fz14">
                                        Accepted
                                      </span>
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <span className="d-block mb-2">
                                    {formatDate(user?.package?.created_at)}{" "}
                                  </span>
                                  <span className="d-block">
                                    {formatHour(user?.package?.created_at)}
                                    hr:{" "}
                                    {formatMinute(user?.package?.created_at)}
                                    min :{" "}
                                    {formatSecond(user?.package?.created_at)}
                                    sec (EST)
                                  </span>
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <h5 style={{ textAlign: "center", color: "green" }}>
                      No Pending Request....!
                    </h5>
                  )}
                </>
              )}
            </div>
          </div>
        </section>
      </div>
      <BottomNavbar />
    </div>
  );
};

export default Notification;
