import React from "react";
import PageList from "../../../reusable_components/pageList";
import Navbar from "../../../layouts/navbar";
import BottomNavbar from "../../../layouts/bottomNavbar";
import apiDocHead from "../../../assets/images/icons/api-doc-head.svg";
import exclamatory from "../../../assets/images/icons/exclamatory-icon.svg";
import DocSidebar from "../docSidebar";
import urlPasteBlack from "../../../assets/images/icons/url-paste-black.svg";

export default function SecurityGuidelines() {
  return (
    <div className="paye-layout">
      <PageList />
      <div className="content-layout">
        <Navbar />
        <section className="product-screen api-doc-screen">
          <DocSidebar />
          <div className="w-100 api-document m-0">
            <div className="api-doc-head d-flex align-items-center gap-2 gx-0">
              <img src={apiDocHead} alt="" />
              <span className="fw600 fz28 text-dark">Security Guidelines</span>
            </div>
            <div className="documentation">
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                Holografs is acquired by exchanging an email + otp for an access
                token and an refresh token. The access token is usually
                short-lived (expires in 5 min or so, can be customized though).
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                The refresh token lives a little bit longer (expires in 24
                hours, also customizable). It is comparable to an authentication
                session. After it expires, you need a full login with username +
                password again.
              </h3>
              <br />
              <h3 className="fz16 fw600 grayText first-letter-capital mb-2">
                Those are three distinctive parts that compose holografs:
              </h3>
              <br />
              <div className="black-box-inside mb-2">
                <div className="d-flex align-items-center gap-2">
                  <button className="white-box">
                    <img src={urlPasteBlack} alt="" />
                  </button>
                  <a className="btn btn-gradient-border endpoints-insideCardBtn rounded-1">
                    <div className="fw400 fz14">
                      <div className="px-3 gradientText--radial">
                        <span className="fz16 fw600">Sample</span>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="endpoints-inside-text">
                  <p className="text-green fw400 fz12 mb-0"></p>
                  <div className="fz12 array-values">
                    <div className="orange-text">
                      <span className="yellow-text">header</span> =
                      eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9 <br />
                      <span className="yellow-text">payload</span> =
                      eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNTQzODI4NDMxLCJqdGkiOiI3ZjU5OTdiNzE1MGQ0NjU3OWRjMmI0OTE29{" "}
                      <br />
                      <span className="yellow-text">signature</span> =
                      Ju70kdcaHKn1Qaz8H42zrOYk0Jx9kIckTn9Xx7vhikY
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <BottomNavbar />

      {/* <!-- Modal --> */}
      <div
        className="modal fade m-modal"
        id="sureModal"
        tabindex="-1"
        aria-labelledby="sureModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="border-0 modal-content">
            <div className="modal_wrapper border-white-2 bdrs10 main-shadow">
              <div className="main-block">
                <div className="content-inner">
                  <img src={exclamatory} alt="" className="mx-auto" />

                  <div className="inner-text">
                    <h3 className="fz60 fw300">Are You Sure!</h3>
                    <p className="fz46 mb-0 lh-1 fw600">
                      You want to cancel the process?
                    </p>
                  </div>

                  <div className="btn-row">
                    <button
                      data-bs-dismiss="modal"
                      className="btn my-2 fz14 bdrs10 btn-dark-outlined black-default"
                    >
                      <span className="fz14 gradientText--radial fw400">
                        No!
                      </span>
                    </button>

                    <a
                      href="./index.html"
                      className="btn my-2 fz14 bdrs10 btn-dark-outlined modal-confirm-btn"
                    >
                      <span className="fz14">Yes</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
