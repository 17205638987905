export const TRACKING_BY_ID_SUCCESS = "profile/TRACKING_BY_ID_SUCCESS";
export const TRACKING_BY_ID_FAILURE = "profile/TRACKING_BY_ID_FAILURE";
export const LOADER_TRUE = "profile/LOADER_TRUE";
export const LOADER_FALSE = "profile/LOADER_FALSE";
export const PACKAGE_USER_DETAILS_SUCCESS ="tracking/PACKAGE_USER_DETAILS_SUCCESS";
export const PACKAGE_USER_DETAILS_FAILURE ="tracking/PACKAGE_USER_DETAILS_FAILURE";
export const INVENTORY_USER_DETAILS_SUCCESS ="tracking/INVENTORY_USER_DETAILS_SUCCESS";
export const INVENTORY_USER_DETAILS_FAILURE ="tracking/INVENTORY_USER_DETAILS_FAILURE";
export const INVENTORY_GET_SINGLE_PRODUCT_SUCCESS ="tracking/INVENTORY_GET_SINGLE_PRODUCT_SUCCESS";
export const INVENTORY_GET_SINGLE_PRODUCT_FAILURE ="tracking/INVENTORY_GET_SINGLE_PRODUCT_FAILURE";
export const INVENTORY_GET_SINGLE_PRODUCT_DETAILS_SUCCESS ="tracking/INVENTORY_GET_SINGLE_PRODUCT_DETAILS_SUCCESS";
export const INVENTORY_GET_SINGLE_PRODUCT_DETAILS_FAILURE ="tracking/INVENTORY_GET_SINGLE_PRODUCT_DETAILS_FAILURE";
export const COUNTERFEIT_COMPLAIN_SUCCESS = "profile/COUNTERFEIT_COMPLAIN_SUCCESS";
export const COUNTERFEIT_COMPLAIN_FAILURE = "profile/COUNTERFEIT_COMPLAIN_FAILURE";
export const ALL_COUNTERFEIT_COMPLAIN_SUCCESS = "profile/ALL_COUNTERFEIT_COMPLAIN_SUCCESS";
export const ALL_COUNTERFEIT_COMPLAIN_FAILURE = "profile/ALL_COUNTERFEIT_COMPLAIN_FAILURE";