// OwnerShip Flow

export const GET_OWNER_SUCCESS = "ownership/GET_OWNER_SUCCESS";
export const GET_OWNER_FAILURE = "ownership/GET_OWNER_FAILURE";
export const REQUEST_OWNERSHIP_SUCCESS = "ownership/REQUEST_OWNERSHIP_SUCCESS";
export const REQUEST_OWNERSHIP_FAILURE = "ownership/REQUEST_OWNERSHIP_FAILURE";
export const APPROVE_OWNERSHIP_SUCCESS = "ownership/APPROVE_OWNERSHIP_SUCCESS";
export const APPROVE_OWNERSHIP_FAILURE = "ownership/APPROVE_OWNERSHIP_FAILURE";
export const GET_OWNERSHIP_STATUS_SUCCESS = "ownership/GET_OWNERSHIP_STATUS_SUCCESS";
export const GET_OWNERSHIP_STATUS_FAILURE = "ownership/GET_OWNERSHIP_STATUS_FAILURE";
export const GET_ALL_SENT_REQUEST_SUCCESS = "ownership/GET_ALL_SENT_REQUEST_SUCCESS";
export const GET_ALL_SENT_REQUEST_FAILURE = "ownership/GET_ALL_SENT_REQUEST_FAILURE";
export const GET_OWNER_DETAILS_SUCCESS = "ownership/GET_OWNER_DETAILS_SUCCESS";
export const GET_OWNER_DETAILS_FAILURE = "ownership/GET_OWNER_DETAILS_FAILURE";
export const GET_ALL_RECEIVED_REQUEST_SUCCESS =
  "ownership/GET_ALL_RECEIVED_REQUEST_SUCCESS";
export const GET_ALL_RECEIVED_REQUEST_FAILURE =
  "ownership/GET_ALL_RECEIVED_REQUEST_FAILURE";
  

