import{ADD_OUTSOUCE_TRANSPORTATION_SUCCESS,ADD_OUTSOUCE_TRANSPORTATION_FAILURE,ADD_TRANSPORTATION_DETAILS_SUCCESS,ADD_TRANSPORTATION_DETAILS_FAILURE,GET_SINGLE_OUTSOURCE_DETAILS_SUCCESS,GET_SINGLE_OUTSOURCE_DETAILS_FAILURE} from "./transportation.actionTypes"


const initialState = {
    addedOutsourceTransportationID:null,
    outsourceDetails:{},
    transportationID:null
    
};

export default function TransportationReducer(
    state = initialState,
    { type, payload }
  ) {
    switch (type) {
      case ADD_OUTSOUCE_TRANSPORTATION_SUCCESS:
        return {
          ...state,
          addedOutsourceTransportationID: payload,
        };

        case ADD_OUTSOUCE_TRANSPORTATION_FAILURE:
        return {
          ...state,
          addedOutsourceTransportationID: null,
        };

        case GET_SINGLE_OUTSOURCE_DETAILS_SUCCESS:
        return {
          ...state,
          outsourceDetails: payload,
        };
        case ADD_TRANSPORTATION_DETAILS_SUCCESS:
          return {
            ...state,
            transportationID: payload,
          };
          case ADD_TRANSPORTATION_DETAILS_FAILURE:
            return {
              ...state,
              transportationID: payload,
            };
        case GET_SINGLE_OUTSOURCE_DETAILS_FAILURE:
        return {
          ...state,
          outsourceDetails: {},
        };
      
  
      default:
        return state;
    }
  }
  