import React from "react";
import apiDocHead from "../../../assets/images/icons/api-doc-head.svg";
import exclamatory from "../../../assets/images/icons/exclamatory-icon.svg";
import { Link } from "react-router-dom";
import PageList from "../../../reusable_components/pageList";
import Navbar from "../../../layouts/navbar";
import BottomNavbar from "../../../layouts/bottomNavbar";
import DocSidebar from "../docSidebar";
import urlPasteBlack from "../../../assets/images/icons/url-paste-black.svg";

export default function ApiUsage() {
  return (
    <div className="paye-layout">
      <PageList />
      <div className="content-layout">
        <Navbar />
        <section className="product-screen api-doc-screen">
          <DocSidebar />

          <div className="w-100 api-document m-0">
            <div className="api-doc-head d-flex align-items-center gap-2 gx-0">
              <img src={apiDocHead} alt="" />
              <span className="fw600 fz28 text-dark">Usage Examples</span>
            </div>

            <div className="documentation">
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                API (Application Programming Interface) integration involves the
                communication between different software systems. This
                communication is facilitated through requests and responses.
                Let's break down the key details regarding requests and
                responses in the context of API integration:
              </h3>
              <br />

              <h3 className="fw600 fz22 mb-3">HTTP Methods:</h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">GET: </span> Retrieves data from the
                server.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">POST: </span> Sends data to the server
                to create a new resource.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">PUT: </span> Updates a resource on the
                server.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">DELETE: </span> Deletes a resource on
                the server.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">PATCH: </span> Partially updates a
                resource on the server.
              </h3>
              <br />

              <h3 className="fw600 fz22 mb-3">Headers:</h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">Content-Type: </span>
                Specifies the format of the data being sent (e.g., JSON, XML).
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">Authorization: </span>
                Provides authentication information.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">Accept: </span>
                Informs the server about the types of data that can be accepted.
              </h3>
              <br />

              <h3 className="fw600 fz22 mb-3">Query-Parameters:</h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">Query Parameters: </span>
                Used in a URL to filter or paginate results.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">Request Body: </span>
                Carries data for operations like creating or updating resources.
              </h3>
              <br />

              <h3 className="fw600 fz22 mb-3">Authentication:</h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                APIs often require authentication using API keys, tokens, or
                other mechanisms to ensure secure communication.
              </h3>
              <br />

              <h3 className="fw600 fz22 mb-3">Endpoint:</h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                The specific URL where the API is located. Different endpoints
                correspond to different functionalities.
              </h3>
              <br />

              <h3 className="fw600 fz22 mb-3"> Responses:</h3>
              <h3 className="fz16 fw600 grayText first-letter-capital mb-2">HTTP Status Codes:</h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">2xx: </span>
                Success (e.g., 200 OK, 201 Created).
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">3xx: </span>
                Redirection (e.g., 301 Moved Permanently, 304 Not Modified).
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">4xx: </span>
                Client errors (e.g., 400 Bad Request, 401 Unauthorized, 404 Not
                Found).
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">5xx: </span>
                Server errors (e.g., 500 Internal Server Error).
              </h3>
              <br />

              <h3 className="fw600 fz22 mb-3">Headers:</h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">Content-Type: </span>
                Informs the client about the format of the data in the response.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">Location: </span>
                Provides the URL of a newly created resource.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                <span className="fw600">Authorization: </span>
                Indicates the authentication method used.
              </h3>
              <br />

              <h3 className="fw600 fz22 mb-3">Response Body:</h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                Contains the requested data or information about the
                success/failure of the operation. Often in formats like JSON or
                XML.
              </h3>

              <br />

              <h3 className="fw600 fz22 mb-3">Pagination:</h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                For large datasets, responses may include information on how to
                retrieve additional pages of data.
              </h3>

              <br />

              <h3 className="fw600 fz22 mb-3">Error Handling:</h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                APIs should provide clear error messages in case of failures,
                along with appropriate HTTP status codes.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                Those are three distinctive parts that compose holografs:
              </h3>
              <div className="documentation">
                <div className="black-box-inside mb-2">
                  <div className="d-flex align-items-center gap-2">
                    <a className="btn btn-gradient-border endpoints-insideCardBtn rounded-1">
                      <div className="fw400 fz14">
                        <div className="px-3 gradientText--radial">
                          <span className="fz16 fw600">Example</span>
                        </div>
                      </div>
                    </a>
                  </div>{" "}
                  <br />
                  <a className="btn btn-gradient-border endpoints-insideCardBtn rounded-1">
                    <div className="fw400 fz14">
                      <div className="px-3 gradientText--radial">
                        <span className="fz16 fw600">Request POST</span>
                      </div>
                    </div>
                  </a>
                  <div className="endpoints-inside-text">
                    <p className="text-green fw400 fz12 mb-0"></p>

                    <div className="fz12 array-values">
                      <div className="orange-text">
                        <span className="yellow-text">
                          {" "}
                          POST /api/users Content-Type: application/json
                          Authorization: Bearer {"<"}token{">"}
                        </span>
                        <span className="pink-text">{"{"}</span>
                        <br />
                        "email": "john.doe@example.com", "otp": "secretpassword"
                        <br />
                        <span className="pink-text">{"}"}</span>
                        <br />
                        <span className="yellow-text">
                          {" "}
                          Response (201 Created):
                        </span>
                        <br />
                        HTTP/1.1 201 Created
                        <br />
                        Content-Type: application/json
                        <br />
                        <span className="pink-text">{"{"}</span>
                        <br />
                        "id": 123, "name": "John Doe", "email":
                        "john.doe@example.com"
                        <br />
                        <span className="pink-text">{"}"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <BottomNavbar />

      {/* <!-- Modal --> */}
      <div
        className="modal fade m-modal"
        id="sureModal"
        tabindex="-1"
        aria-labelledby="sureModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="border-0 modal-content">
            <div className="modal_wrapper border-white-2 bdrs10 main-shadow">
              <div className="main-block">
                <div className="content-inner">
                  <img src={exclamatory} alt="" className="mx-auto" />

                  <div className="inner-text">
                    <h3 className="fz60 fw300">Are You Sure!</h3>
                    <p className="fz46 mb-0 lh-1 fw600">
                      You want to cancel the process?
                    </p>
                  </div>

                  <div className="btn-row">
                    <button
                      data-bs-dismiss="modal"
                      className="btn my-2 fz14 bdrs10 btn-dark-outlined black-default"
                    >
                      <span className="fz14 gradientText--radial fw400">
                        No!
                      </span>
                    </button>

                    <a
                      href="./index.html"
                      className="btn my-2 fz14 bdrs10 btn-dark-outlined modal-confirm-btn"
                    >
                      <span className="fz14">Yes</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
