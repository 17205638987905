export const GET_ALL_PACKAGE_SUCCESS = "dashboard/GET_ALL_PACKAGE_SUCCESS";
export const GET_ALL_PACKAGE_FAILURE = "dashboard/GET_ALL_PACKAGE_FAILURE";
export const GET_ALL_INVENTORY_SUCCESS = "dashboard/GET_ALL_INVENTORY_SUCCESS";
export const GET_ALL_INVENTORY_FAILURE = "dashboard/GET_ALL_INVENTORY_FAILURE";
export const GET_ALL_PRODUCT_SUCCESS = "dashboard/GET_ALL_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCT_FAILURE = "dashboard/GET_ALL_PRODUCT_FAILURE";
export const LOADER_TRUE = "dashboard/LOADER_TRUE";
export const LOADER_FALSE = "dashboard/LOADER_FALSE";
export const INVENTORY_COUNT_PERMONTH_SUCCESS =
  "dashboard/INVENTORY_COUNT_PERMONTH_SUCCESS";
export const INVENTORY_COUNT_PERMONTH_FAILURE =
  "dashboard/INVENTORY_COUNT_PERMONTH_FAILURE";
export const GET_ALL_PRODUCTS_COUNT_SUCCESS =
  "dashboard/GET_ALL_PRODUCTS_COUNT_SUCCESS";
export const FILTERED_INVENTORY_COUNT_SUCCESS =
  "dashboard/FILTERED_INVENTORY_COUNT_SUCCESS";
export const FILTERED_PRODUCT_INVENTORY_COUNT_SUCCESS =
  "dashboard/FILTERED_PRODUCT_INVENTORY_COUNT_SUCCESS";