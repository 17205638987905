import {
  GET_INVENTORY_OWNER_SUCCESS,
  GET_INVENTORY_OWNER_FAILURE,
  REQUEST_INVENTORY_OWNERSHIP_SUCCESS,
  REQUEST_INVENTORY_OWNERSHIP_FAILURE,
  APPROVE_INVENTORY_OWNERSHIP_SUCCESS,
  APPROVE_INVENTORY_OWNERSHIP_FAILURE,
  GET_INVENTORY_OWNERSHIP_STATUS_SUCCESS,
  GET_INVENTORY_OWNERSHIP_STATUS_FAILURE,
  GET_INVENTORY_ALL_SENT_REQUEST_SUCCESS,
  GET_INVENTORY_ALL_SENT_REQUEST_FAILURE,
  GET_INVENTORY_OWNER_DETAILS_SUCCESS,
  GET_INVENTORY_OWNER_DETAILS_FAILURE,
  GET_INVENTORY_ALL_RECEIVED_REQUEST_SUCCESS,
  GET_INVENTORY_ALL_RECEIVED_REQUEST_FAILURE,
} from "./InventoryOwnership.actionTypes";


const initialState = {
  currentStatus: "",
  ownerId: null,
  requestId: null,
  ownerDetails: [],
  ProductDetails: null,
  ownerActive: false,
  AllReceivedRequestArray: [],
};

export default function inventoryOwnerShipReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case GET_INVENTORY_ALL_SENT_REQUEST_SUCCESS:
      return {
        ...state,
        currentStatus: payload,
      };

    case GET_INVENTORY_ALL_SENT_REQUEST_FAILURE:
      return {
        ...state,
        currentStatus: payload,
      };

    case GET_INVENTORY_OWNER_DETAILS_SUCCESS:
      return {
        ...state,
        ownerDetails: [...state.ownerDetails, payload.ownerDetails],
      };
    case GET_INVENTORY_OWNER_DETAILS_FAILURE:
      return {
        ...state,
        ownerDetails: [],
      };

    case GET_INVENTORY_OWNER_SUCCESS:
      return {
        ...state,
        currentStatus: "",
        ownerDetails: [],
      };

    case GET_INVENTORY_OWNER_FAILURE:
      return {
        ...state,
        currentStatus: "",
        ownerDetails: [],
      };

    case GET_INVENTORY_OWNERSHIP_STATUS_SUCCESS:
      return {
        ...state,
        currentStatus: payload,
      };
    case GET_INVENTORY_OWNERSHIP_STATUS_FAILURE:
      return {
        ...state,
        currentStatus: "",
      };

    case GET_INVENTORY_ALL_RECEIVED_REQUEST_SUCCESS:
      return {
        ...state,
        AllReceivedRequestArray: payload,
      };

    case GET_INVENTORY_ALL_RECEIVED_REQUEST_FAILURE:
      return {
        ...state,
        AllReceivedRequestArray: [],
      };

    case APPROVE_INVENTORY_OWNERSHIP_SUCCESS:
      return state;

    case APPROVE_INVENTORY_OWNERSHIP_FAILURE:
      return state;

    case REQUEST_INVENTORY_OWNERSHIP_SUCCESS:
      return {
        ...state,
        requestId: payload,
      };
    case REQUEST_INVENTORY_OWNERSHIP_FAILURE:
      return {
        ...state,
        requestId: null,
      };
    
      default:
      return state;
  }
}