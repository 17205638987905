import React from 'react';
import successToasterImage from '../assets/images/icons/toaster-success.svg';

const SuccessToast = ({content}) => {
  return (
    <div className="add-product-modal-content">
            <div className="p-0 m-0 modal-header">
    <div className="header-topBar toaster-success">
      <h3 className="mb-0 d-inline-flex">
        {/* Uncomment the line below if you want to include the long-arrow icon */}
        {/* <img src="./assets/images/icons/icon-long-arrow.svg" alt=""> */}
        <img className="success-img" src={successToasterImage} alt="Success Toaster" />
        <span className="m-0 fw600 fz24 success-message">{content}</span>
      </h3>
    </div>
    </div>
    </div>
  );
};

export default SuccessToast;
