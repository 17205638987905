import { UPLOAD_QR_FAILURE } from "../SingleProduct/singleProduct.actionTypes";
import {
  GET_ALL_NEXTOWNER_FAILURE,
  GET_ALL_NEXTOWNER_SUCCESS,
  ADD_NEXTOWNER_FAILURE,
  ADD_NEXTOWNER_SUCCESS,
  GET_SINGLE_NEXTOWNER_FAILURE,
  GET_SINGLE_NEXTOWNER_SUCCESS,
  UPDATE_NEXTOWNER_FAILURE,
  UPDATE_NEXTOWNER_SUCCESS,
  All_CATAGORY_ID_FAILURE,
  All_CATAGORY_ID_SUCCESS
} from "./nextowner.actiontypes";

const initialState = {
  addedNextownerID: null,
  nextOwnerDetails: {},
  AllNextowners: [],
  ALLCat_BrandArr:[]
};

export default function NextOwnerReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ADD_NEXTOWNER_SUCCESS:
      return {
        ...state,
        addedNextownerID: payload,
      };
    case ADD_NEXTOWNER_FAILURE:
      return {
        ...state,
        addedNextownerID: null,
      };
    case GET_SINGLE_NEXTOWNER_SUCCESS:
      return {
        ...state,
        nextOwnerDetails: payload,
      };

    case GET_SINGLE_NEXTOWNER_FAILURE:
      return {
        ...state,
        nextOwnerDetails: {},
      };

    case GET_ALL_NEXTOWNER_SUCCESS:
      return {
        ...state,
        AllNextowners: payload,
      };

    case GET_ALL_NEXTOWNER_FAILURE:
      return {
        ...state,
        AllNextowners: [],
      };

    case UPDATE_NEXTOWNER_SUCCESS:
      return {
        ...state,
      };

    case UPDATE_NEXTOWNER_FAILURE:
      return {
        ...state,
      };

    case All_CATAGORY_ID_SUCCESS:
      return {
        ...state,
        ALLCat_BrandArr: payload,
      };

    case All_CATAGORY_ID_FAILURE:
      return {
        ...state,
        ALLCat_BrandArr: [],
      };
    default:
      return state;
  }
}
