import React, { useEffect } from 'react'
import leaf from "../../assets/images/landing/subscribe/leaf.svg";
import deco1 from "../../assets/images/landing/subscribe/deco1.svg";
import layer0 from "../../assets/images/landing/subscribe/layer0.png";
import layer1 from "../../assets/images/landing/subscribe/layer1.png";
import headerLogo from "../../assets/images/landing/truck/truck-right.svg";
import { Link } from 'react-router-dom';

const style1 = {
  lineHeight: '60px'
}
const desc = {
  fontSize: '18px'
}

export default function ThankYouPage() {

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `gtag('event', 'conversion', {'send_to': 'AW-16608198606/25CbCJHx97wZEM7_s-89'});`;
    document.head.appendChild(script);

    return () => {
        document.head.removeChild(script);
    };
}, []);

  useEffect(() => {
    let script = document.createElement("script");
    script.id = "scriptId";
    script.src = "/assets/scripts/landing.js";
    document.body.append(script);
    const handlePopState = (event) => {
      if (event.state === null) {
        console.log("Back Button Pressed")
      }

    };
    window.addEventListener('popstate', handlePopState);

    return () => {
      const scriptElement = document.getElementById("scriptId");
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <>
      <section className="subscribeSec">
        <div className="container">
          <div className="subscribeSec__inner pb-5">
            <div className="subscribeSec__content w-100 fade-in">
              <h2 className="head1" style={style1}>
                Thank You for Your Interest!
                <img className="d-inline-block" src={leaf} alt="..." />
              </h2>
              <p style={desc}>
                We appreciate your curiosity about Holografs, our innovative brand safety technology that delivers captivating and unforgettable brand experiences, leaving a lasting impression on your audience.
              </p>
              <p style={desc}>
                A member of our dedicated team will be in touch with you shortly to understand your specific needs and explore how our products can benefit your organization. We're committed to providing you with exceptional solutions that drive innovation and success.
              </p>
              <p style={desc}>
                Stay tuned for an exciting journey as we embark on transforming your digital landscape together.
              </p>
              <a href="/" className='my-3 btn bg-light text-black'>
                Go to Home
              </a>
              <img src={headerLogo} width="40%" className='mt-3' />
              {/* <form>
                <div className="subscribeSec__emailWrapper">
                  <input
                    type="email"
                    required
                    placeholder="Enter you email to subscribe"
                  // value={emailID}
                  // onChange={(e) => setEmailID(e.target.value)}
                  />
                  <button>
                    <img src={email} alt="..." />
                  </button>
                </div>
              </form> */}
            </div>
            <img className="subscribeSec__deco1" src={deco1} alt="" />
            {/* <div className="subscribeSec__deco2">
              <img className="subscribeSec__layer0" src={layer0} alt="" />
              <img className="subscribeSec__layer1" src={layer1} alt="" />
            </div> */}
          </div>
        </div>
      </section>
    </>
  )
}
