export const ADD_PACKAGE_SUCCESS = "packaging/ADD_PACKAGE_SUCCESS";
export const ADD_PACKAGE_FAILURE = "packaging/ADD_PACKAGE_FAILURE";
export const GET_ALL_PACKAGE_SUCCESS = "packaging/GET_ALL_PACKAGE_SUCCESS";
export const GET_ALL_PACKAGE_FAILURE = "packaging/GET_ALL_PACKAGE_FAILURE";
export const GET_SINGLE_PACKAGE_SUCCESS =
  "packaging/GET_SINGLE_PACKAGE_SUCCESS";
export const GET_SINGLE_PACKAGE_FAILURE =
  "packaging/GET_SINGLE_PACKAGE_FAILURE";
export const ADD_ITEM_TO_PACKAGE_SUCCESS =
  "packaging/ADD_ITEM_TO_PACKAGE_SUCCESS";
export const ADD_ITEM_TO_PACKAGE_FAILURE =
  "packaging/ADD_ITEM_TO_PACKAGE_FAILURE";
export const GET_ITEM_FROM_PACKAGE_SUCCESS =
  "packaging/GET_ITEM_FROM_PACKAGE_SUCCESS";
export const GET_ITEM_FROM_PACKAGE_FAILURE =
  "packaging/GET_ITEM_FROM_PACKAGE_FAILURE";
export const GET_PACKAGE_ITEM_DETAILS_SUCCESS =
  "packaging/GET_PACKAGE_ITEM_DETAILS_SUCCESS";
  export const GET_PACKAGE_ITEM_DETAILS_FAILURE =
    "packaging/GET_PACKAGE_ITEM_DETAILS_FAILURE";
    export const SEARCH_SINGLE_PACKAGE_SUCCESS =
      "packaging/SEARCH_SINGLE_PACKAGE_SUCCESS";
    export const SEARCH_SINGLE_PACKAGE_FAILURE =
      "packaging/SEARCH_SINGLE_PACKAGE_FAILURE";

    export const LOADER_TRUE = "packaging/LOADER_TRUE";
    export const LOADER_FALSE = "packaging/LOADER_FALSE";  
    export const UPDATE_PACKAGE_SUCCESS = "packaging/UPDATE_PACKAGE_SUCCESS";
    export const UPDATE_PACKAGE_FAILURE = "packaging/UPDATE_PACKAGE_FAILURE";
    export const ADD_OUTSOURCE_PACKAGING_SUCCESS = "packaging/ADD_OUTSOURCE_PACKAGING_SUCCESS";
    export const ADD_OUTSOURCE_PACKAGING_FAILURE = "packaging/ADD_OUTSOURCE_PACKAGING_FAILURE";  
