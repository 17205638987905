import {
  LoadScript,
  MarkerF,
  GoogleMap,
  DirectionsRenderer,
  Marker,
  InfoWindowF,
} from "@react-google-maps/api";
import { useEffect, useState } from "react";
import warehouse from "../../assets/images/Group 6.svg";
import originMarker from "../../assets/images/origin_new.svg";
import location from "../../assets/images/landing/info/location.svg";

const containerStyle = {
  width: "100%",
  height: "99%",
  borderRadius: "10px",
};

const customMapStyle = [
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#343434" }],
  },
  {
    featureType: "all",
    elementType: "geometry.fill",
    stylers: [{ color: "#000000" }],
  },
  {
    elementType: "labels.text",
    stylers: [{ color: "#606060" }, { weight: 1 }, { fontSize: "10px" }],
  },
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.business",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi.airport",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
];

export default function GoogleMapComponent(props) {
  const [directions, setDirections] = useState(null);
  const [center, setCenter] = useState({});
  const [latitude, setlatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [loading, setLoading] = useState(true);
  const [marker, setMarker] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [geocoder, setGeocoder] = useState(null);

  let digitArray;
  useEffect(() => {
    if (props?.location) {
      digitArray = props?.location?.split("&");
      let lat = digitArray[0];
      let long = digitArray[1];
      setlatitude(Number(lat));
      setLongitude(Number(long));
    }
  }, [digitArray, props.location]);

  const origin = { lat: latitude, lng: longitude };
  const destination = null;

  useEffect(() => {
    if (latitude) {
      setCenter({ lat: latitude, lng: longitude });
      setLoading(false);
    }
  }, [latitude, longitude]);

  const customMarkerIcon = {
    url: originMarker,
    scaledSize: { width: 25, height: 21 },
  };
  const icon = { url: location, scaledSize: { width: 25, height: 44 } };

  useEffect(() => {
    getLocationByInput();
  }, [latitude, longitude]);

  const getLocationByInput = async () => {
    if (window.google) {
      const geocoder = new window.google.maps.Geocoder();
      setGeocoder(geocoder);
      // Get the user's current location using the Geolocation API
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          //  const { latitude, longitude } = position.coords;
          const currentLocation = {
            lat: latitude,
            lng: longitude,
          };
          geocoder.geocode({ location: currentLocation }, (results, status) => {
            if (status === "OK" && results[0]) {
              const address = results[0].formatted_address;
              setMarker([
                {
                  position: currentLocation,
                  title: "Current Location",
                  address: address,
                },
              ]);
            }
          });
        });
      }
    } else {
      console.error("Google Maps API is not loaded.");
    }
  };

  const fetchDirections = async () => {
    if (window.google && destination) {
      const directionsService = new window.google.maps.DirectionsService();
      try {
        const response = await new Promise((resolve, reject) => {
          directionsService.route(
            {
              origin: origin,
              destination: destination,
              travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (response, status) => {
              if (status === "OK") {
                resolve(response);
              } else {
                reject(status);
              }
            }
          );
        });

        setDirections(response);
      } catch (error) {
        console.error("Error fetching directions:", error);
      }
    }
  };

  useEffect(() => {
    // Check if window.google is defined before running fetchDirections
    if (!window.google) {
      const checkGoogleInterval = setInterval(() => {
        if (window.google) {
          clearInterval(checkGoogleInterval);
          fetchDirections();
        }
      }, 100); // Check every 100ms
      return () => clearInterval(checkGoogleInterval);
    } else {
      fetchDirections();
    }
  }, []);

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        // <LoadScript googleMapsApiKey="AIzaSyBGMUJe2XUHhIZKXIrq4btVXK2s3zeqLLM">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={14}
          options={{
            styles: customMapStyle,
            disableDefaultUI: true,
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            // mapTypeId:"satellite" /
          }}
        >
          <MarkerF
            position={origin}
            icon={icon}
            onMouseOver={() => setSelectedMarker(marker)}
            onMouseOut={() => setSelectedMarker(null)}
          >
            {selectedMarker === marker && (
              <InfoWindowF onCloseClick={() => setSelectedMarker(null)}>
                <div className="infowindow-content">
                  <p>{marker[0]?.address}</p>
                  {/* <img src={wave}/> */}
                 
                </div>
              </InfoWindowF>
            )}
          </MarkerF>

          {directions && (
            <DirectionsRenderer
              directions={directions}
              options={{
                polylineOptions: {
                  strokeOpacity: 1,
                  strokeColor: "#FFFDD0",
                },
                suppressMarkers: true,
              }}
            ></DirectionsRenderer>
          )}
          <Marker position={origin} icon={customMarkerIcon} />
          {/* <Marker position={destination} icon={customMarkerIcon} /> */}
        </GoogleMap>
        // </LoadScript>
      )}
    </>
  );
}
