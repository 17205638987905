import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LatLngToAddress = ({ lat, long, setAddressFromLatLng}) => {
  useEffect(() => {
    const apiKey = 'AIzaSyBGMUJe2XUHhIZKXIrq4btVXK2s3zeqLLM';
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${apiKey}`;

    axios.get(apiUrl)
      .then(response => {
        if (response.data.results.length > 0) {
          const address = response.data.results[0].formatted_address;
          setAddressFromLatLng(address);
        }
      })
      .catch(error => {
        console.error('Error fetching address:', error);
      });
  }, [lat, long, setAddressFromLatLng]);

  return null ;
};

export default LatLngToAddress;
