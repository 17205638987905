import React, { useEffect, useState } from "react";
import PageList from "../../reusable_components/pageList";
import headerLogo from "../../assets/images/landing/header-logo.svg";
import menuArrowLeft from "../../assets/images/landing/menu-arrow-left.svg";
import menuArrowRight from "../../assets/images/landing/menu-arrow-right.svg";
import menuLogo from "../../assets/images/landing/menu-logo.svg";
import social1 from "../../assets/images/landing/social1.svg";
import social2 from "../../assets/images/landing/social2.svg";
import social3 from "../../assets/images/landing/social3.svg";
import social4 from "../../assets/images/landing/social4.svg";
import gradLeaf from "../../assets/images/icons/icon-grad-leaf.svg";
import veryLongArrow from "../../assets/images/icons/icon-very-long-arrow.svg";
import holograph from "../../assets/images/holographic-image.svg";
import lockedQR from "../../assets/images/locked-QR.png";
import userDp from "../../assets/images/user-dp.png";
import trustedShield from "../../assets/images/icons/icon-trusted-shield.svg";
import arrowGrad from "../../assets/images/product-modal-arrow-grad.svg";
import ddArrow from "../../assets/images/icons/icon-dropdown-arrow.svg";
import flag from "../../assets/images/icons/icon-flag-red.svg";
import goldenCrown from "../../assets/images/golden-crown.svg";
import msg from "../../assets/images/icons/icon-msg.svg";
import Level1_Image from "../../assets/images/packageImages/pack_level1.svg";
import Level2_Image from "../../assets/images/packageImages/pack_level2.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../reusable_components/loader";
import GoogleMapComponent from "./googleMapComponent";
import SuccessToast from "../../reusable_components/SuccessToast"
import ErrorToast from "../../reusable_components/ErrorToast"
import {
  BASE_PROXY_URL,
  GET_PRODUCT_DETAILS_ID,
  GET_USER_DETAILS,
  IMAGE_PROXY_URL,
} from "../../services/Api";
import axios from "axios";
import {
  formatDate,
  formatHour,
  formatMinute,
  formatSecond,
  formatTime,
} from "../../services/utils";
import {
  GetItemFromPackage,
  SearchSinglePackage,
} from "../../Redux/Packaging/packaging.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  counterfeitComplain,
  getAllProductsById,
} from "../../Redux/Tracking/tracking.actionTypes";
import { toast, ToastContainer } from "react-toastify";
import LatLngToAddress from "../../reusable_components/latLngToAddress";

export default function LandingSearch() {
  const [productDetails, setProductDetails] = useState({});
  const [producImg, setProductImg] = useState(null);
  const [hour, setHour] = useState(null);
  const [minute, setMinute] = useState(null);
  const [date, setDate] = useState(null);
  const [itemType, setItemType] = useState(null);
  const [ownerDetails, setOwnerDetails] = useState({});
  const [packageCount, setPackageCount] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [ContentData, setContentData] = useState([]);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const items = [
    "Location-based issue",
    "Different owner detected",
    "Batch ID related issue",
    "Others",
  ];
  const [selectedItem, setSelectedItem] = useState("");
  const [currentLocation, setCurrentLocation] = useState(null);
  const [addressFromLatLng, setAddressFromLatLng] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  const getDetails = async (id) => {
    try {
      let res = await axios.get(`${GET_PRODUCT_DETAILS_ID}/${id}`);
      let data = res?.data;
      if (data?.msg?.includes("Invalid")) {
        toast(<ErrorToast content={data?.msg} />, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        
        setTimeout(() => {
          navigate("/");
        }, [1500]);
      }
      if (data) {
        setData(data);
      }
    } catch (error) {
      console.log({ error });
      toast(<ErrorToast content={"Oops, Error Occurred"} />, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      
    }
  };
  useEffect(() => {
    getDetails(id);
  }, [id]);

  useEffect(() => {
    if (data) {
      setProductDetails(data);
      setContentData(data?.packegecontent?.content_data);

      const isPackId = data?.display_id?.startsWith("PACK");
      const isInveId = data?.display_id?.startsWith("INVE");
      if (isInveId) {
        setItemType("INVENTORY");
        if (data?.product?.images && data?.product?.images?.length > 0) {
          let img = `${IMAGE_PROXY_URL}${data?.product?.images[0]?.image}`;
          setProductImg(img);
        }
      } else if (isPackId) {
        setItemType("PACKAGE");
      }

      const date1 = formatDate(data?.created_at);
      const hour1 = formatHour(data?.created_at);
      const min1 = formatMinute(data?.created_at);

      if (date1 && hour1 && min1) {
        setDate(date1);
        setHour(hour1);
        setMinute(min1);
      }
    }
  }, [data]);

  useEffect(() => {
    setItemCount(0);
    setPackageCount(0);
    if (itemType == "PACKAGE") {
      const fetchPackageDetails = async (id) => {
        try {
          // Replace this with your actual API endpoint for package details
          const res = await axios.get(`${GET_PRODUCT_DETAILS_ID}/PACK${id}`);
          let packageData = res?.data;

          // Increment the package count
          setPackageCount((prevCount) => prevCount + 1);

          // Count items within the package details
          let isArr = Array.isArray(packageData?.packegecontent?.content_data);
          if (!isArr) {
            return;
          }
          const itemDetails = packageData?.packegecontent?.content_data?.filter(
            (item) => item?.type === "item"
          );
          setItemCount((prevCount) => prevCount + itemDetails?.length);
        } catch (error) {
          console.error(error);
        }
      };

      const processPackageData = async () => {
        for (const item of ContentData) {
          if (item.type === "package") {
            // For packages, fetch package details
            await fetchPackageDetails(item.id);
          } else if (item?.type === "item") {
            // For standalone items, increment the item count
            setItemCount((prevCount) => prevCount + 1);
          }
        }
      };

      processPackageData();
    }
  }, [ContentData]);

  const handleToast = () => {
    toast(<ErrorToast content={"Please login for more Info..!"} />, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
   
  };

  const handleFetchLocation = () => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error("Error getting location:", error);
      }
    );
  };

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    handleFetchLocation();
  };
  const handleSubmitQuery = () => {
    const payLoad = {
      // location: addressFromLatLng,
      address: addressFromLatLng,
      item: id,
      query: selectedItem,
    };
    if (payLoad) {
      dispatch(counterfeitComplain(payLoad));
      toast(<SuccessToast content={"complaint successfully registered"} />, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      
    }
  };

  return (
    <div className="landing paye-layout">
      <PageList />
      <div className="pb-0 content-layout">
        <header className="position-static headerSec">
          <nav className="headerSec__inner">
            <Link className="headerSec__logo" to="">
              <img src={headerLogo} alt="logo" />
            </Link>

            <ul className="headerSec__links">
              <li className="active">
                <Link to={`/?state=${"home"}`}>Home</Link>
              </li>
              <li>
                <Link to={`/?state=${"why"}`}>About Holografs</Link>
              </li>
              <li>
                <Link to={`/?state=${"values"}`}>Unique Values</Link>
              </li>
              <li>
                <Link to={`/?state=${"how"}`}>How it works?</Link>
              </li>
              <li>
                <Link to={`/?state=${"faq"}`}>FAQ</Link>
              </li>
              <li>
                <Link to={`/?state=${"chat"}`}>Let's have a chat!</Link>
              </li>
            </ul>

            <Link className="headerSec__btn" to={"/login"}>
              <span>SIGN IN</span>
            </Link>

            <Link className="d-lg-none menu-btn" to="#">
              <span>MENU</span>
              <img src={menuArrowLeft} alt="menu button" />
            </Link>
          </nav>
        </header>

        <aside className="menuSec">
          <div className="menuSec__inner">
            <div className="menuSec__head">
              <div className="text-start">
                <Link className="d-lg-none menu-btn" to="#">
                  <span>MENU</span>
                  <img src={menuArrowRight} alt="menu button" />
                </Link>
              </div>
              <div className="menuSec__head-logo">
                <img src={menuLogo} alt="logo" />
              </div>
              <div className="menuSec__head-desc">
                a cutting-edge Blockchain as a Service
              </div>
              <div>
                <Link className="button-light" to="#">
                  <span>
                    <span className="hgt">Login</span>
                  </span>
                </Link>
              </div>
            </div>

            <ul className="menuSec__links">
              <li className="active">
                <Link to="home">
                  <span className="hgt">Home</span>
                </Link>
              </li>
              <li>
                <Link to="about">
                  <span className="hgt">About </span>
                </Link>
              </li>
              <li>
                <Link to="values">
                  <span className="hgt">Unique Values</span>
                </Link>
              </li>
              <li>
                <Link to="how">
                  <span className="hgt">How it works?</span>
                </Link>
              </li>
              <li>
                <Link to="faq">
                  <span className="hgt">FAQ</span>
                </Link>
              </li>
              <li>
                <Link to="chat">
                  <span className="hgt">Let's have a chat!</span>
                </Link>
              </li>
            </ul>

            <div className="menuSec__foot">
              <div>
                <span className="hgt">2023 copyright.</span>
              </div>

              <div className="menuSec__social">
                <Link to="">
                  <img src={social1} />
                </Link>
                <Link to="">
                  <img src={social2} />
                </Link>
                <Link to="">
                  <img src={social3} />
                </Link>
                <Link to="">
                  <img src={social4} />
                </Link>
              </div>
            </div>
          </div>
        </aside>

        <section className="tracker-screen-s2 cs4 mb-4 mb-lg-0">
          <div className="row">
            <div className="col-12 col-lg-5">
              <div className="border-white-2 tracker-wrapper tracker-wrapper--search">
                <section className="tracker-bg">
                  <div className="dark-block-content page-desc-content">
                    <h3 className="fz24 mb-0 fw600 gradientText--radial">
                      <img src={gradLeaf} alt="" />
                      <span>
                        {itemType == "PACKAGE"
                          ? "Package ID: "
                          : "Product ID: "}
                        <span>{productDetails?.display_id}</span>
                      </span>
                    </h3>
                    <p className="mb-0 mt-1 fz12 lh1-65 text-white d-inline-block">
                      <span className="fz14 mb-0 fw600 gradientText--radial">
                        {itemType == "PACKAGE"
                          ? "Package Address: "
                          : "Product Address: "}
                      </span>
                      <span>
                        {productDetails?.address_location
                          ? productDetails?.address_location
                          : "NA"}
                      </span>
                      <img
                        className="ms-1 d-inline-block"
                        src={veryLongArrow}
                        alt=""
                      />
                    </p>
                  </div>
                  <div className="map">
                    {productDetails?.location && (
                      <GoogleMapComponent location={productDetails?.location} />
                    )}
                  </div>
                </section>
              </div>
            </div>
            <div className="col-12 col-lg-7">
              <div className="border-white-2 tracking-status">
                <div className="tracking-status__search">
                  <div
                    onClick={handleToast}
                    className="main-shadow block-wavy-dark"
                  >
                    <span className="gradientText fw600 fz16">
                      Product Detail
                    </span>
                  </div>

                  <div className="search-images">
                    <div className="search-images__holographic">
                      {itemType == "PACKAGE" ? (
                        // <img
                        //   className="bdrs10 main-shadow"
                        //   src={packageCount == 0 ? Level1_Image : Level2_Image}
                        //   alt=""
                        // />
                        <div className="d-flex bdrs10 border-white-2 main-shadow level-image-block">
                          <div className="d-inline-flex level">
                            <img src={gradLeaf} />
                            <span className="fw600 fz30 font-primary gradientText--radial">
                              {packageCount == 0 ? "Level 1" : "Level 2"}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <img
                          className="bdrs10 main-shadow"
                          src={producImg ? producImg : holograph}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="search-images__locked">
                      <img className="main-shadow" src={lockedQR} alt="" />
                    </div>
                  </div>

                  <ul className="list-inline m-0 pairs">
                    <li>
                      <div className="fz20 pairs__text">
                        <div className="label">
                          {itemType == "PACKAGE"
                            ? "Package ID:"
                            : "Product ID:"}
                        </div>
                        <div className="fw600 value">
                          {productDetails?.display_id}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="fz20 pairs__text">
                        <div className="label">
                          {itemType == "PACKAGE"
                            ? "Package Brand:"
                            : "Product Name:"}
                        </div>
                        {itemType == "PACKAGE" ? (
                          <div className="fw600 value">
                            {productDetails?.brand_name}
                          </div>
                        ) : (
                          <div className="fw600 value">
                            {productDetails?.product?.name}
                          </div>
                        )}
                      </div>
                    </li>
                    {itemType == "PACKAGE" && itemCount > 0 ? (
                      <li>
                        <div className="fz20 pairs__text">
                          <div className="label">Items Added:</div>
                          <div className="fw600 value">{itemCount}</div>
                        </div>
                      </li>
                    ) : (
                      <></>
                    )}

                    {itemType == "PACKAGE" && packageCount != 0 ? (
                      <li>
                        <div className="fz20 pairs__text">
                          <div className="label">
                            Total Level 1 Packages Added:
                          </div>
                          <div className="fw600 value">{packageCount}</div>
                        </div>
                      </li>
                    ) : (
                      <></>
                    )}

                    <li>
                      <div className="fz20 pairs__text">
                        <div className="label">Added:</div>
                        <div className="fw600 value">
                          {formatDate(productDetails?.created_at)} |
                          {formatHour(productDetails?.created_at)} hrs :{" "}
                          {formatMinute(productDetails?.created_at)}mins :{" "}
                          {formatSecond(productDetails?.created_at)}secs
                        </div>
                      </div>
                    </li>
                  </ul>

                  <div
                    onClick={handleToast}
                    className="main-shadow block-wavy-dark"
                  >
                    <span className="gradientText fw600 fz16">
                      Current Owner
                    </span>
                  </div>

                  <div className="spacer"></div>

                  <div className="tracker-info-wrapper">
                    <div className="tracker-info">
                      <div className="tracker-user-card-wrapper">
                        <div className="user-card-dot"></div>
                        <div className="tracker-user-card position-relative">
                          <span className="tagBefore">
                            <span className="fw600 fz14 gradientText--radial">
                              P1
                            </span>
                          </span>
                          <div className="user-tracking-pic d-flex">
                            <div className="page-header__user-info round_pf_pic">
                              <div className="rounded-circle user-img">
                                <span className="rounded-circle">
                                  <img
                                    src={userDp}
                                    alt="User Image - Holograf"
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="user-tracking-info">
                              <h3 className="m-0 fw600 fz16">
                                {itemType == "PACKAGE"
                                  ? productDetails?.package_owner?.fullname
                                  : productDetails?.owner?.fullname}
                              </h3>
                              <p className="d-flex align-items-center trusted-user">
                                <img src={trustedShield} alt="" />
                                <span className="fw500 fz12">
                                  Trusted Owner
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end tracking-date-tag">
                            <span className="date_btn">
                              {" "}
                              {formatDate(productDetails?.created_at)}
                            </span>
                          </div>
                        </div>
                      </div>
                      <button
                        className="w-100 btn fw500 fz18 counterfeitBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#counterfeitModal"
                        onClick={() => setSelectedItem("")}
                      >
                        <div className="icon">
                          <img src={flag} alt="" />
                        </div>
                        <span className="fz20 fw400">
                          Not sure about this product?
                          <strong className="fz20 fw600">
                            Mark counterfeit this product!
                          </strong>
                        </span>
                      </button>
                    </div>

                    {/* <div className="tracker-info">
                      <div className="tracker-user-card-wrapper">
                        <div className="user-card-dot"></div>
                        <div className="tracker-user-card position-relative">
                          <span className="tagBefore">
                            <span className="fw600 fz14 gradientText--radial">
                              P2
                            </span>
                          </span>
                          <div className="d-flex justify-content-between cs3-border-bottom">
                            <div className="user-tracking-pic d-flex">
                              <div className="page-header__user-info round_pf_pic">
                                <div className="rounded-circle user-img">
                                  <span className="rounded-circle">
                                    <img
                                      src={userDp}
                                      alt="User Image - Holograf"
                                    />
                                  </span>
                                </div>
                                <div className="position-absolute crown-wrapper">
                                  <img src={goldenCrown} alt="" />
                                </div>
                              </div>
                              <div className="user-tracking-info">
                                <h3 className="m-0 fw600 fz16">
                                  Shah Ray (You)
                                </h3>
                                <p className="mb-1 fz12 contact-para">
                                  <span className="fw500">Contact Info:</span>
                                  <span className="fw400">
                                    shahray@example.com
                                  </span>
                                </p>
                                <p className="d-flex align-items-center trusted-user">
                                  <img src={trustedShield} alt="" />
                                  <span className="fw500 fz12">
                                    Trusted Owner
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="message">
                              <button className="btn btn-fab btn-fab--green">
                                <img src={msg} alt="" />
                              </button>
                            </div>
                          </div>

                          <div className="d-flex justify-content-end tracking-date-tag">
                            <span className="date_btn">01 March 2023</span>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {/* <div className="tracker-info">
                      <div className="tracker-user-card-wrapper">
                        <div className="user-card-dot"></div>
                        <div className="tracker-user-card position-relative">
                          <span className="tagBefore">
                            <span className="fw600 fz14 gradientText--radial">
                              P3
                            </span>
                          </span>
                          <div className="d-flex justify-content-between cs3-border-bottom">
                            <div className="user-tracking-pic d-flex">
                              <div className="page-header__user-info round_pf_pic">
                                <div className="rounded-circle user-img">
                                  <span className="rounded-circle">
                                    <img
                                      src={userDp}
                                      alt="User Image - Holograf"
                                    />
                                  </span>
                                </div>
                                <div className="position-absolute crown-wrapper">
                                  <img src={goldenCrown} alt="" />
                                </div>
                              </div>
                              <div className="user-tracking-info">
                                <h3 className="m-0 fw600 fz16">
                                  Jeromin Willson (You)
                                </h3>
                                <p className="mb-1 fz12 contact-para">
                                  <span className="fw500">Contact Info:</span>
                                  <span className="fw400">
                                    jermonwillson@example.com
                                  </span>
                                </p>
                                <p className="d-flex align-items-center trusted-user">
                                  <img src={trustedShield} alt="" />
                                  <span className="fw500 fz12">
                                    Trusted Owner
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="d-flex justify-content-end tracking-date-tag">
                            <span className="date_btn">01 March 2023</span>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <span className="tracker-info-wrapper-dot"></span>
                  </div>
                </div>

                <div className="text-start border-0 login-search-block">
                  {/* <button
                    className="btn fw500 fz18 btn-fab btn-fab--pink"
                    data-bs-toggle="modal"
                    data-bs-target="#counterfeitModal"
                    onClick={() => setSelectedItem("")}
                  >
                    Counterfeit Report
                  </button> */}
                  <button
                    onClick={() => navigate("/login")}
                    role="button"
                    className="main-shadow block-wavy-dark"
                  >
                    <div className="title">
                      <img src={gradLeaf} alt="" />
                      <h2 className="fz24 mb-0 fw600 gradientText--radial">
                        <span>
                          Login for detailed view or track your product
                        </span>
                      </h2>
                    </div>

                    <p className="fz12 mb-0 text-start text-white">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy
                      <img
                        className="d-inline-block"
                        src={veryLongArrow}
                        alt=""
                      />
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </div>

      {/* Modal  */}
      <div
        className="modal fade add_product_modal"
        id="counterfeitModal"
        tabindex="-1"
        aria-labelledby="counterfeitModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content add-product-modal-content cf-modal-content">
            <div className="p-0 modal-header">
              <h3 className="mb-0">
                <img src={arrowGrad} alt="" />
                <span className="m-0 fw600 gradientText--radial fz24">
                  Counterfeit Report
                </span>
              </h3>
            </div>

            <div className="m-0 modal-body">
              {/* <hr className="modal-divider m-0" /> */}

              <div className="product-counter">
                <h4 className="fz24 font-primary cf-title">
                  <strong className="fw600">
                    Confirm your choice to report this product
                  </strong>
                  ?
                </h4>
                <div className="pt-3 counter-number">
                  <div className="w-100 form-group">
                    <label className="fw500 mb-1 fz14 label">
                      choose reasons
                    </label>
                    <div className="dropdown">
                      <a
                        className="position-relative form-control select-div"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="fw400 fz12 grayText fst-italic">
                          {selectedItem ? selectedItem : "Choose the reason"}
                        </span>
                        <div className="position-absolute btn btn-fab btn-fab--green">
                          <img src={ddArrow} alt="" />
                        </div>
                      </a>

                      <div className="w-100 dropdown-menu border-white-2 dropdown-menu-holograf">
                        {/* <ul className="list-inline mb-0 product-type-list">
                          <li value={"reason1"}>
                            <Link to="#" className="type-pair">
                              <span className="fz14 type-pair">Reason 01</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="#" className="type-pair active">
                              <span className="fz14 type-pair">Reason 02</span>
                            </Link>
                          </li>
                        </ul> */}
                        <ul className="list-inline mb-0 product-type-list">
                          {items &&
                            items?.map((item, index) => (
                              <li
                                key={index}
                                onClick={() => handleSelectItem(item)}
                              >
                                <span className="fz14 type-pair">{item}</span>
                              </li>
                            ))}
                        </ul>
                        <LatLngToAddress
                          lat={currentLocation?.lat}
                          long={currentLocation?.lng}
                          setAddressFromLatLng={setAddressFromLatLng}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mx-0 pt-2 mb-0 cta-btn-row">
                  <div className="w-100 cta-row">
                    {/* <a
                      role="button"
                      data-bs-dismiss="modal"
                      className="btn btn-dark-outlined cancel-modal-btn"
                    >
                      <span className="fz14">Cancel</span>
                    </a> */}
                    {/* <a
                      role="button" 
                      data-bs-dismiss="modal"
                      onClick={() => navigate("/dashboard")}
                      className="btn btn-solid--dark next-modal-btn"
                    >
                      <span className="fz14 lh-1 gradientText--radial">
                        Submit
                      </span>
                    </a> */}
                    <a
                      role="button"
                      href="javascript:;"
                      data-bs-dismiss="modal"
                      onClick={handleSubmitQuery}
                      className="btn btn-solid--dark next-modal-btn"
                    >
                      <span className="fz14 lh-1 gradientText--radial">
                        Submit
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
