import {
  GET_ALL_PACKAGES_FAILURE,
  GET_ALL_PACKAGES_SUCCESS,
  GET_ALL_TRANSPORT_FAILURE,
  GET_ALL_TRANSPORT_SUCCESS
} from "./logisticreport.actionTypes";

const initialState = {
  PackedItemCount: null,
  WareHouseItemCount: null,
  TransportedItemCount:0,
  TransportDetailsArray:[]
};

export default function LogisticReportReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case GET_ALL_PACKAGES_SUCCESS:
      return {
        ...state,
        PackedItemCount: payload?.packedItems,
        WareHouseItemCount: payload?.warehouseItems,
        TransportedItemCount:payload?.transportedItems
      };

    case GET_ALL_PACKAGES_FAILURE:
      return {
        ...state,
        PackedItemCount: 0,
      };

      case GET_ALL_TRANSPORT_SUCCESS:
        return {
          ...state,
          TransportDetailsArray: payload,
        };

      case GET_ALL_TRANSPORT_FAILURE:
          return {
            ...state,
            TransportDetailsArray: [],
          };
    default:
      return state;
  }
}
