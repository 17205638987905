import {
  SEND_MSG_TO_ADMIN_SUCCESS,
  SEND_MSG_TO_ADMIN_FAILURE,
  ALL_MSG_FROM_CUSTOMER_SUCCESS,
  ALL_MSG_FROM_CUSTOMER_FAILURE,
  SEND_MSG_TO_CUSTOMER_SUCCESS,
  SEND_MSG_TO_CUSTOMER_FAILURE,
  ALL_MSG_FROM_ADMIN_SUCCESS,
  ALL_MSG_FROM_ADMIN_FAILURE,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_FAILURE,
  GET_ALL_TICKET_SUCCESS,
  GET_ALL_TICKET_FAILURE,
} from "./support.actionTypes";

const initialState = {
  AllChats: [],
  AllTickets: [],
};

export default function SupportReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ALL_MSG_FROM_CUSTOMER_SUCCESS:
      return {
        ...state,
        AllChats: payload,
      };

    case ALL_MSG_FROM_CUSTOMER_FAILURE:
      return {
        ...state,
        AllChats: [],
      };

    case GET_ALL_TICKET_SUCCESS:
      return {
        ...state,
        AllTickets: payload,
      };

    case GET_ALL_TICKET_FAILURE:
      return {
        ...state,
        AllTickets: [],
      };

    default:
      return state;
  }
}
