import {
  PROFILE_UPDATE_USER_SUCCESS,
  PROFILE_UPDATE_USER_FAILURE,
  PROFILE_GET_USER_SUCCESS,
  PROFILE_GET_USER_FAILURE,
  COMPANY_INFO_SUCCESS,
  COMPANY_INFO_FAILURE,
} from "./profile.actionTypes";
import axios from "axios";
import {
  GET_USER_DETAILS,
  PATCH_UPDATE_USER,
  POST_COMPANY_INFO,
} from "../../services/Api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SuccessToast from "../../reusable_components/SuccessToast";

export const updateBasicInformation = (data) => async (dispatch) => {

  // if (data?.fullname || data?.mobile || typeof data?.mobile !== "number") {
  //   toast.error("Kindly complete the necessary fields", {
  //     position: toast.POSITION.TOP_CENTER,
  //   });
  // }
  const savedImage = localStorage.getItem("ProfileImage") || null;
  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const formData = new FormData();
  formData.append("fullname", data?.fullname);
  formData.append("address", data?.address);
  formData.append("mobile", data?.mobile);
  formData.append("social_link", data?.social_link);
  formData.append("role", data?.role);
  if (savedImage) {
    const blob = dataURItoBlob(savedImage);
    formData.append("profile_image", blob, "image.jpg");
  }

  let token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const res = await axios.patch(PATCH_UPDATE_USER, formData, { headers });
    if (res.data) {
      dispatch({
        type: PROFILE_UPDATE_USER_SUCCESS,
      });
      toast(<SuccessToast content={'Profile Successfully Updated'} />, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      
    } else {
      dispatch({
        type: PROFILE_UPDATE_USER_FAILURE,
        payload: {
          message: res?.data?.msg,
        },
      });
    }
  } catch (error) {
    console.log("Error Update", error);

    dispatch({
      type: PROFILE_UPDATE_USER_FAILURE,
      payload: {
        message: error,
      },
    });
  }

  dispatch(getUserBasicInformation());
};

export const getUserBasicInformation = () => async (dispatch) => {
  let token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.get(GET_USER_DETAILS, { headers });
    if (res.data) {
      dispatch({
        type: PROFILE_GET_USER_SUCCESS,
        responseData: {
          fullname: res.data.fullname,
          Linkedin: res.data.Linkedin,
          address: res.data.address,
          bio: res.data.bio,
          email: res.data.email,
          facebook: res.data.facebook,
          id: res.data.id,
          is_active: res.data.is_active,
          mc_hash: res.data.mc_hash,
          mobile: res.data.mobile,
          profile_image: res.data.profile_image,
          social_link: res.data.social_link,
          role: res.data.role,
        },
      });
    } else {
      dispatch({
        type: PROFILE_GET_USER_FAILURE,
        message: "",
      });
    }
  } catch (error) {
    dispatch({
      type: PROFILE_GET_USER_FAILURE,
      message: "",
    });
  }
};

export const postCompanyInfomation = (payLoad) => async (dispatch) => {
  let token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const resp = await axios.post(POST_COMPANY_INFO, payLoad, { headers });
    if (resp.data) {
      dispatch({
        type: COMPANY_INFO_SUCCESS,
        payload: {
          data: resp?.data,
        },
      });
    } else {
      dispatch({
        type: COMPANY_INFO_FAILURE,
        message: "",
      });
    }
  } catch (error) {
    dispatch({
      type: COMPANY_INFO_FAILURE,
      message: "",
    });
  }
};
