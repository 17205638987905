import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

const PrivateRoutes = ({ children }) => {
  // const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token")) || null;

  if (!token || token == "") {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" />;
  }
  // authorized so return child components
  return children;
};

export default PrivateRoutes;
