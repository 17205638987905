import { GET_ALL_PRODUCTS_FAILURE } from "../ProductInventory/productInventory.actionTypes";
import {
  GET_ALL_INVENTORY_FAILURE,
  GET_ALL_INVENTORY_SUCCESS,
  GET_ALL_PACKAGE_FAILURE,
  GET_ALL_PACKAGE_SUCCESS,
  GET_ALL_PRODUCT_SUCCESS,
  GET_ALL_PRODUCTS_COUNT_SUCCESS,
  GET_ALL_PRODUCT_FAILURE,
  FILTERED_INVENTORY_COUNT_SUCCESS,
  FILTERED_PRODUCT_INVENTORY_COUNT_SUCCESS,
  LOADER_TRUE,
  LOADER_FALSE,
  INVENTORY_COUNT_PERMONTH_SUCCESS,
  INVENTORY_COUNT_PERMONTH_FAILURE,
} from "./dashboard.actionTypes";

const initialState = {
  AllPackages: [],
  ALLInventories: [],
  ProductMonth: [],
  InventoryItems: [],
  InventoryCountObj: null,
  InventoryInStock: null,
  TotalProductCount: 0,
  TotalInventoryObject: null,
  TotalInventoryInStockObj: null,
};

export default function dashboardReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LOADER_TRUE:
      return {
        ...state,
        loader: true,
      };

    case LOADER_FALSE:
      return {
        ...state,
        loader: false,
      };

    case GET_ALL_PACKAGE_SUCCESS:
      return {
        ...state,
        AllPackages: payload,
      };

    case GET_ALL_PACKAGE_FAILURE:
      return {
        ...state,
        AllPackages: [],
      };

    case GET_ALL_INVENTORY_SUCCESS:
      return {
        ...state,
        ALLInventories: payload,
      };

    case GET_ALL_INVENTORY_FAILURE:
      return {
        ...state,
        ALLInventories: [],
      };
    case GET_ALL_PRODUCT_SUCCESS:
      return {
        ...state,
        ProductMonth: payload.arrayList,
        InventoryItems: payload.inventoryData,
      };

    case GET_ALL_PRODUCT_FAILURE:
      return {
        ...state,
        ProductMonth: [],
        InventoryItems: [],
      };

    case INVENTORY_COUNT_PERMONTH_SUCCESS:
      return {
        ...state,

        InventoryCountObj: payload?.InventoryCountObj,
        InventoryInStock: payload?.InventoryInStock,
        TotalInventoryObject: payload?.InventoryCountObj,
        TotalInventoryInStockObj: payload?.InventoryInStock,
      };

    case FILTERED_INVENTORY_COUNT_SUCCESS:
      return {
        ...state,
        InventoryCountObj: payload?.InventoryCountObj,
        InventoryInStock: payload?.InventoryInStock,
      };

    case FILTERED_PRODUCT_INVENTORY_COUNT_SUCCESS:
      return {
        ...state,
        InventoryCountObj: payload?.InventoryCountObj,
        InventoryInStock: payload?.InventoryInStock,
      };

    case INVENTORY_COUNT_PERMONTH_FAILURE:
      return {
        ...state,
      };

    case GET_ALL_PRODUCTS_COUNT_SUCCESS:
      return {
        ...state,
        TotalProductCount: payload,
      };

    default:
      return state;
  }
}
