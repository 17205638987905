import React, { useRef } from "react";
import apiDocHead from "../../../assets/images/icons/api-doc-head.svg";
import urlPaste from "../../../assets/images/icons/url-paste.svg";
import urlPasteBlack from "../../../assets/images/icons/url-paste-black.svg";
import { Link } from "react-router-dom";
import PageList from "../../../reusable_components/pageList";
import Navbar from "../../../layouts/navbar";
import BottomNavbar from "../../../layouts/bottomNavbar";
import DocSidebar from "../docSidebar";

export default function Authentication() {
  const textAreaRef = useRef(null);

  const handleCopyClick = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
  };
  return (
    <div className="paye-layout">
      <PageList />
      <div className="content-layout">
        <Navbar />
        <section className="product-screen api-doc-screen">
          <DocSidebar />

          <div className="w-100 api-document bdrs10 border-white-2 m-0">
            <div className="api-doc-head d-flex align-items-center gap-2 gx-0">
              <img src={apiDocHead} alt="" />
              <span className="fw600 fz28 text-dark">Authentication</span>
            </div>

            <div className="endpoints-wrapper">
              {/* <!-- Accordion in Documentation --> */}
              <div className="accordion accordion--api" id="apiDocumentation">
                <div className="bdrs10 main-shadow accordion-item accordian-widget">
                  <button
                    className="w-100 p-0 btn border-0 accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseZero"
                    aria-expanded="true"
                    aria-controls="collapseZero"
                  >
                    <div className="w-100 ts03 api-cards-header">
                      <div className="linebefore">
                        <span></span>
                        <span></span>
                      </div>
                      <span className=" fz16 fw500 gradientText--radial">
                        Send OTP
                      </span>
                    </div>
                  </button>

                  <div
                    id="collapseZero"
                    className="accordion-collapse collapse"
                    data-bs-parent="#apiDocumentation"
                  >
                    <div className="api-cards-body bdrs10">
                      <div className="endpoint-card-wrapper">
                        <div className="left">
                          <h3 className="fw600 fz16 m-0">URL</h3>
                        </div>
                        <ul className="list-inline linksURL">
                          <li>
                            <div className="endpoints-Insidewrapper">
                              <div className="d-flex align-items-center gap-2 right">
                                <button className="box">
                                  <img
                                    src={urlPaste}
                                    onClick={() =>
                                      handleCopyClick(
                                        "https://api.holografs.com/account/sendotp"
                                      )
                                    }
                                    alt=""
                                  />
                                </button>
                                <textarea
                                  ref={textAreaRef}
                                  value="https://api.holografs.com/product/addproduct"
                                  readOnly
                                  style={{
                                    position: "absolute",
                                    left: "-9999px",
                                  }}
                                />
                                <p className="grayText m-0">
                                  https://api.holografs.com/account/sendotp
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <hr className="endpoint-card-divider" />

                      <div className="endpoint-card-wrapper">
                        <div className="left">
                          <h3 className="fw600 fz16 m-0">Method</h3>
                        </div>
                        <div className="endpoints-Insidewrapper">
                          <div className="d-flex align-items-center gap-2 right">
                            <span className="brnd_btn_black content-box m-0 text-uppercase">
                              <span className="m-0">Post</span>
                            </span>
                          </div>
                        </div>
                      </div>

                      <hr className="endpoint-card-divider" />

                      <div className="black-box-inside mb-2">
                        <div className="d-flex align-items-center gap-2">
                          <button className="white-box">
                            <img src={urlPasteBlack} alt="" />
                          </button>
                          <a
                            href="#"
                            className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                          >
                            <div className="fw400 fz14">
                              <div className="px-3 gradientText--radial">
                                <span className="fz16 fw600">Fields</span>
                              </div>
                            </div>
                          </a>
                        </div>

                        <div className="endpoints-inside-text">
                          <p className="text-green fw400 fz12 mb-0">
                            fields ={" "}
                          </p>
                          <div className="fz12 array-values">
                            <div className="orange-text">
                              <span className="pink-text">[</span> <br />
                              'email'
                              <br />
                              <span className="pink-text">]</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="black-box-inside">
                        <div className="d-flex align-items-center gap-2">
                          <button className="white-box">
                            <img src={urlPasteBlack} alt="" />
                          </button>
                          <a
                            href="#"
                            className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                          >
                            <div className="fw400 fz14">
                              <div className="px-3 gradientText--radial">
                                <span className="fz16 fw600">Example</span>
                              </div>
                            </div>
                          </a>
                        </div>

                        <div className="endpoints-inside-text">
                          <p className="text-green fw400 fz12 mb-0">
                            example ={" "}
                          </p>

                          <div className="fz12 array-values">
                            <div className="orange-text">
                              <span className="pink-text">{"{"}</span>
                              <br />
                              "email":"abc@abc.com"
                              <br />
                              <span className="pink-text">{"}"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bdrs10 main-shadow accordion-item accordian-widget">
                  <button
                    className="w-100 p-0 btn border-0 accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <div className="w-100 ts03 api-cards-header">
                      <div className="linebefore">
                        <span></span>
                        <span></span>
                      </div>
                      <span className=" fz16 fw500 gradientText--radial">
                        Login
                      </span>
                    </div>
                  </button>
                </div>

                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#apiDocumentation"
                >
                  <div className="api-cards-body bdrs10">
                    <div className="endpoint-card-wrapper">
                      <div className="left">
                        <h3 className="fw600 fz16 m-0">URL</h3>
                      </div>
                      <ul className="list-inline linksURL">
                        <li>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <button className="box">
                                <img
                                  src={urlPaste}
                                  onClick={() =>
                                    handleCopyClick(
                                      "https://api.holografs.com/account/login"
                                    )
                                  }
                                  alt=""
                                />
                              </button>
                              <textarea
                                ref={textAreaRef}
                                value="https://api.holografs.com/product/addproduct"
                                readOnly
                                style={{
                                  position: "absolute",
                                  left: "-9999px",
                                }}
                              />
                              <p className="grayText m-0">
                                https://api.holografs.com/account/login
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <hr className="endpoint-card-divider" />

                    <div className="endpoint-card-wrapper">
                      <div className="left">
                        <h3 className="fw600 fz16 m-0">Method</h3>
                      </div>
                      <div className="endpoints-Insidewrapper">
                        <div className="d-flex align-items-center gap-2 right">
                          <span className="brnd_btn_black content-box m-0 text-uppercase">
                            <span className="m-0">Post</span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <hr className="endpoint-card-divider" />

                    <div className="black-box-inside mb-2">
                      <div className="d-flex align-items-center gap-2">
                        <button className="white-box">
                          <img src={urlPasteBlack} alt="" />
                        </button>
                        <a
                          href="#"
                          className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                        >
                          <div className="fw400 fz14">
                            <div className="px-3 gradientText--radial">
                              <span className="fz16 fw600">Fields</span>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="endpoints-inside-text">
                        <p className="text-green fw400 fz12 mb-0">fields = </p>

                        <div className="fz12 array-values">
                          <div className="orange-text">
                            <span className="pink-text">[</span> <br />
                            'email', 'otp'
                            <br />
                            <span className="pink-text">]</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="black-box-inside mb-2">
                      <div className="d-flex align-items-center gap-2">
                        <button className="white-box">
                          <img src={urlPasteBlack} alt="" />
                        </button>
                        <a
                          href="#"
                          className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                        >
                          <div className="fw400 fz14">
                            <div className="px-3 gradientText--radial">
                              <span className="fz16 fw600">Example</span>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="endpoints-inside-text">
                        <p className="text-green fw400 fz12 mb-0">example = </p>

                        <div className="fz12 array-values">
                          <div className="orange-text">
                            <span className="pink-text">{"{"}</span>
                            <br />
                            "email": "abc@abc.com", "otp": "1234"
                            <br />
                            <span className="pink-text"> {"}"}</span>
                          </div>
                        </div>
                      </div>

                      <div className="endpoints-inside-text">
                        <p className="text-green fw400 fz12 mb-0">
                          Response Token ={" "}
                        </p>

                        <div className="fz12 array-values">
                          <div className="orange-text">
                            <span className="pink-text">{"{"}</span>
                            <br />
                            <span className="yellow-text">"refresh":</span>{" "}
                            <br />
                            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY5Mjc5NTQzMywiaWF0IjoxNjkyNzA5MDMzLCJqdGkiOiJlYjZiZTNlYjYwZjg0NjNhYjVhNzA3OWE0MmM1MmZjYiIsInVzZXJfaWQiOjJ9.2cSUG32II7GKFeeCUbEXjgrNSS1re1Os-98zc33M3vI",
                            <br />
                            <span className="yellow-text"> "access":</span>{" "}
                            <br />
                            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjkyNzEwODMzLCJpYXQiOjE2OTI3MDkwMzMsImp0aSI6ImMxNDFkZTZkOTY3MjRiNjhhZjY1ODMwZTNlNDIxNjRlIiwidXNlcl9pZCI6Mn0.4jZn8NugSDNBakmcM1Tqec16MwS5S7jtSL43X9BBzYE"
                            <br />
                            <span className="pink-text"> {"}"} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item bdrs10 main-shadow accordian-widget">
                  <button
                    className="w-100 p-0 btn border-0 accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    <div className="w-100 ts03 api-cards-header">
                      <div className="linebefore">
                        <span></span>
                        <span></span>
                      </div>
                      <span className="fz16 fw500 gradientText--radial">
                        To Logout or Block refresh token
                      </span>
                    </div>
                  </button>

                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#apiDocumentation"
                  >
                    <div className="api-cards-body bdrs10">
                      <div className="endpoint-card-wrapper">
                        <div className="left">
                          <h3 className="fw600 fz16 m-0">URL</h3>
                        </div>
                        <ul className="list-inline linksURL">
                          <li>
                            <div className="endpoints-Insidewrapper">
                              <div className="d-flex align-items-center gap-2 right">
                                <button className="box">
                                  <img
                                    src={urlPaste}
                                    onClick={() =>
                                      handleCopyClick(
                                        "  https://api.holografs.com/account/logout"
                                      )
                                    }
                                    alt=""
                                  />
                                </button>
                                <textarea
                                  ref={textAreaRef}
                                  value="https://api.holografs.com/product/addproduct"
                                  readOnly
                                  style={{
                                    position: "absolute",
                                    left: "-9999px",
                                  }}
                                />
                                <p className="grayText m-0">
                                  https://api.holografs.com/account/logout
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <hr className="endpoint-card-divider" />

                      <div className="endpoint-card-wrapper">
                        <div className="left">
                          <h3 className="fw600 fz16 m-0">Method</h3>
                        </div>
                        <div className="endpoints-Insidewrapper">
                          <div className="d-flex align-items-center gap-2 right">
                            <span className="brnd_btn_black content-box m-0 text-uppercase">
                              <span className="m-0">POST</span>
                            </span>
                          </div>
                        </div>
                      </div>

                      <hr className="endpoint-card-divider" />

                      <div className="black-box-inside mb-2">
                        <div className="d-flex align-items-center gap-2">
                          <button className="white-box">
                            <img src={urlPasteBlack} alt="" />
                          </button>
                          <button
                            type="button"
                            className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                          >
                            <div className="fw400 fz14">
                              <div className="px-3 gradientText--radial">
                                <span className="fz16 fw600">Fields</span>
                              </div>
                            </div>
                          </button>
                        </div>

                        <div className="endpoints-inside-text">
                          <p className="text-green fw400 fz12 mb-0">
                            fields ={" "}
                          </p>

                          <div className="fz12 array-values">
                            <div className="orange-text">
                              <span className="pink-text">[</span>
                              <br />
                              'refresh_token'
                              <br />
                              <span className="pink-text">]</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="black-box-inside mb-2">
                        <div className="d-flex align-items-center gap-2">
                          <button className="white-box">
                            <img src={urlPasteBlack} alt="" />
                          </button>
                          <a
                            href="#"
                            className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                          >
                            <div className="fw400 fz14">
                              <div className="px-3 gradientText--radial">
                                <span className="fz16 fw600">Example</span>
                              </div>
                            </div>
                          </a>
                        </div>

                        <div className="endpoints-inside-text">
                          <p className="text-green fw400 fz12 mb-0">
                            example ={" "}
                          </p>

                          <div className="fz12 array-values">
                            <div className="orange-text">
                              <span className="yellow-text">
                                "refresh_token":
                              </span>
                              <br />
                              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY5NjkyMTcyNiwiaWF0IjoxNjk2ODM1MzI2LCJqdGkiOiJkNDZiYjU4MjJhOGM0ODE0Yjc2MmY4ZjQ4ZmZlYTE2YSIsInVzZXJfaWQiOjJ9.jh6nbQUOFh69lrnXK0GevPJ5bwU1x3CU096ewPV3eho"
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item bdrs10 main-shadow accordian-widget">
                  <button
                    className="w-100 p-0 btn border-0 accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  >
                    <div className="w-100 ts03 api-cards-header">
                      <div className="linebefore">
                        <span></span>
                        <span></span>
                      </div>
                      <span className="fz16 fw500 gradientText--radial">
                        How make request on all API
                      </span>
                    </div>
                  </button>

                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#apiDocumentation"
                  >
                    <div className="api-cards-body bdrs10">
                      <div className="endpoint-card-wrapper">
                        <ul className="list-inline linksURL">
                          <li>
                            <div className="endpoints-Insidewrapper">
                              <div className="d-flex align-items-center gap-2 right">
                                <button className="box">
                                  <img src={urlPaste} alt="" />
                                </button>
                                <p className="grayText m-0">
                                  curl -X 'POST' \ <br />
                                  'http://35.173.227.195:82/account/login' \{" "}
                                  <br />
                                  -H 'accept: application/json' \ <br />
                                  -H 'Authorization: Bearer eyJhbGciOiJIUzI1
                                  .... \ <br />
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <BottomNavbar />
    </div>
  );
}
