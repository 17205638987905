export const GET_ALL_PRODUCTS_SUCCESS  = "productinventory/GET_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAILURE  = "productinventory/GET_PRODUCTS_FAILURE";
export const ADD_ITEM_SUCCESS  = "productinventory/ADD_ITEM_SUCCESS";
export const ADD_ITEM_FAILURE  = "productinventory/ADD_ITEM_FAILURE";
export const GET_ITEM_FROM_RESP_SUCCESS  = "productinventory/GET_ITEM_FROM_RESP_SUCCESS";
export const GET_ITEM_FROM_RESP_FAILURE  = "productinventory/GET_ITEM_FROM_RESP_FAILURE";
export const UPDATE_BATCH_SUCCESS  = "productinventory/UPDATE_BATCH_SUCCESS";
export const UPDATE_BATCH_FAILURE  = "productinventory/UPDATE_BATCH_FAILURE";
export const BATCH_RESP_SUCCESS  = "productinventory/BATCH_RESP_SUCCESS";
export const BATCH_RESP_FAILURE  = "productinventory/BATCH_RESP_FAILURE";
export const VERIFY_OWNER_SUCCESS  = "productinventory/CHANGE_OWNER_SUCCESS";
export const VERIFY_OWNER_FAILURE  = "productinventory/CHANGE_OWNER_FAILURE";
export const CHANGE_OWNER_SUCCESS  = "productinventory/CHANGE_OWNER_SUCCESS";
export const CHANGE_OWNER_FAILURE  = "productinventory/CHANGE_OWNER_FAILURE";
export const GET_PRODUCT_SUCCESS  = "productinventory/GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE  = "productinventory/GET_PRODUCT_FAILURE";
export const GET_OWNER_DETAIL_BY_ID_SUCCESS  = "productinventory/GET_OWNER_DETAIL_BY_ID_SUCCESS";
export const GET_OWNER_DETAIL_BY_ID_FAILURE  = "productinventory/GET_OWNER_DETAIL_BY_ID_FAILURE";
export const GET_USER_BY_ID_SUCCESS  = "productinventory/GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAILURE  = "productinventory/GET_USER_BY_ID_FAILURE";
export const LOADER_TRUE = "productinventory/LOADER_TRUE";
export const LOADER_FALSE = "productinventory/LOADER_FALSE";
export const GET_ALL_CATEGORY_SUCCESS  = "productinventory/GET_ALL_CATEGORY_SUCCESS";
export const GET_ALL_CATEGORY_FAILURE  = "productinventory/GET_ALL_CATEGORY_FAILURE";
export const PRODUCT_BY_ID_SUCCESS = "productinventory/PRODUCT_BY_ID_SUCCESS";
export const PRODUCT_BY_ID_FAILURE  = "productinventory/PRODUCT_BY_ID_FAILURE";
