import axios from "axios";
import { POST_SEND_QUERY } from "../../services/Api";
import { SEND_QUERY_FAILURE, SEND_QUERY_SUCCESS } from "./landing.actionTypes";

export const sendQuery = (data) => async (dispatch) => {
    // const token = JSON.parse(localStorage.getItem("token")) || null;
    // const headers = {
    //   Authorization: `Bearer ${token}`,
    //   "Content-Type": "application/json",
    // }
    try {
      const resp = await axios.post(POST_SEND_QUERY, data);
      if (resp.data) {
        dispatch({
          type: SEND_QUERY_SUCCESS,
          payload: resp.data,
        });
      } else {
        dispatch({
          type: SEND_QUERY_FAILURE,
          message: "",
        });
      }
    } catch (error) {
      dispatch({
        type: SEND_QUERY_FAILURE,
        payload: {
          message: error,
        },
      });
    }
  };