import {
  GET_ALL_PACKAGE_FAILURE,
  GET_ALL_PACKAGE_SUCCESS,
  GET_SINGLE_PACKAGE_FAILURE,
  GET_SINGLE_PACKAGE_SUCCESS,
  ADD_PACKAGE_SUCCESS,
  ADD_PACKAGE_FAILURE,
  GET_ITEM_FROM_PACKAGE_FAILURE,
  GET_ITEM_FROM_PACKAGE_SUCCESS,
  GET_PACKAGE_ITEM_DETAILS_FAILURE,
  GET_PACKAGE_ITEM_DETAILS_SUCCESS,
  ADD_ITEM_TO_PACKAGE_FAILURE,
  ADD_ITEM_TO_PACKAGE_SUCCESS,
  SEARCH_SINGLE_PACKAGE_FAILURE,
  SEARCH_SINGLE_PACKAGE_SUCCESS,
  LOADER_TRUE,
  LOADER_FALSE,
  UPDATE_PACKAGE_FAILURE,
  UPDATE_PACKAGE_SUCCESS,
  ADD_OUTSOURCE_PACKAGING_FAILURE,
  ADD_OUTSOURCE_PACKAGING_SUCCESS
} from "./packaging.actionTypes";

import {
  CREATE_PACKAGE,
  GET_ALL_PACKAGE,
  GET_SINGLE_PACKAGE,
  ADD_ITEM_TO_PACKAGE,
  GET_ITEM_FROM_PACKAGE,
  GET_PRODUCT,
  UPDATE_PACKAGE,
  GET_ITEM_DETAILS,
  ADD_OUTSOURCE_PACKAGE
} from "../../services/Api";
import axios from "axios";
import { toast } from "react-toastify";
import ErrorToast from "../../reusable_components/ErrorToast";

export const AddPackage = (data) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  dispatch({ type: LOADER_TRUE });

  try {
    let response = await axios.post(`${CREATE_PACKAGE}`, data, {
      headers,
    });
    console.log("response Package", response);

    dispatch({
      type: ADD_PACKAGE_SUCCESS,
      payload: response?.data?.packege,
    });

    dispatch({ type: LOADER_FALSE });
  } catch (error) {
    console.log({ error: error });
    dispatch({
      type: ADD_PACKAGE_FAILURE,
    });

    dispatch({ type: LOADER_FALSE });
  }
};

export const GetAllPackages = () => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  dispatch({ type: LOADER_TRUE });

  try {
    let response = await axios.get(`${GET_ALL_PACKAGE}`, {
      headers,
    });

    dispatch({
      type: GET_ALL_PACKAGE_SUCCESS,
      payload: response?.data?.reverse(),
    });

    dispatch({ type: LOADER_FALSE });
  } catch (error) {
    console.log({ error: error });
    dispatch({
      type: GET_ALL_PACKAGE_FAILURE,
    });
    dispatch({ type: LOADER_FALSE });
  }
};

export const GetSinglePackage = (id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  dispatch({ type: LOADER_TRUE });
  try {
    let response = await axios.get(`${GET_SINGLE_PACKAGE}/${id}`, {
      headers,
    });

    dispatch({
      type: GET_SINGLE_PACKAGE_SUCCESS,
      payload: response?.data,
    });
    dispatch({ type: LOADER_FALSE });
  } catch (error) {
    console.log({ error: error });
    dispatch({
      type: GET_SINGLE_PACKAGE_FAILURE,
    });
    dispatch({ type: LOADER_FALSE });
  }
};

export const AddItemToPackage = (data) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  dispatch({ type: LOADER_TRUE });
  try {
    let response = await axios.post(`${ADD_ITEM_TO_PACKAGE}`, data, {
      headers,
    });

    dispatch({
      type: ADD_ITEM_TO_PACKAGE_SUCCESS,
    });
    dispatch({ type: LOADER_FALSE });
  } catch (error) {
    console.log({ error: error });
    dispatch({
      type: ADD_ITEM_TO_PACKAGE_FAILURE,
    });
    dispatch({ type: LOADER_FALSE });
  }
};

export const GetItemFromPackage = (id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  dispatch({ type: LOADER_TRUE });

  try {
    let response = await axios.get(`${GET_ITEM_FROM_PACKAGE}/${id}`, {
      headers,
    });
    const itemList = response?.data?.content_data;
    dispatch({
      type: GET_ITEM_FROM_PACKAGE_SUCCESS,
      payload: {
        packageitems: response?.data,
        ContentData: itemList,
      },
    });
    dispatch({ type: LOADER_FALSE });
    let detailsArr = [];
    //  if (itemList){
    //    // itemList &&
    //    //   itemList.forEach((el) => {
    //    //     const GetPackageInfo = async (id) => {
    //    //       try {
    //    //         let response = await axios.get(`${GET_SINGLE_PACKAGE}/${id}`, {
    //    //           headers,
    //    //         });
    //    //        // console.log("detailsArr", response?.data);
    //    //         detailsArr.push(response?.data)

    //    //       } catch (error) {
    //    //         console.log({ error: error });
    //    //       }
    //    //     };
    //    //     GetPackageInfo(el?.id);

    //    //   });

    //    // Create an array of promises
    //   //  const promises = itemList.map((el) => {
    //   //    return axios.get(`${GET_PRODUCT}/${el?.id}`, {
    //   //      headers,
    //   //    });
    //   //  });

    //    // Wait for all promises to resolve
    //   //  Promise.all(promises)
    //   //    .then((responses) => {
    //   //      // responses is an array of resolved axios responses
    //   //      detailsArr = responses.map((response) => response?.data);
    //   //      console.log("DetailsARR",detailsArr)

    //   //    })

    //  }
  } catch (error) {
    console.log({ error: error });
    dispatch({
      type: GET_ITEM_FROM_PACKAGE_FAILURE,
    });
    dispatch({ type: LOADER_FALSE });
  }
};

export const GetItemDetailsFromPackage = (arr) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  dispatch({ type: LOADER_TRUE });
  try {
    let detailsArr = [];

    
    const promises = arr.map((el) => {
      return axios.get(`${GET_ITEM_DETAILS}/${el?.id}`, {
        headers,
      });
    });
    // Wait for all promises to resolve
    Promise.all(promises).then((responses) => {
      // responses is an array of resolved axios responses
      detailsArr = responses.map((response) => response?.data);
      dispatch({
        type: GET_PACKAGE_ITEM_DETAILS_SUCCESS,
        payload: {
          detailsArr: detailsArr,
        },
      });
      dispatch({ type: LOADER_FALSE });
    });
  } catch (error) {
    console.log({ error: error });
    dispatch({
      type: GET_PACKAGE_ITEM_DETAILS_FAILURE,
    });
    dispatch({ type: LOADER_FALSE });
  }
};

export const SearchSinglePackage = (id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  dispatch({ type: LOADER_TRUE });
  try {
    let response = await axios.get(`${GET_SINGLE_PACKAGE}/${id}`, {
      headers,
    });
    if (response?.data?.msg?.includes("Invalid Product id")) {
      toast(<ErrorToast content={'Please enter valid package ID'} />, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
     
    }

    dispatch({
      type: SEARCH_SINGLE_PACKAGE_SUCCESS,
      payload: response?.data,
    });
    dispatch({ type: LOADER_FALSE });
  } catch (error) {
    console.log({ Errrror: error });
    dispatch({
      type: SEARCH_SINGLE_PACKAGE_FAILURE,
    });
    dispatch({ type: LOADER_FALSE });
  }
};

export const UpdatePackage = (data, id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  dispatch({ type: LOADER_TRUE });

  try {
    let response = await axios.patch(`${UPDATE_PACKAGE}/${id}`, data, {
      headers,
    });

    dispatch({
      type: UPDATE_PACKAGE_SUCCESS,
    });

    dispatch({ type: LOADER_FALSE });
  } catch (error) {
    console.log({ error: error });
    dispatch({
      type: UPDATE_PACKAGE_FAILURE,
    });

    dispatch(GetAllPackages());
  }
};

export const AddOutsourcePackage = (data) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  axios
    .post(ADD_OUTSOURCE_PACKAGE, data, { headers })
    .then((response) => {
      // Handle response
      
      dispatch({
        type: ADD_OUTSOURCE_PACKAGING_SUCCESS,
        payload: response?.data?.id,
      });
    })
    .catch((err) => {
      // Handle errors
      console.error("Error sending data:", err);
      dispatch({
        type: ADD_OUTSOURCE_PACKAGING_FAILURE,
      });
    });
};
