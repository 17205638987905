// Single Product

export const ADD_PRODUCT_SUCCESS = "singleproduct/ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "singleproduct/ADD_PRODUCT_FAILURE";
export const GET_PRODUCT_SUCCESS = "singleproduct/GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "singleproduct/GET_PRODUCT_FAILURE";
export const GET_CATEGORY_SUCCESS = "singleproduct/GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILURE = "singleproduct/GET_CATEGORY_FAILURE";
export const GET_COLOR_SUCCESS = "singleproduct/GET_COLOR_SUCCESS";
export const GET_COLOR_FAILURE = "singleproduct/GET_COLOR_FAILURE";
export const ADD_PRODUCT_DETAILS_SUCCESS =
  "singleproduct/ADD_PRODUCT_DETAILS_SUCCESS";
export const ADD_PRODUCT_DETAILS_FAILURE =
  "singleproduct/ADD_PRODUCT_DETAILS_FAILURE";
export const GET_PRODUCT_DETAILS_SUCCESS =
  "singleproduct/GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_FAILURE =
  "singleproduct/GET_PRODUCT_DETAILS_FAILURE";

export const UPDATE_PRODUCT_SUCCESS = "singleproduct/UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "singleproduct/UPDATE_PRODUCT_FAILURE";
export const UPDATE_PRODUCT_DETAILS_SUCCESS =
  "singleproduct/UPDATE_PRODUCT_DETAILS_SUCCESS";
export const UPDATE_PRODUCT_DETAILS_FAILURE =
  "singleproduct/UPDATE_PRODUCT_DETAILS_FAILURE";

export const GET_QR_SUCCESS = "singleproduct/GET_QR_SUCCESS";
export const GET_QR_FAILURE = "singleproduct/GET_QR_FAILURE";
export const UPLOAD_QR_SUCCESS = "singleproduct/UPLOAD_QR_SUCCESS";
export const UPLOAD_QR_FAILURE = "singleproduct/UPLOAD_QR_FAILURE";
export const UPLOAD_PRODUCT_IMAGE_SUCCESS =
  "singleproduct/UPLOAD_PRODUCT_IMAGE_SUCCESS";
export const UPLOAD_PRODUCT_IMAGE_FAILURE =
  "singleproduct/UPLOAD_PRODUCT_IMAGE_FAILURE";
export const LOADER_FALSE = "singleproduct/LOADER_FALSE";
export const LOADER_TRUE = "singleproduct/LOADER_TRUE";
export const GET_ALL_PRODUCTS_SUCCESS = "singleproduct/GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAILURE = "singleproduct/GET_ALL_PRODUCTS_FAILURE";