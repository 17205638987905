import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactGA from 'react-ga';
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import './assets/styles/main.css'
import React, { Suspense, lazy, useEffect } from "react";
import Loader from "./reusable_components/loader";
import BadRequest from "./reusable_components/badRequest";
import LandingPage from "./containers/landing/index";
import LandingSearch from "./containers/tracking/landingSearch";
import Notification from "./layouts/notification";
import PrivateRoutes from "./containers/private_Routes/PrivateRoutes";
import Introduction from "./containers/documentation/api-documentation/introduction";
import ApiEndpoints from "./containers/documentation/api-documentation/apiEndpoints";
import Authentication from "./containers/documentation/api-documentation/authentication";
import ApiReqResFormats from "./containers/documentation/api-documentation/apiReqResFormats";
import ApiUsage from "./containers/documentation/api-documentation/apiUsage";
import ApiErrorHandling from "./containers/documentation/api-documentation/apiErrorHandling";
import SecurityGuidelines from "./containers/documentation/key-management/securityGuidelines";
import ApiKeyGeneration from "./containers/documentation/key-management/apiKeyGeneration";
import KeyManagement from "./containers/documentation/key-management/keyManagement";
import SdksLibraries from "./containers/documentation/developer-resources/sdksLibraries";
import SecLandingPage from "./containers/secLanding";
import ThankYouPage from "./containers/thankyou";

function App() {

  useEffect(() => {
    ReactGA.initialize('G-D8ST7WW49P');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const Login = lazy(() => import('./containers/authentication/login_page/login'));
  const OTP = lazy(() => import('./containers/authentication/login_page/otp'));
  const Dashboard = lazy(() => import('./containers/dashboard'));
  const ProfileMainPage = lazy(() => import('./containers/profile/profileMainPage'));
  const BasicAdminProfile = lazy(() => import('./containers/profile/basicAdminProfile'));
  const CompanyInformation = lazy(() => import('./containers/profile/companyInformation'));
  const UserProfile = lazy(() => import('./containers/profile/UserProfile'));
  const Support = lazy(() => import('./containers/dashboard/support'));
  const Activities = lazy(() => import('./containers/activities/activities'));
  const AddProduct = lazy(() => import('./containers/product/single_product/basic_information/addProduct'));
  const AddDetailedInformation = lazy(() => import('./containers/product/single_product/detailed_information'));
  const ProductPreview = lazy(() => import('./containers/product/single_product/product_Preview'));
  const ProductInventoryView = lazy(() => import('./containers/product_Inventory'));
  const ProductDetails = lazy(() => import('./containers/product_Inventory/productDetails'));
  const PakageInformation = lazy(() => import('./containers/Inventory/packaging_details/pakage_information'));
  const PackagingOuterSourcingDetails = lazy(() => import('./containers/Inventory/packaging_details/outsourcing_details'));
  const BasicPackagingDetails = lazy(() => import('./containers/Inventory/packaging_details/basic_pakaging_details'));
  const PakagingAddItems = lazy(() => import('./containers/Inventory/packaging_details/additems'));
  const PackagingPreview = lazy(() => import('./containers/Inventory/packaging_details/packaging_preview'));
  const SelectItemsPage = lazy(() => import('./containers/Inventory/packaging_details/additems/SelectItemsPage'));
  const PackagingQRSuccess = lazy(() => import('./containers/Inventory/packaging_details/pakaging_success/QR_success'));
  const PackagingIOTSuccess = lazy(() => import('./containers/Inventory/packaging_details/pakaging_success/IOT_success'));
  const WarehouseTypes = lazy(() => import('./containers/Inventory/warehouse_details/warehouse_types'));
  const BasicWarehouseDetails = lazy(() => import('./containers/Inventory/warehouse_details/basic_warehouse_details'));
  const WarehouseList = lazy(() => import('./containers/Inventory/warehouse_details/warehouse_table_page'));
  const WareHousePreview = lazy(() => import('./containers/Inventory/warehouse_details/warehouse_preview'));
  const BasicNextOwnerDetails = lazy(() => import('./containers/Inventory/next_owner_details/basic_owner_details'));
  const NextOwnerList = lazy(() => import('./containers/Inventory/next_owner_details/next_owner_packagelist'));
  const NextOwnerItems = lazy(() => import('./containers/incentives/package_Table'));
  const NextOwnerPreview = lazy(() => import('./containers/Inventory/next_owner_details/next_owner_preview'));
  const TransportationTypes = lazy(() => import('./containers/Inventory/transportation_details/transportation_types'));
  const TransportationOuterSourcingDetails = lazy(() => import('./containers/Inventory/transportation_details/outsoursing_details'));
  const TransportationModes = lazy(() => import('./containers/Inventory/transportation_details/transpotaion_mode'));
  const PackageTransportList = lazy(() => import('./containers/Inventory/transportation_details/select_packages'));
  const TransportationPreview = lazy(() => import('./containers/Inventory/transportation_details/transportation_preview'));
  const TrackingStatus = lazy(() => import('./containers/tracking/trackingStatus'));
  const OwnershipFlow = lazy(() => import('./containers/tracking/ownershipFlow'));
  const OwnershipFlowInventory = lazy(() => import('./containers/tracking/ownershipFlowInventory'));
  const OwnershipFlowPackage = lazy(() => import('./containers/tracking/ownershipFlowPackage'));
  const InventoryReport = lazy(() => import('./containers/reports/inventory-report'));
  const LogisticsReport = lazy(() => import('./containers/reports/logistics-report'));
  const CounterfeitReport = lazy(() => import('./containers/reports/counterfeit-report'));
  const LogisticsListings = lazy(() => import('./containers/Inventory'));
  const ListPackage = lazy(() => import('./containers/Inventory/packaging_details/packaging-level2/list-package'));
  const PackageDetails = lazy(() => import('./containers/Inventory/Items_in_Package'));
  const PreviewPackage = lazy(() => import('./containers/Inventory/packaging_details/packaging-level2/preview-package'));
  const PackagingLevel2QRSuccess = lazy(() => import('./containers/Inventory/packaging_details/packaging-level2/QR-success-level2'));
  const PackagingIOTLevel2Success = lazy(() => import('./containers/Inventory/packaging_details/packaging-level2/IOT-success-level2'));
  const WarrantySection = lazy(() => import('./containers/warranty'));
  const IncentivesPages = lazy(() => import('./containers/incentives'));
  const QRSuccess = lazy(() => import('./containers/product/single_product/product_Preview/generate_QR_IOT/QRSuccess'));
  // const Introduction = lazy(() => import('./containers/documentation/api-documentation/introduction'));
  // const ApiEndpoints = lazy(() => import('./containers/documentation/api-documentation/apiEndpoints'));
  // const Authentication = lazy(() => import('./containers/documentation/api-documentation/authentication'));
  // const ApiReqResFormats = lazy(() => import('./containers/documentation/api-documentation/apiReqResFormats'));
  // const ApiUsage = lazy(() => import('./containers/documentation/api-documentation/apiUsage'));
  // const ApiErrorHandling = lazy(() => import('./containers/documentation/api-documentation/apiErrorHandling'));
  // const ApiKeyGeneration = lazy(() => import('./containers/documentation/key-management/apiKeyGeneration'));
  // const KeyManagement = lazy(() => import('./containers/documentation/key-management/keyManagement'));
  // const SecurityGuidelines = lazy(() => import('./containers/documentation/key-management/securityGuidelines'));
  // const SdksLibraries = lazy(() => import('./containers/documentation/developer-resources/sdksLibraries'));
  const BlockchainExplorer = lazy(() => import('./containers/blockchain-explorer'));
  const BlockchainExplorerDetails = lazy(() => import('./containers/blockchain-explorer/blockchain_details'));
  const Privacy = lazy(() => import('./containers/footer/privacy'));
  const TermsAndConditions = lazy(() => import('./containers/footer/termsAndConditions'));
  
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/holografs-anti-counterfeit-solution" element={<SecLandingPage />} />
          <Route path="/thankyou" element={<ThankYouPage />} />
          <Route path="*" element={<BadRequest />} />
          <Route path="/notification" element={<PrivateRoutes><Notification /></PrivateRoutes>}/>

          {/* Authentication */}
          <Route path="/login" element={<Suspense fallback={<Loader />}><Login /></Suspense>} />
          <Route path="/otp" element={<Suspense fallback={<Loader />}><OTP /></Suspense>} />

          {/* Dashboard */}
          <Route path="/dashboard" element={<PrivateRoutes><Suspense fallback={<Loader />}><Dashboard /></Suspense></PrivateRoutes>} />

          {/* Dashboard Items */}
          <Route path="/support" element={<PrivateRoutes><Suspense fallback={<Loader />}><Support /></Suspense></PrivateRoutes>} />
          <Route path="/activities" element={<PrivateRoutes><Suspense fallback={<Loader />}><Activities /></Suspense></PrivateRoutes>} />
          <Route path="/incentives" element={<PrivateRoutes><Suspense fallback={<Loader />}><IncentivesPages /></Suspense></PrivateRoutes>} />

          {/* Profile Section */}
          <Route path="/profile" element={<PrivateRoutes><Suspense fallback={<Loader />}><ProfileMainPage /></Suspense></PrivateRoutes>} />
          <Route path="//basic-admin-profile" element={<PrivateRoutes><Suspense fallback={<Loader />}><BasicAdminProfile /></Suspense></PrivateRoutes>} />
          <Route path="/company-information" element={<PrivateRoutes><Suspense fallback={<Loader />}><CompanyInformation /></Suspense></PrivateRoutes>} />
          <Route path="/userProfile" element={<PrivateRoutes><Suspense fallback={<Loader />}><UserProfile /></Suspense></PrivateRoutes>} />

          {/* Add Product */}
          <Route path="/add-product" element={<PrivateRoutes><Suspense fallback={<Loader />}><AddProduct /></Suspense></PrivateRoutes>} />
          <Route path="/edit-product/:id?" element={<PrivateRoutes><Suspense fallback={<Loader />}><AddProduct /></Suspense></PrivateRoutes>} />
          <Route path="/detailed-information" element={<PrivateRoutes><Suspense fallback={<Loader />}><AddDetailedInformation /></Suspense></PrivateRoutes>} />
          <Route path="/edit-detailed-information/:id?" element={<PrivateRoutes><Suspense fallback={<Loader />}><AddDetailedInformation /></Suspense></PrivateRoutes>} />
          <Route path="/product-preview" element={<PrivateRoutes><Suspense fallback={<Loader />}><ProductPreview /></Suspense></PrivateRoutes>} />
          <Route path="/QR-generated" element={<PrivateRoutes><Suspense fallback={<Loader />}><QRSuccess /></Suspense></PrivateRoutes>} />
          {/* Product Inventory */}
          <Route path="/inventoryView" element={<PrivateRoutes><Suspense fallback={<Loader />}><ProductInventoryView /></Suspense></PrivateRoutes>} />
          <Route path="/product-details/:id" element={<PrivateRoutes><Suspense fallback={<Loader />}><ProductDetails /></Suspense></PrivateRoutes>} />
        
          {/* Logistics Pages (Packaging) */}
          <Route path="/pakage-information" element={<PrivateRoutes><Suspense fallback={<Loader />}><PakageInformation /></Suspense></PrivateRoutes>} />
          <Route path="/pakaging-outsourcing-details" element={<PrivateRoutes><Suspense fallback={<Loader />}><PackagingOuterSourcingDetails /></Suspense></PrivateRoutes>} />
          <Route path="/pakaging-basic-details" element={<PrivateRoutes><Suspense fallback={<Loader />}><BasicPackagingDetails /></Suspense></PrivateRoutes>} />
          <Route path="/pakaging-add-items" element={<PrivateRoutes><Suspense fallback={<Loader />}><PakagingAddItems /></Suspense></PrivateRoutes>} />
          <Route path="/pakaging-details-preview" element={<PrivateRoutes><Suspense fallback={<Loader />}><PackagingPreview /></Suspense></PrivateRoutes>} />
          <Route path="/packaging-product-details/:id" element={<PrivateRoutes><Suspense fallback={<Loader />}><SelectItemsPage /></Suspense></PrivateRoutes>} />
          <Route path="/pakaging-QR-generated" element={<PrivateRoutes><Suspense fallback={<Loader />}><PackagingQRSuccess /></Suspense></PrivateRoutes>} />
          <Route path="/pakaging-IOT-generated" element={<PrivateRoutes><Suspense fallback={<Loader />}><PackagingIOTSuccess /></Suspense></PrivateRoutes>} />

          {/* Inventory Pages (Warehouse) */}
          <Route path="/warehouse-types" element={<PrivateRoutes><Suspense fallback={<Loader />}><WarehouseTypes /></Suspense></PrivateRoutes>} />
          <Route path="/warehouse-basic-details/:id?" element={<PrivateRoutes><Suspense fallback={<Loader />}><BasicWarehouseDetails /></Suspense></PrivateRoutes>} />
          <Route path="/warehouse-list" element={<PrivateRoutes><Suspense fallback={<Loader />}><WarehouseList /></Suspense></PrivateRoutes>} />
          <Route path="/warehouse-preview" element={<PrivateRoutes><Suspense fallback={<Loader />}><WareHousePreview /></Suspense></PrivateRoutes>} />

          {/* Logistics Pages (Next Owner) */}
          <Route path="/nextowner-basic-details" element={<PrivateRoutes><Suspense fallback={<Loader />}><BasicNextOwnerDetails /></Suspense></PrivateRoutes>} />
          <Route path="/nextowner-list" element={<PrivateRoutes><Suspense fallback={<Loader />}><NextOwnerList /></Suspense></PrivateRoutes>} />
          <Route path="/nextowner-item-list/:id" element={<PrivateRoutes><Suspense fallback={<Loader />}><NextOwnerItems /></Suspense></PrivateRoutes>} />
          <Route path="/nextowner-preview" element={<PrivateRoutes><Suspense fallback={<Loader />}><NextOwnerPreview /></Suspense></PrivateRoutes>} />
          
          {/* Logistics Pages (Transportation) */}
          <Route path="/transportation-types" element={<PrivateRoutes><Suspense fallback={<Loader />}><TransportationTypes /></Suspense></PrivateRoutes>} />
          <Route path="/transportation-outsourcing-details" element={<PrivateRoutes><Suspense fallback={<Loader />}><TransportationOuterSourcingDetails /></Suspense></PrivateRoutes>} />
          <Route path="/transportation-mode/:id?" element={<PrivateRoutes><Suspense fallback={<Loader />}><TransportationModes /></Suspense></PrivateRoutes>} />
          <Route path="/packagelist" element={<PrivateRoutes><Suspense fallback={<Loader />}><PackageTransportList /></Suspense></PrivateRoutes>} />
          <Route path="/transportation-preview" element={<PrivateRoutes><Suspense fallback={<Loader />}><TransportationPreview /></Suspense></PrivateRoutes>} />

          {/* Logistics Level2 */}
          <Route path="/logistics-listings" element={<PrivateRoutes><Suspense fallback={<Loader />}><LogisticsListings /></Suspense></PrivateRoutes>} />
          <Route path="/list-package" element={<PrivateRoutes><Suspense fallback={<Loader />}><ListPackage /></Suspense></PrivateRoutes>} />
          <Route path="/package-details/:id" element={<PrivateRoutes><Suspense fallback={<Loader />}><PackageDetails /></Suspense></PrivateRoutes>} />
          <Route path="/preview-package" element={<PrivateRoutes><Suspense fallback={<Loader />}><PreviewPackage /></Suspense></PrivateRoutes>} />
          <Route path="/QR-success-level2" element={<PrivateRoutes><Suspense fallback={<Loader />}><PackagingLevel2QRSuccess /></Suspense></PrivateRoutes>} />
          <Route path="/IOT-success-level2" element={<PrivateRoutes><Suspense fallback={<Loader />}><PackagingIOTLevel2Success /></Suspense></PrivateRoutes>} />
          
          {/* Tracking */}
          <Route path="/tracking/:id?" element={<PrivateRoutes><Suspense fallback={<Loader />}><TrackingStatus /></Suspense></PrivateRoutes>} />
          <Route path="/ownership/:QRcode" element={<PrivateRoutes><Suspense fallback={<Loader />}><OwnershipFlow /></Suspense></PrivateRoutes>} />
          <Route path="/ownershipinventory/:QRcode" element={<PrivateRoutes><Suspense fallback={<Loader />}><OwnershipFlowInventory /></Suspense></PrivateRoutes>} />
          <Route path="/ownershippackage/:QRcode" element={<PrivateRoutes><Suspense fallback={<Loader />}><OwnershipFlowPackage /></Suspense></PrivateRoutes>} />
          <Route path="/productTracking/:id" element={<LandingSearch />} />
        
          {/* Reports */}
          <Route path="/inventory-report" element={<PrivateRoutes><Suspense fallback={<Loader />}><InventoryReport /></Suspense></PrivateRoutes>} />
          <Route path="/logistics-report" element={<PrivateRoutes><Suspense fallback={<Loader />}><LogisticsReport /></Suspense></PrivateRoutes>} />
          <Route path="/counterfeit-report" element={<PrivateRoutes><Suspense fallback={<Loader />}><CounterfeitReport /></Suspense></PrivateRoutes>} />
    
          {/* Warranty */}
          <Route path="/warranty" element={<PrivateRoutes><Suspense fallback={<Loader />}><WarrantySection /></Suspense></PrivateRoutes>} />
       
          {/* Documentation */}
          {/* <Route path="/doc-introduction" element={<PrivateRoutes><Suspense fallback={<Loader />}><Introduction /></Suspense></PrivateRoutes>} />
          <Route path="/doc-api-endpoints" element={<PrivateRoutes><Suspense fallback={<Loader />}><ApiEndpoints /></Suspense></PrivateRoutes>} />
          <Route path="/doc-authentication" element={<PrivateRoutes><Suspense fallback={<Loader />}><Authentication /></Suspense></PrivateRoutes>} />
          <Route path="/doc-formats" element={<PrivateRoutes><Suspense fallback={<Loader />}><ApiReqResFormats /></Suspense></PrivateRoutes>} />
          <Route path="/doc-apiusage" element={<PrivateRoutes><Suspense fallback={<Loader />}><ApiUsage /></Suspense></PrivateRoutes>} />
          <Route path="/doc-errorhandling" element={<PrivateRoutes><Suspense fallback={<Loader />}><ApiErrorHandling /></Suspense></PrivateRoutes>} />
          <Route path="/doc-key-generation" element={<PrivateRoutes><Suspense fallback={<Loader />}><ApiKeyGeneration /></Suspense></PrivateRoutes>} />
          <Route path="/doc-key-management" element={<PrivateRoutes><Suspense fallback={<Loader />}><KeyManagement /></Suspense></PrivateRoutes>} />
          <Route path="/doc-key-security-guidelines" element={<PrivateRoutes><Suspense fallback={<Loader />}><SecurityGuidelines /></Suspense></PrivateRoutes>} />
          <Route path="/doc-sdks" element={<PrivateRoutes><Suspense fallback={<Loader />}><SdksLibraries /></Suspense></PrivateRoutes>} /> */}
          
          <Route path="/doc-introduction" element={<PrivateRoutes><Introduction /></PrivateRoutes>}/>
          <Route path="/doc-api-endpoints" element={<PrivateRoutes><ApiEndpoints /></PrivateRoutes>}/>
          <Route path="/doc-authentication" element={<PrivateRoutes><Authentication /></PrivateRoutes>}/>
          <Route path="/doc-formats" element={<PrivateRoutes><ApiReqResFormats /></PrivateRoutes>}/>
          <Route path="/doc-apiusage" element={<PrivateRoutes><ApiUsage /></PrivateRoutes>}/>
          <Route path="/doc-errorhandling" element={<PrivateRoutes><ApiErrorHandling /></PrivateRoutes>}/>
          <Route path="/doc-key-generation" element={<PrivateRoutes><ApiKeyGeneration /></PrivateRoutes>}/>
          <Route path="/doc-key-management" element={<PrivateRoutes><KeyManagement /></PrivateRoutes>}/>
          <Route path="/doc-key-security-guidelines" element={<PrivateRoutes><SecurityGuidelines /></PrivateRoutes>}/>
          <Route  path="/doc-sdks" element={<PrivateRoutes><SdksLibraries /></PrivateRoutes>}/>

          {/* Blockchain */}
          <Route path="/explorer" element={<PrivateRoutes><Suspense fallback={<Loader />}><BlockchainExplorer /></Suspense></PrivateRoutes>} />
          <Route path="/explorer-details" element={<PrivateRoutes><Suspense fallback={<Loader />}><BlockchainExplorerDetails /></Suspense></PrivateRoutes>} />

          {/* Footer */}
          <Route path="/privacy" element={<Suspense fallback={<Loader />}><Privacy /></Suspense>} />
          <Route path="/terms-and-conditions" element={<Suspense fallback={<Loader />}><TermsAndConditions /></Suspense>} />
 
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
