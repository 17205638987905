// let BASE_PROXY_URL = "http://35.173.227.195:82"; // development URL
export const BASE_PROXY_URL = "https://api.holografs.com"; // development URL

export const IMAGE_PROXY_URL = "https://api.holografs.com";

//Authentication
export const POST_OTP = `${BASE_PROXY_URL}/account/sendotp`;
export const POST_LOGIN = `${BASE_PROXY_URL}/account/login`;
export const POST_LOGOUT = `${BASE_PROXY_URL}/account/logout`;

//Profile 
export const PATCH_UPDATE_USER = `${BASE_PROXY_URL}/account/updateuser`;
export const POST_COMPANY_INFO = `${BASE_PROXY_URL}/account/addcompany`;
export const DELETE_PROFILE_IMG = `${BASE_PROXY_URL}/account/deleteprofileimage`;
export const GET_ALL_USERS = `${BASE_PROXY_URL}/account/allusers`;

//Single Product 
export const ADD_PRODUCT = `${BASE_PROXY_URL}/product/addproduct`;
export const GET_PRODUCT = `${BASE_PROXY_URL}/product/getproduct`;
export const GET_CATEGORY = `${BASE_PROXY_URL}/product/getcategory`;
export const GET_SINGLE_CATEGORY = `${BASE_PROXY_URL}/product/getcategory`;
export const GET_COLOR = `${BASE_PROXY_URL}/product/getcolor`;
export const ADD_PRODUCT_DETAILS = `${BASE_PROXY_URL}/product/addproductdetail`;
export const GET_PRODUCT_DETAILS = `${BASE_PROXY_URL}/product/getproductdetail`;
export const UPDATE_PRODUCT_DETAILS = `${BASE_PROXY_URL}/product/updateproductdetail`;
export const GET_QR = `${BASE_PROXY_URL}/product/getqrcode`;
export const UPLOAD_QR = `${BASE_PROXY_URL}/product/uploadqrcode`;
export const UPLOAD_PRODUCT_IMAGE = `${BASE_PROXY_URL}/product/addproductimage`;
export const UPDATE_PRODUCT = `${BASE_PROXY_URL}/product/updateproduct`;

//Tracking
export const GET_OWNER = `${BASE_PROXY_URL}/product/getowner`;
export const REQUEST_OWNERSHIP = `${BASE_PROXY_URL}/product/requistowner`;
export const GET_USER_DETAILS = `${BASE_PROXY_URL}/account/getuser`;
export const GET_ACTIVE_USER = `${BASE_PROXY_URL}/account/getuser`;
export const GET_ALL_REQUEST = `${BASE_PROXY_URL}/product/getallownerrequest`;
export const APPROVE_OWNERSHIP = `${BASE_PROXY_URL}/product/approveowner`;
export const GET_OWNERSHIP_STATUS = `${BASE_PROXY_URL}/product/getownerrequeststatus`;
export const GET_ALL_SENT_REQUEST = `${BASE_PROXY_URL}/product/getallsendownerrequest`;
export const POST_COUNTERFEIT_COMPLAIN =`${BASE_PROXY_URL}/inventory/complain`
export const GET_ALL_COUNTERFEIT_COMPLAIN =`${BASE_PROXY_URL}/inventory/complain`

//Tracking without token
export const GET_PRODUCT_DETAILS_ID =`${BASE_PROXY_URL}/product/getproductless`
export const GET_PRODUCT_DETAILS_QRCODE =`${BASE_PROXY_URL}/product/uploadqrcodeless`

//Packaging 
export const ADD_OUTSOURCE_PACKAGE = `${BASE_PROXY_URL}/logistics/outsource`;
export const CREATE_PACKAGE = `${BASE_PROXY_URL}/inventory/createpackage`;
export const GET_ALL_PACKAGE = `${BASE_PROXY_URL}/inventory/getpackage`;
export const GET_SINGLE_PACKAGE = `${BASE_PROXY_URL}/inventory/getpackage`;
export const ADD_ITEM_TO_PACKAGE = `${BASE_PROXY_URL}/inventory/additemtopackage`;
export const GET_ITEM_FROM_PACKAGE = `${BASE_PROXY_URL}/inventory/getpackagecontent`;
export const UPDATE_PACKAGE = `${BASE_PROXY_URL}/inventory/updatepackage`;

//Product inventory view
export const GET_ALL_PRODUCTS =`${BASE_PROXY_URL}/product/getproducts`
export const POST_ADD_ITEMS =`${BASE_PROXY_URL}/inventory/additem`
export const UPDATE_BATCH =`${BASE_PROXY_URL}/inventory/updatebatch`
export const POST_VERIFY_OWNER =`${BASE_PROXY_URL}/account/getemailuser`
export const POST_CHANGE_OWNER =`${BASE_PROXY_URL}/inventory/makeowner`
export const GET_ITEM_DETAILS =`${BASE_PROXY_URL}/inventory/getitem`
export const POST_UPLOAD_CSV_INVENTORY =`${BASE_PROXY_URL}/inventory/uploadcsvtostock`


//Inventory Ownership
export const GET_INVENTORY_OWNER = `${BASE_PROXY_URL}/inventory/getstockitemowner`;
export const GET_ALL_INVENTORY_SENT_REQUEST = `${BASE_PROXY_URL}/inventory/getallitemownerrequestsend`;
export const GET_ALL_INVENTORY_REQUEST = `${BASE_PROXY_URL}/inventory/getallitemownerrequest`;
export const APPROVE_INVENTORY_OWNERSHIP = `${BASE_PROXY_URL}/inventory/approveitemowner`;
export const GET_INVENTORY_OWNERSHIP_STATUS = `${BASE_PROXY_URL}/inventory/getitemownerrequeststatus`;
export const REQUEST_INVENTORY_OWNERSHIP = `${BASE_PROXY_URL}/inventory/RequistStockItemOwner`;

//Package Ownership
export const GET_PACKAGE_OWNER = `${BASE_PROXY_URL}/inventory/getpackageowner`;
export const GET_ALL_PACKAGE_SENT_REQUEST = `${BASE_PROXY_URL}/inventory/getallpackageownerrequestsend`;
export const GET_ALL_PACKAGE_REQUEST = `${BASE_PROXY_URL}/inventory/getallpackageownerrequistget`;
export const APPROVE_PACKAGE_OWNERSHIP = `${BASE_PROXY_URL}/inventory/approvepackageowner`;
export const GET_PACKAGE_OWNERSHIP_STATUS = `${BASE_PROXY_URL}/inventory/getpackageownerrequeststatus`;
export const REQUEST_PACKAGE_OWNERSHIP = `${BASE_PROXY_URL}/inventory/requistpackageowner`;

//Landing
export const POST_SEND_QUERY = `${BASE_PROXY_URL}/account/adduserquery`;
export const GET_QUERY = `${BASE_PROXY_URL}/account/getuserquery`;

// Warehouse
export const ADD_WAREHOUSE = `${BASE_PROXY_URL}/account/addwarehouse`;
export const GET_ALL_WAREHOUSE = `${BASE_PROXY_URL}/account/getwarehouse`;
export const GET_SINGLE_WAREHOUSE = `${BASE_PROXY_URL}/account/getwarehouse`;

// NextOwner
export const ADD_NEXTOWNER = `${BASE_PROXY_URL}/account/delivery`;
export const GET_ALL_NEXTOWNER = `${BASE_PROXY_URL}/account/delivery`;
export const GET_SINGLE_NEXTOWNER = `${BASE_PROXY_URL}/account/delivery`;
export const UPDATE_SINGLE_NEXTOWNER = `${BASE_PROXY_URL}/account/delivery`;


//Warranty
export const POST_ADD_WARRANTY_PARTNERS = `${BASE_PROXY_URL}/product/addwarrantypartner`;
export const GET_ALL_WARRANTY_PARTNERS = `${BASE_PROXY_URL}/product/getwarrantypartners`;
export const POST_UPLOAD_CSV_WARRANTY = `${BASE_PROXY_URL}/product/warrantypartneruploadcsv`;

// Metrices
export const POST_MATRIX = `${BASE_PROXY_URL}/product/matrix`;
export const GET_MATRIX = `${BASE_PROXY_URL}/product/matrix`;

// Support
export const SEND_MESSAGE_FROM_CUSTOMER_TO_ADMIN = `${BASE_PROXY_URL}/support/message`;
export const GET_ALL_CUSTOMER_MESSAGES = `${BASE_PROXY_URL}/support/message`;
export const SEND_MESSAGE_FROM_ADMIN_TO_CUSTOMER = `${BASE_PROXY_URL}/support/adminmessage`;
export const GET_ALL_ADMIN_MESSAGES = `${BASE_PROXY_URL}/support/adminmessage`;
export const GET_SINGLE_CUSTOMER_ADMIN_MESSAGES = `${BASE_PROXY_URL}/support/adminmessage`;
export const CREATE_SUPPORT_TICKET = `${BASE_PROXY_URL}/support/ticket`;
export const GET_ALL_SUPPORT_TICKET = `${BASE_PROXY_URL}/support/ticket`;
export const GET_SINGLE_SUPPORT_TICKET_BY_ID = `${BASE_PROXY_URL}/support/ticket`;

//Transportation
export const ADD_OUTSOURCE_TRANSPORTATION = `${BASE_PROXY_URL}/logistics/outsource`;
export const GET_OUTSOURCE_TRANSPORTATION = `${BASE_PROXY_URL}/logistics/outsource`;
export const ADD_TRANSPORTATION_DETAILS = `${BASE_PROXY_URL}/logistics/transport`;
export const GET_TRANSPORTATION_DETAILS = `${BASE_PROXY_URL}/logistics/transport`;




//Subscription
export const ADD_SUBSCRIPTION = `${BASE_PROXY_URL}/account/subscriber`;

//New User
export const ADD_NEWUSER = `${BASE_PROXY_URL}/account/newuser`;
export const GET_NEWUSER = `${BASE_PROXY_URL}/account/newuser`;
export const REMOVE_NEWUSER = `${BASE_PROXY_URL}/account/newuser`;
export const PATCH_NEWUSER = `${BASE_PROXY_URL}/account/newuser`;

//explorer
export const GET_ALL_UPSTREAM = `${BASE_PROXY_URL}/blockchain/allstreams`;
export const SEARCH_SINGLE_UPSTREAM = `${BASE_PROXY_URL}/blockchain/search`;