export const SEND_MSG_TO_ADMIN_SUCCESS = "support/SEND_MSG_TO_ADMIN_SUCCESS";
export const SEND_MSG_TO_ADMIN_FAILURE = "support/SEND_MSG_TO_ADMIN_FAILURE";
export const ALL_MSG_FROM_CUSTOMER_SUCCESS = "support/ALL_MSG_FROM_CUSTOMER_SUCCESS";
export const ALL_MSG_FROM_CUSTOMER_FAILURE = "support/ALL_MSG_FROM_CUSTOMER_FAILURE";
export const SEND_MSG_TO_CUSTOMER_SUCCESS = "support/SEND_MSG_TO_CUSTOMER_SUCCESS";
export const SEND_MSG_TO_CUSTOMER_FAILURE = "support/SEND_MSG_TO_CUSTOMER_FAILURE";
export const ALL_MSG_FROM_ADMIN_SUCCESS = "support/ALL_MSG_FROM_ADMIN_SUCCESS";
export const ALL_MSG_FROM_ADMIN_FAILURE = "support/ALL_MSG_FROM_ADMIN_FAILURE";
export const CREATE_TICKET_SUCCESS = "support/CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAILURE = "support/CREATE_TICKET_FAILURE";
export const GET_ALL_TICKET_SUCCESS = "support/GET_ALL_TICKET_SUCCESS";
export const GET_ALL_TICKET_FAILURE = "support/GET_ALL_TICKET_FAILURE";





