import {
  ADD_WARRANTY_PARTNERS_FAILURE,
  ADD_WARRANTY_PARTNERS_SUCCESS,
  ALL_WARRANTY_PARTNERS_FAILURE,
  ALL_WARRANTY_PARTNERS_SUCCESS,
} from "./warranty.actionTypes";

const initialState = {
  warrantyPartner: null,
  allWarrantyPartner: null
};

export default function warrantyReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ADD_WARRANTY_PARTNERS_SUCCESS:
      return {
        ...state,
        warrantyPartner: payload,
      };
    case ADD_WARRANTY_PARTNERS_FAILURE:
      return state;

    case ALL_WARRANTY_PARTNERS_SUCCESS:
      return {
        ...state,
        allWarrantyPartner: payload,
      };
    case ALL_WARRANTY_PARTNERS_FAILURE:
      return state;

    default:
      return state;
  }
}
