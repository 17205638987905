// Function to format date
export function formatDate(dateTimeString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateTimeString).toLocaleDateString(undefined, options);  
    return date ;
   
  }

// Function to format hour
  export function formatHour(dateTimeString) { 
    const timeOptions = { hour: '2-digit' }; 
    const hour = new Date(dateTimeString).toLocaleTimeString(undefined, timeOptions);
    return hour ;  
  }

// Function to format minute
  export function formatMinute(dateTimeString) { 
    const timeOptions = { minute: '2-digit' }; 
    const minute = new Date(dateTimeString).toLocaleTimeString(undefined, timeOptions);
    return minute;  
  }

// Function to format second
  export function formatSecond(dateTimeString) { 
    const timeOptions = { second: '2-digit' }; 
    const second = new Date(dateTimeString).toLocaleTimeString(undefined, timeOptions);
    return second ;  
  }

   // Function to convert coordinates
 export default function convertCoordinates(coordinates){
    const { lat, lng } = coordinates;
    const formattedCoordinates = `${lat}&${lng}`;
    return formattedCoordinates;
  };

  //Function to extract month
 export const getMonthFromDateString = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("en-US", { month: "short" });
    return month.slice(0, 3).toUpperCase();
  };

  //Function to convert date into YYYY-MM-DD format
  export function formatDateString(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

// Function to convert string("Thu Oct 12 2023 00:00:00 GMT+0530 (India Standard Time)") to date

export const extractDate=(input) =>{
  const dateRegExp = /(\d{2})\/(\d{2})\/(\d{2})/;
  const match = input.match(dateRegExp);

  if (match && match.length === 4) {
    const day = match[1];
    const month = match[2];
    const year = match[3];
    return `${day}/${month}/${year}`;
  } else {
    return null; // Return null if the input string does not contain a valid date in the DD/MM/YY format.
  }
}


export const ConvertStringToDate = (dateString) => {
  let date = new Date(dateString);

  // Extract the year, month, and day from the Date object
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // Months are zero-based, so add 1
  let day = date.getDate();

  // Create a formatted date string
  let formattedDate =
    year +
    "-" +
    (month < 10 ? "0" : "") +
    month +
    "-" +
    (day < 10 ? "0" : "") +
    day;

return formattedDate;
};
 
// Get year difference
export const calculateRemainingYears = (endDate) => {
  const currentDate = new Date(); 
  const endWarrantyDate = new Date(endDate); 

  if (endWarrantyDate > currentDate) {
    const timeDifference = endWarrantyDate - currentDate;

    const millisecondsInDay = 24 * 60 * 60 * 1000;
    const remainingDays = Math.floor(timeDifference / millisecondsInDay);

    const years = Math.floor(remainingDays / 365);
    const months = Math.floor((remainingDays % 365) / 30); 
    const days = remainingDays % 30;

    return years;
  } else {
    return 0 
  }
};

// Get month difference

export const calculateRemainingMonths = (endDate) => {
  const currentDate = new Date(); 
  const endWarrantyDate = new Date(endDate); 

  if (endWarrantyDate > currentDate) {
    const timeDifference = endWarrantyDate - currentDate;

    const millisecondsInDay = 24 * 60 * 60 * 1000;
    const remainingDays = Math.floor(timeDifference / millisecondsInDay);

    const years = Math.floor(remainingDays / 365);
    const months = Math.floor((remainingDays % 365) / 30); 
    const days = remainingDays % 30;

    return months;
  } else {
    return 0 
  }
};

//// Get days difference

// export const calculateRemainingDays = (endDate) => {
//   const currentDate = new Date();
//   const endWarrantyDate = new Date(endDate); 

//   if (endWarrantyDate > currentDate) {
//     const timeDifference = endWarrantyDate - currentDate;

//     const millisecondsInDay = 24 * 60 * 60 * 1000;
//     const remainingDays = Math.floor(timeDifference / millisecondsInDay);

//     const years = Math.floor(remainingDays / 365);
//     const months = Math.floor((remainingDays % 365) / 30);
//     const days = remainingDays % 30;

//     return days;
//   } else {
//     return 0 
//   }
// };

export const calculateRemainingDays = (endDate) => {
  const currentDate = new Date();
  const endWarrantyDate = new Date(endDate);

  // Set time components to 00:00:00 for both dates
  currentDate.setHours(0, 0, 0, 0);
  endWarrantyDate.setHours(0, 0, 0, 0);

  if (endWarrantyDate > currentDate || (endWarrantyDate.getTime() === currentDate.getTime() && endWarrantyDate.getHours() >= currentDate.getHours())) {
    const timeDifference = endWarrantyDate - currentDate;

    const millisecondsInDay = 24 * 60 * 60 * 1000;
    const remainingDays = Math.floor(timeDifference / millisecondsInDay);

    // If endWarrantyDate is the same as currentDate or has later hours, return 1
    if (remainingDays === 0) {
      return 1;
    }

    const days = remainingDays % 30;

    return days;
  } else {
    return 0;
  }
};

export const NumberToKFormat = ({ number }) => {
  const convertToK = (num) => {
    return num >= 1000 ? (num / 1000).toFixed(1) + 'k' : num;
  };

  return <span>{convertToK(number)}</span>;
};

export const DateFormattiing = (dateString) => {
  let date = new Date(dateString);

  // Extract the year, month, and day from the Date object
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // Months are zero-based, so add 1
  let day = date.getDate();

  // Create a formatted date string
  let formattedDate =
    year +
    "-" +
    (month < 10 ? "0" : "") +
    month +
    "-" +
    (day < 10 ? "0" : "") +
    day;
  return formattedDate;
};

export const truncateAddress =(address)=> {
  if (address.length <= 20) {
    return address;
  }

  const truncatedAddress = `${address.substring(0, 7)}...${address.slice(-21)}`;
  return truncatedAddress;
}
  
//Function to convert image size from kb to mb
export const convertbytesToKb = (kb) => {
  const mb = kb / 1024;
  return mb.toFixed(2); // Round to 2 decimal places
};







  
  