import React, { useEffect, useState } from "react";
import userDp from "../assets/images/user-dp.png";
import iconSearch from "../assets/images/icons/icon-search.svg";
import iconCog from "../assets/images/icons/icon-cog.svg";
import iconNotify from "../assets/images/icons/icon-notify.svg";
import iconLogout from "../assets/images/icons/icon-logout.svg";
import arrowDashboard from "../assets/images/icons/icon-arrow-dashboard.svg";
import filterDark from "../assets/images/icons/icon-filter-dark.svg";
import calendar from "../assets/images/icons/icon-calendar.svg";
import userLocation from "../assets/images/icons/icon-user-location.svg";
import arrowDarkVeryLong from "../assets/images/icons/icon-arrow-dark-very-long.svg";
import singleProduct from "../assets/images/icons/icon-single-product.svg";
import explorer from "../assets/images/icons/header-circle-gradient.svg"
import multipleProduct from "../assets/images/icons/icon-multiple-product.svg";
import { getUserBasicInformation } from "../Redux/Profile/profile.action";

import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import {
  getAllInventoryRequests,
  handleInventoryApproveRequest,
} from "../Redux/InventoryOwnership/InventoryOwnership.action";
import {
  getAllPackageRequests,
  handleDisplayDataChange,
  handlePackageApproveRequest,
} from "../Redux/PackageOwnership/PackageOwnership.action";
import { IMAGE_PROXY_URL, POST_LOGOUT } from "../services/Api";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import SuccessToast from "../reusable_components/SuccessToast";

export default function Navbar() {
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [selectedData, setSelectedData] = useState("Inventory");
  const [notificationCount, setNotificationCount] = useState(0);
  const [image, setImage] = useState(null);
  const [firstLetter, setFirstLetter] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state);
  const authState = useSelector((store) => store.authManager);
  const fullName = profileData?.profileManager?.fullname;
  const profileImage = profileData?.profileManager?.profile_image;
  const InventoryownerShipState = useSelector(
    (store) => store.inventoryOwnerShipManager
  );

  const userDetail = profileData?.profileManager;
  const PackageownerShipState = useSelector(
    (store) => store.packageOwnershipManager
  );

  const AllReceivedInventoryRequestArray =
    InventoryownerShipState?.AllReceivedRequestArray;

  const AllReceivedPackageRequestArray =
    PackageownerShipState?.AllReceivedRequestArray;

  const handleDataChange = (event) => {
    setSelectedData(event.target.value);
    dispatch(handleDisplayDataChange(event.target.value));
  };

  useEffect(() => {

  let reqestedInventories = AllReceivedInventoryRequestArray?.filter((item)=>item?.status == 'request');
  let reqestedPackages = AllReceivedPackageRequestArray?.filter((item)=>item?.status == 'request');

    setNotificationCount(reqestedInventories?.length + reqestedPackages?.length);
  }, [AllReceivedInventoryRequestArray, AllReceivedPackageRequestArray]);

  useEffect(() => {
    getUserDetails();
    dispatch(getAllInventoryRequests());
    dispatch(getAllPackageRequests());
  }, [isNotificationVisible]);

  useEffect(() => {
    if (fullName) {
      const getWords = fullName.split(" ");
      let firstLT = getWords?.map((word) => word.charAt(0)).join("");
      setFirstLetter(firstLT);
    }
  }, [fullName]);

  const getUserDetails = () => {
    dispatch(getUserBasicInformation());
  };

  const toggleNotification = () => {
    setIsNotificationVisible(!isNotificationVisible);
  };

  const handleChange = () => {
    navigate("/profile");
  };
  useEffect(() => {
    if (profileImage) {
      setImage(`${IMAGE_PROXY_URL}${profileImage}`);
    }
  }, [profileImage]);

  const handleLogout = async () => {
    toast(<SuccessToast content={'Logged Out Successfully'} />, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
   
    localStorage.removeItem("token");
    navigate("/");
  };
  
  return (
    <>
      <header className="page-header">
        <div className="page-header__user-info">
          <div className="rounded-circle user-img">
            {profileImage ? (
              <span className="h-100 w-100 rounded-circle ">
                <img
                  className="rounded-circle w-100 h-100 object-fit-cover"
                  src={image}
                  alt="User Image - Holograf"
                />
              </span>
            ) : (
              <div className="fz30 text-uppercase rounded-circle font-tertiary img__dp">
                <b className="gradientText">{firstLetter}</b>
              </div>
            )}
          </div>
          <div className="user-detail">
            <div className="user-detail__name">
              <div className="fz30 fw600">{userDetail?.fullname}</div>
              <div className="dp-points">
                <div className="position-relative font-secondary fz20 fw400 num">
                  <span className="gradientText--radial">300</span>
                </div>
                <span className="fz14 fw500 text-uppercase">points</span>
              </div>
            </div>
            <div className="fz20 lh1-2 grayText dp-email">
              {userDetail?.email}
            </div>
          </div>
        </div>
        <div className="page-header__other-options">
          {/* <div className="position-relative form-group form-group--search">
            <button className="position-absolute icon-wrap">
              <img src={iconSearch} alt="" />
            </button>
            <input
              type="text"
              className="fz14 border-white-2 form-control"
              placeholder="Search anything..."
            />
          </div> */}
           <button onClick={()=>navigate('/explorer')} className="btn btn-fab btn-fab--black">
            <img src={explorer} alt="" />
          </button>
          <button
            onClick={() => navigate("/profile")}
            className="btn btn-fab btn-fab--green"
          >
            <img src={iconCog} alt="" />
          </button>
          <div className="position-relative custom-dd">
            <button
              id=""
              onClick={toggleNotification}
              className="btn btn-fab btn-fab--blue d-none d-md-inline-flex"
            >
              <img src={iconNotify} alt="" />
              {notificationCount !== 0 ? (
                <span className="rounded-circle numberNotification">
                  <b className="fw700 gradientText">{notificationCount}</b>
                </span>
              ) : (
                <></>
              )}
            </button>
            {isNotificationVisible && (
              <div className="notification-block">
                <div className="border-white-2 bdrs10 main-shadow">
                  <div className="block-wavy-dark">
                    <h2 className="m-0 fw600 fz16">
                      <span className="gradientText">Notifications</span>
                    </h2>
                    <Link
                      to="/notification"
                      className="text-white fw400 fz12 ms-auto"
                    >
                      <img src={arrowDashboard} alt="" />
                      <span>View all</span>
                    </Link>
                  </div>

                  <div className="notification-block__toggle">
                    <div className="switches-container">
                      <input
                        type="radio"
                        id="switchPackage"
                        name="switchPlan"
                        value="Package"
                        checked={selectedData === "Package"}
                        onChange={handleDataChange}
                      />
                      <input
                        type="radio"
                        id="switchInventory"
                        name="switchPlan"
                        value="Inventory"
                        checked={selectedData === "Inventory"}
                        onChange={handleDataChange}
                      />
                      <label for="switchPackage" className="ts02 fw600 fz14">
                        Package Requests
                      </label>
                      <label for="switchInventory" className="ts02 fw600 fz14">
                        Inventory Requests
                      </label>
                      <div className="switch-wrapper">
                        <div className="fz14 fw600 switch">
                          <div>Package Requests</div>
                          <div>Inventory Requests</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="top-row">
                    <div className="top-row__left">
                      <h3 className="mb-0 fz16">Alert On</h3>
                      <p className="mb-0 fst-italic fw300 grayText fz12">
                        Updated 03hrs:23min ago.
                      </p>
                    </div>
                    <div className="btn-top-group top-row__right">
                      <div className="dropdown">
                        <button
                          className="border-0 btn dropdown-toggle btn-fab btn-fab--blue"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={filterDark} alt="" />
                        </button>
                        <div className="w-100 dropdown-menu dropdown-menu-end border-white-2 dropdown-menu-holograf">
                          <ul className="list-inline mb-0 filter-list">
                            <li>
                              <a href="#" className="table-pair">
                                <img src={singleProduct} alt="" />
                                <div className="text">
                                  <h4 className="mb-0 fz14 fw500">Products</h4>
                                  <span className="fw300 lh-1 grayText fst-italic fz10">
                                    Total products: 300k
                                  </span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="table-pair">
                                <img src={multipleProduct} alt="" />
                                <div className="text">
                                  <h4 className="mb-0 fz14 fw500">Groups</h4>
                                  <span className="fw300 lh-1 grayText fst-italic fz10">
                                    Total products: 300k
                                  </span>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <button
                        id="datepicker-btn-index-01"
                        className="btn btn-fab btn-fab--pink"
                        type="button"
                      >
                        <img src={calendar} alt="" />
                      </button>
                      <div
                        id="datepicker-container-index-01"
                        className="wrapper_datepicker"
                      ></div>
                    </div>
                  </div>
                  <div className="limit-height">
                    {selectedData === "Inventory" ? (
                      <ul className="list-inline">
                        {AllReceivedInventoryRequestArray &&
                          AllReceivedInventoryRequestArray?.map(
                            (user, index) => {
                              if (user.status == "request") {
                                return (
                                  <>
                                    <li key={index} className="fz10 stamp">
                                      <div className="fst-italic fw400 stamp__time">
                                        {user?.request_date}
                                      </div>
                                      <div className="stamp__status">
                                        <span className="fw500 gradientText">
                                          {" "}
                                          Active Requests{" "}
                                        </span>
                                      </div>
                                    </li>
                                    <li className="main-shadow bdrs10 border-white-2 n-card">
                                      <div className="n-card__top">
                                        <div className="title">
                                          <div className="btn btn-fab btn-fab--gray">
                                            <img src={userLocation} alt="" />
                                          </div>
                                          <h3 className="fw600 fz14 mb-0">
                                            <div>
                                              Ownership Request Received
                                            </div>
                                            <img
                                              src={arrowDarkVeryLong}
                                              alt=""
                                            />
                                          </h3>
                                        </div>
                                        <div className="activities-content">
                                          <div className="detail-cards">
                                            <div className="card-inner">
                                              <div className="fz12 card__text">
                                                <div className="text-pair">
                                                  <div className="label">
                                                    Product Id:
                                                  </div>
                                                  <div className="fw600 value">
                                                    {
                                                      user?.stock_item
                                                        ?.display_id
                                                    }
                                                  </div>
                                                </div>
                                                <div className="text-pair">
                                                  <div className="label">
                                                    Brand Name:
                                                  </div>
                                                  <div className="fw600 value">
                                                    <div className="brnd_btn_black">
                                                      <span>
                                                        {
                                                          user?.stock_item
                                                            ?.product?.brand
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="text-pair">
                                                  <div className="label">
                                                    Status:
                                                  </div>
                                                  <div className="fw600 value">
                                                    <span className="td-status td-status--active">
                                                      <span className="fz12 fw400">
                                                        {
                                                          user?.new_item_owner
                                                            ?.email
                                                        }
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="n-card__bottom">
                                        <button
                                          onClick={() =>
                                            dispatch(
                                              handlePackageApproveRequest(
                                                user?.id
                                              )
                                            )
                                          }
                                          className="fw500 fz14 btn"
                                        >
                                          <span className="gradientText">
                                            Accept Request
                                          </span>
                                        </button>
                                        <button className="fw400 fz14 btn">
                                          <span className="text-white">
                                            Reject Request
                                          </span>
                                        </button>
                                      </div>
                                    </li>
                                  </>
                                );
                              } else if (user.status == "approved") {
                                return (
                                  <>
                                    <li className="fz10 stamp">
                                      <div className="fst-italic fw400 stamp__time">
                                        {user?.request_date}
                                      </div>
                                      <div className="stamp__status">
                                        <span className="fw500 gradientText">
                                          Accepted Request
                                        </span>
                                      </div>
                                    </li>
                                    <li className="main-shadow bdrs10 border-white-2 n-card">
                                      <div className="fz10 fw500 tag tag--accepted">
                                        Accepted
                                      </div>
                                      <div className="n-card__top">
                                        <div className="title">
                                          <div className="btn btn-fab btn-fab--gray">
                                            <img src={userLocation} alt="" />
                                          </div>
                                          <h3 className="fw600 fz14 mb-0">
                                            <div>
                                              Ownership Request Accepted
                                            </div>
                                            <img
                                              src={arrowDarkVeryLong}
                                              alt=""
                                            />
                                          </h3>
                                        </div>
                                        <div className="activities-content">
                                          <div className="detail-cards">
                                            <div className="card-inner">
                                              <div className="fz12 card__text">
                                                <div className="text-pair">
                                                  <div className="label">
                                                    Product Id:
                                                  </div>
                                                  <div className="fw600 value">
                                                    {
                                                      user?.stock_item
                                                        ?.display_id
                                                    }
                                                  </div>
                                                </div>
                                                <div className="text-pair">
                                                  <div className="label">
                                                    Brand Name:
                                                  </div>
                                                  <div className="fw600 value">
                                                    <div className="brnd_btn_black">
                                                      <span>
                                                        {
                                                          user?.stock_item
                                                            ?.product?.brand
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="text-pair">
                                                  <div className="label">
                                                    Status:
                                                  </div>
                                                  <div className="fw600 value">
                                                    <span className="td-status td-status--active">
                                                      <span className="fz12 fw400">
                                                        {
                                                          user?.new_item_owner
                                                            ?.email
                                                        }
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                );
                              }
                            }
                          )}
                      </ul>
                    ) : (
                      <ul className="list-inline">
                        {AllReceivedPackageRequestArray &&
                          AllReceivedPackageRequestArray?.map((user, index) => {
                            if (user.status == "request") {
                              return (
                                <>
                                  <li key={index} className="fz10 stamp">
                                    <div className="fst-italic fw400 stamp__time">
                                      {user?.request_date}
                                    </div>
                                    <div className="stamp__status">
                                      <span className="fw500 gradientText">
                                        {" "}
                                        Active Requests{" "}
                                      </span>
                                    </div>
                                  </li>
                                  <li className="main-shadow bdrs10 border-white-2 n-card">
                                    <div className="n-card__top">
                                      <div className="title">
                                        <div className="btn btn-fab btn-fab--gray">
                                          <img src={userLocation} alt="" />
                                        </div>
                                        <h3 className="fw600 fz14 mb-0">
                                          <div>Ownership Request Received</div>
                                          <img src={arrowDarkVeryLong} alt="" />
                                        </h3>
                                      </div>
                                      <div className="activities-content">
                                        <div className="detail-cards">
                                          <div className="card-inner">
                                            <div className="fz12 card__text">
                                              <div className="text-pair">
                                                <div className="label">
                                                  Product Id:
                                                </div>
                                                <div className="fw600 value">
                                                  {user?.package?.display_id}
                                                </div>
                                              </div>
                                              <div className="text-pair">
                                                <div className="label">
                                                  Brand Name:
                                                </div>
                                                <div className="fw600 value">
                                                  <div className="brnd_btn_black">
                                                    <span>
                                                      {
                                                        user?.package
                                                          ?.brand_name
                                                      }
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="text-pair">
                                                <div className="label">
                                                  Status:
                                                </div>
                                                <div className="fw600 value">
                                                  <span className="td-status td-status--active">
                                                    <span className="fz12 fw400">
                                                      {
                                                        user?.new_package_owner
                                                          ?.email
                                                      }
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="n-card__bottom">
                                      <button
                                        onClick={() =>
                                          dispatch(
                                            handlePackageApproveRequest(
                                              user?.id
                                            )
                                          )
                                        }
                                        className="fw500 fz14 btn"
                                      >
                                        <span className="gradientText">
                                          Accept Request
                                        </span>
                                      </button>
                                      <button className="fw400 fz14 btn">
                                        <span className="text-white">
                                          Reject Request
                                        </span>
                                      </button>
                                    </div>
                                  </li>
                                </>
                              );
                            } else if (user.status == "approved") {
                              return (
                                <>
                                  <li className="fz10 stamp">
                                    <div className="fst-italic fw400 stamp__time">
                                      {user?.request_date}
                                    </div>
                                    <div className="stamp__status">
                                      <span className="fw500 gradientText">
                                        Accepted Request
                                      </span>
                                    </div>
                                  </li>
                                  <li className="main-shadow bdrs10 border-white-2 n-card">
                                    <div className="fz10 fw500 tag tag--accepted">
                                      Accepted
                                    </div>
                                    <div className="n-card__top">
                                      <div className="title">
                                        <div className="btn btn-fab btn-fab--gray">
                                          <img src={userLocation} alt="" />
                                        </div>
                                        <h3 className="fw600 fz14 mb-0">
                                          <div>Ownership Request Accepted</div>
                                          <img src={arrowDarkVeryLong} alt="" />
                                        </h3>
                                      </div>
                                      <div className="activities-content">
                                        <div className="detail-cards">
                                          <div className="card-inner">
                                            <div className="fz12 card__text">
                                              <div className="text-pair">
                                                <div className="label">
                                                  Product Id:
                                                </div>
                                                <div className="fw600 value">
                                                  {user?.package?.display_id}
                                                </div>
                                              </div>
                                              <div className="text-pair">
                                                <div className="label">
                                                  Brand Name:
                                                </div>
                                                <div className="fw600 value">
                                                  <div className="brnd_btn_black">
                                                    <span>
                                                      {
                                                        user?.package
                                                          ?.brand_name
                                                      }
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="text-pair">
                                                <div className="label">
                                                  Status:
                                                </div>
                                                <div className="fw600 value">
                                                  <span className="td-status td-status--active">
                                                    <span className="fz12 fw400">
                                                      {
                                                        user?.new_package_owner
                                                          ?.email
                                                      }
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </>
                              );
                            }
                          })}
                      </ul>
                    )}

                   
                  </div>
                </div>
              </div>
            )}
          </div>

          <button className="btn btn-fab btn-fab--pink logout">
            <img src={iconLogout} alt="" onClick={handleLogout} />
          </button>
        </div>
      </header>
      <ToastContainer />
    </>
  );
}
