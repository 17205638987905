import React from "react";
import footerLogo from "../assets/images/landing/footer-logo.svg";
import social1 from "../assets/images/landing/social1.svg";
import social2 from "../assets/images/landing/social2.svg";
import social3 from "../assets/images/landing/social3.svg";
import social4 from "../assets/images/landing/social4.svg";
import upload from "../assets/images/landing/hero/upload.svg";
import { Link } from "react-router-dom";

const handleMailClick = () => {
  const email = "support@Holografs.com";
  const subject = "Subject of the email";
  const body = "Body of the email";

  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  window.location.href = mailtoLink;
};
export default function Footer({ selectQR, handleIdChange, handleSubmit, id }) {
  return (
    <footer className="footerSec" id="footer">
      <div className="container">
        <div className="footerSec__top">
          <div>
            <img className="footerSec__logo" src={footerLogo} alt="logo" />
          </div>

          <div className="d-none d-md-block">
            <div className="search-product">
              <div className="search-product__inner">
                {/* <div className="btn-fab">
                  <input type="file" accept="image/*" onChange={selectQR} />

                  <img src={upload} alt="upload" />
                </div> */}
                <input
                  type="text"
                  // placeholder="Search product by ID or upload QR.."
                  placeholder="Search product by ID"
                  value={id}
                  onChange={handleIdChange}
                />
                <button onClick={handleSubmit}>
                  <span className="hgt">Search</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <h3>
          A cutting-edge Blockchain
          <br className="d-lg-none" />
          As A Service
        </h3>
        <p>
          Holograf is a cutting-edge Blockchain as a Service (BaaS) and
          white-label solution that tackles the key issues of counterfeiting,
          theft, and pilferages in the luxury product supply chain. Through the
          utilization of blockchain technology and Zero Knowledge Proof
          integration, Holograf provides a transparent and secure platform for
          verifying product authenticity, tracking provenance, and enabling
          smart ownership.
        </p>

        <div className="d-block d-md-none">
          <div className="search-product">
            <div className="search-product__inner">
              <input type="text" placeholder="Search your product..." />
              <button onclick="javascript:location.href='./landing-search.html'">
                <span className="hgt">Search</span>
              </button>
            </div>
          </div>
        </div>

        <div className="footerSec__links-wrapper">
          <div className="footerSec__links bb">
            <h4>SITEMAP</h4>
            <ul>
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#about">About Holograf</a>
              </li>
              <li>
                <a href="#values">Unique Features</a>
              </li>
              <li>
                <a href="#why">Why Choose Us</a>
              </li>
              <li>
                <a href="#how">How it work?</a>
              </li>
            </ul>
          </div>
          <div className="footerSec__links">
            <h4>LEGAL</h4>
            <ul>
              <li>
                <Link to="#faq">FAQ</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </li>
              {/* <li><Link to="#">Cookies Policy</Link></li> */}
              {/* <li><Link to="#">License Agreement</Link></li> */}
            </ul>
          </div>
        </div>
        <div className="footerSec__bottom">
          <div>
            <span className="hgt">2023 copyright.</span>
          </div>

          <div className="footerSec__social">
            {/* <Link to="#"><img src={social1} /></Link> */}
            <a href="https://in.linkedin.com" target="_blank">
              <img src={social2} />
            </a>

            <a href="" onClick={handleMailClick}>
              <img src={social3} />
            </a>
            <a href="https://discord.com" target="_blank">
              <img src={social4} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
