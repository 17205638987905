import {GET_ALLUPSTREAMS_SUCCESS,GET_ALLUPSTREAMS_FAILURE,GET_SINGLEUPSTREAMS_FAILURE,GET_SINGLEUPSTREAMS_SUCCESS} from "./explorer.actionTypes"

const initialState = {
    TotalUpstreamsData: [], 
    SearchedHash:{}  
  };

export default function explorerReducer(
    state = initialState,
    { type, payload }
  ) {
    switch (type) {
      case GET_ALLUPSTREAMS_SUCCESS:
        return {
          ...state,
          TotalUpstreamsData:payload
         
        };

        case GET_ALLUPSTREAMS_FAILURE:
        return {
          ...state,
          TotalUpstreamsData:[]
         
        };
        case GET_SINGLEUPSTREAMS_SUCCESS:
            return {
              ...state,
              SearchedHash:payload
             
            };

        case GET_SINGLEUPSTREAMS_FAILURE:
            return {
              ...state,
              SearchedHash:{}
             
            };
  
     
        default:
        return state;
    }
  }