import axios from "axios";
import {
  GET_PACKAGE_OWNER,
  GET_ALL_PACKAGE_SENT_REQUEST,
  GET_ALL_PACKAGE_REQUEST,
  APPROVE_PACKAGE_OWNERSHIP,
  GET_PACKAGE_OWNERSHIP_STATUS,
  REQUEST_PACKAGE_OWNERSHIP,
  GET_USER_DETAILS,
} from "../../services/Api.js";


import {
  GET_PACKAGE_OWNER_SUCCESS,
  GET_PACKAGE_OWNER_FAILURE,
  REQUEST_PACKAGE_OWNERSHIP_SUCCESS,
  REQUEST_PACKAGE_OWNERSHIP_FAILURE,
  APPROVE_PACKAGE_OWNERSHIP_SUCCESS,
  APPROVE_PACKAGE_OWNERSHIP_FAILURE,
  GET_PACKAGE_OWNERSHIP_STATUS_SUCCESS,
  GET_PACKAGE_OWNERSHIP_STATUS_FAILURE,
  GET_PACKAGE_ALL_SENT_REQUEST_SUCCESS,
  GET_PACKAGE_ALL_SENT_REQUEST_FAILURE,
  GET_PACKAGE_OWNER_DETAILS_SUCCESS,
  GET_PACKAGE_OWNER_DETAILS_FAILURE,
  GET_PACKAGE_ALL_RECEIVED_REQUEST_SUCCESS,
  GET_PACKAGE_ALL_RECEIVED_REQUEST_FAILURE,
  DISPLAY_REQUEST_DATA_SUCCESS,
  DISPLAY_REQUEST_DATA_FAILURE,
  LOADER_TRUE,
  LOADER_FALSE
} from "./PackageOwnership.actionTypes.js";
import { toast } from "react-toastify";
import { GetSinglePackage } from "../Packaging/packaging.actions.js";
import ErrorToast from "../../reusable_components/ErrorToast.jsx";
import SuccessToast from "../../reusable_components/SuccessToast.jsx";


export const getPackageOwnerDetails = (id, from_date) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const userEmail = JSON.parse(localStorage.getItem("userEmail")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  dispatch({ type: LOADER_TRUE });
  try {
    
    let res = await axios.get(`${GET_USER_DETAILS}/${id}`, {
      headers,
    });
    let ownerDetails = res.data;
    console.log("Package ownerDetails", ownerDetails);
   


    dispatch({
      type: GET_PACKAGE_OWNER_DETAILS_SUCCESS,
      payload: {
        ownerDetails: { ...ownerDetails, from_date },
      },
    });

     dispatch({ type: LOADER_FALSE });
    // if (userEmail == ownerDetails?.email) {
    //   console.log("MATCHED");
    //   dispatch({
    //     type: GET_OWNER_DETAILS_SUCCESS,
    //     payload: {
    //       ownerDetails: { ...ownerDetails, from_date },
    //     },
    //   });
    // } else {
    //   console.log("NOT MATCHED");
    //   dispatch({
    //     type: GET_OWNER_DETAILS_SUCCESS,
    //     payload: {
    //       ownerDetails: { ...ownerDetails, from_date },
    //     },
    //   });
    // }
  } catch (err) {
    console.log({ EROOR: err });
    dispatch({
      type: GET_PACKAGE_OWNER_DETAILS_FAILURE,
    });
    dispatch({ type: LOADER_FALSE });
  }
};

export const checkForPackagePreviousRequest = (id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  dispatch({ type: LOADER_TRUE });
  try {
    let res = await axios.get(`${GET_ALL_PACKAGE_SENT_REQUEST}`, {
      headers,
    });

    let rqst_id_array = res?.data?.requests?.filter((el) => {
      if (el.package?.id == id) {
        let status = el?.status;
        if (status == "request") {
          dispatch({
            type: GET_PACKAGE_ALL_SENT_REQUEST_SUCCESS,
            payload: status,
          });
          dispatch({ type: LOADER_FALSE });
        }

      }
    });
    dispatch({
      type: GET_PACKAGE_ALL_SENT_REQUEST_SUCCESS,
      payload: "",
    });
     
     dispatch({ type: LOADER_FALSE });
  } catch (err) {
    console.log({ Error: err });
    dispatch({
      type: GET_PACKAGE_ALL_SENT_REQUEST_FAILURE,
      payload: "",
    });
   
    dispatch({ type: LOADER_FALSE });
  }
};

export const handleSearchPackageOwners = (id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const userEmail = JSON.parse(localStorage.getItem("userEmail")) || null;
  // dispatch(getQRCode(id, token));
  dispatch(checkForPackagePreviousRequest(id));
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let res = await axios.get(`${GET_PACKAGE_OWNER}/${id}`, {
      headers,
    });

    dispatch({
      type: GET_PACKAGE_OWNER_SUCCESS,
    });

    console.log("InverTory OWNERS", res?.data);
    //let ownerID = res?.data?.owners[0]?.owner;
    let ownerArr = res?.data?.owners;
    let ReverseOwnerArr = [...ownerArr]?.reverse();
    console.log("PACKAGE OWNER ARRAY", ReverseOwnerArr);

    ReverseOwnerArr &&
      ReverseOwnerArr?.forEach((el, i) => {
        let ownerID = el?.package_owner?.id;
        let from_date = el?.from_date;

        dispatch(getPackageOwnerDetails(ownerID, from_date));
      });

    dispatch(GetSinglePackage(id)); // GET Package ? Details
  } catch (err) {
    toast(<ErrorToast content={'Please enter a valid ID..!'} />, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
   
    console.log({ Error: err });
    dispatch({
      type: GET_PACKAGE_OWNER_FAILURE,
    });
  }
};

export const getPackageRequestStatus = (id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let res = await axios.get(`${GET_PACKAGE_OWNERSHIP_STATUS}/${id}`, {
      headers,
    });

    let status = res?.data?.status?.status;
    dispatch({
      type: GET_PACKAGE_OWNERSHIP_STATUS_SUCCESS,
      payload: status,
    });
  } catch (err) {
    console.log({ Error: err });
    dispatch({
      type: GET_PACKAGE_OWNERSHIP_STATUS_FAILURE,
    });
  }
};


export const getAllPackageRequests = () => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let res = await axios.get(`${GET_ALL_PACKAGE_REQUEST}`, { headers });

    
    const reqArray = res?.data?.requests;
    const ReverseReqArr = [...reqArray]?.reverse();
    dispatch({
      type: GET_PACKAGE_ALL_RECEIVED_REQUEST_SUCCESS,
      payload: ReverseReqArr,
    });
  } catch (err) {
    console.log({ Error: err });
    dispatch({
      type: GET_PACKAGE_ALL_RECEIVED_REQUEST_FAILURE,
    });
  }
};

export const handlePackageApproveRequest = (id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let res = await axios.post(
      `${APPROVE_PACKAGE_OWNERSHIP}`,
      { request_id: id },
      { headers }
    );
    if (res.data) {
      toast(<SuccessToast content={'Ownership Approved...!'} />, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
     

      dispatch({
        type: APPROVE_PACKAGE_OWNERSHIP_SUCCESS,
      });

      dispatch(getAllPackageRequests());
    }
  } catch (err) {
    console.log({ Error: err });
    dispatch({
      type: APPROVE_PACKAGE_OWNERSHIP_FAILURE,
    });
  }
};

export const handlePackageOwnershipRequest = (id) => async (dispatch) => {

  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let res = await axios.post(
      `${REQUEST_PACKAGE_OWNERSHIP}`,
      { package: id },
      { headers }
    );

    // localStorage.setItem("request_id", JSON.stringify(res.data.request_id));

    dispatch({
      type: REQUEST_PACKAGE_OWNERSHIP_SUCCESS,
      payload: res?.data?.request_id,
    });
    dispatch(getAllPackageRequests());
    dispatch(getPackageRequestStatus(res?.data?.request_id));
    dispatch(handleSearchPackageOwners(id));
  } catch (err) {
    console.log({ Error: err });
    dispatch({
      type: REQUEST_PACKAGE_OWNERSHIP_FAILURE,
    });
  }
};



export const handleDisplayDataChange = (value) =>  (dispatch) => {
    
  if(value){
    dispatch({
        type:DISPLAY_REQUEST_DATA_SUCCESS,
        payload:value
    })
  }
  else{
     dispatch({
       type: DISPLAY_REQUEST_DATA_FAILURE, 
     });
  }
};