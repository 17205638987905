import React, { useState } from "react";
import contactLayer0 from "../../assets/images/landing/contact/layer0.png";
import contactLayer1 from "../../assets/images/landing/contact/layer1.svg";
import contactLayer2 from "../../assets/images/landing/contact/layer2.png";
import { sendQuery } from "../../Redux/Landing/landing.action";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import SuccessToast from "../../reusable_components/SuccessToast";
import headerLogo from "../../assets/images/landing/truck/truck-right.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ErrorToast from "../../reusable_components/ErrorToast";
import { POST_SEND_QUERY } from "../../services/Api";

export default function ContactSection({ logo }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query, setQuery] = useState({ fullname: "", email: "", message: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuery({ ...query, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payLoad = {
      fullname: query.fullname,
      email: query.email,
      message: query.message,
    };
    if (payLoad) {
      // dispatch(sendQuery(payLoad));
      // toast(<SuccessToast content={'Query successfully sent'} />, {
      //   position: toast.POSITION.TOP_CENTER,
      //   autoClose: 3000,
      // });
      axios.post(POST_SEND_QUERY, payLoad).then(resp => {
        navigate("/thankyou");
        setQuery({ fullname: '', email: '', message: '' });
      }).catch(err => {
        toast(<ErrorToast content={'Please Try Again After Sometime !!!'} />, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        console.log(err);
      })
    }
  };
  return (
    <>
      <section className="contactSec m-0 p-0" id="chat">
        <div className="container">
          <div className="contactSec__flex" style={{ minHeight: "100vh" }}>
            <div className="contactSec__left">
              <div className="contactSec__head">
                <h2 className="head1 fade-in">Write to us!</h2>
                <p className="desc fade-in">
                  Secure Supply Chain with Holografs.
                </p>
              </div>
              <form>
                <div className="input-wrapper">
                  <div className="input-label">What should we call you?</div>
                  <input
                    type="text"
                    name="fullname"
                    placeholder="Full name"
                    value={query.fullname}
                    onChange={handleChange}
                  />
                </div>

                <div className="input-wrapper">
                  <div className="input-label">How to contact you?</div>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email address"
                    value={query.email}
                    onChange={handleChange}
                  />
                </div>

                <div className="input-wrapper">
                  <div className="input-label">What’s on your mind?</div>
                  <textarea
                    name="message"
                    placeholder="Tell us about your issues we will contact you as soon as possible."
                    value={query.message}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div className="text-center">
                  <button
                    className="button-dark"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    <span className="hgt">Send query</span>
                  </button>
                </div>
              </form>
            </div>
            <div className="contactSec__right h-100">
              <h2 className="head1 fade-in">
                <span className="hgt">24/7</span>
              </h2>
              <p className="desc2 fade-in">Revolutionizing Supply Chain Authenticity with Holografs</p>
              <p className="text-start fade-in">Counterfeit goods? Product pilferage? Fear no more! Holografs is your ultimate solution, harnessing the power of Blockchain and Zero Knowledge Proof to safeguard your supply chain integrity.</p>
              <p className="text-start fade-in m-0">Our cutting-edge BaaS platform offers:</p>
              <p className="text-start fade-in m-0">- Transparent product authenticity verification</p>
              <p className="text-start fade-in m-0">- Immutable tracking of product origin</p>
              <p className="text-start fade-in">- Smart ownership system for enhanced consumer trust</p>

              <p className="text-start fade-in">Experience the future of secure supply chains today. Submit your details now to explore Holografs' transformative capabilities.</p>

              {logo &&
                <div className="fade-in d-flex justify-content-center my-3 mt-5">
                  <img src={headerLogo} alt="logo" />
                </div>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
}