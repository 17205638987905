import React, { useState } from "react";
import leaf from "../../assets/images/landing/subscribe/leaf.svg";
import email from "../../assets/images/landing/subscribe/email.svg";
import deco1 from "../../assets/images/landing/subscribe/deco1.svg";
import layer0 from "../../assets/images/landing/subscribe/layer0.png";
import layer1 from "../../assets/images/landing/subscribe/layer1.png";
import infoLocation from "../../assets/images/landing/info/location.svg";
import infoUser from "../../assets/images/landing/info/user.svg";
import infoEmail from "../../assets/images/landing/info/email.svg";
import { ADD_SUBSCRIPTION } from "../../services/Api";
import axios from "axios";
import { toast } from "react-toastify";
import ErrorToast from "../../reusable_components/ErrorToast";
import SuccessToast from "../../reusable_components/SuccessToast";

export default function Info() {
  const [emailID,setEmailID] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);

 const subscriptionEmail = async (e) => {
   e.preventDefault();

   if(!emailID.trim()){
    toast(<ErrorToast content={'Please enter a valid email..!'} />, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
    
    return;
   }
  const payLoad = {'email':emailID}
   
    try {
      setButtonClicked(true);
      const resp = await axios.post(ADD_SUBSCRIPTION, payLoad);
      if (resp.data) {
        toast(<SuccessToast content={'You are successfully subscribed'} />, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        setEmailID('')
        setButtonClicked(false);
      } 
    } catch (error) {
      
    }
  };

  return (
    <>
      {/* <section className="queAnsSec" id="faq">
        <div className="container">
          <div className="section-title-wrapper">
            <h2 className="head1 fade-in">
              Let's answers your tricky questions!
            </h2>
            <p className="desc fade-in">
            These FAQs offer clear insights into Holografs, providing a brief yet comprehensive overview of our platform's advantages.
            </p>
          </div>

          <div
            className="accordion accordion-flush fade-in"
            id="accordionFlushExample"
          >
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What is Holografs primary focus?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                Holografs is dedicated to revolutionizing supply chain management through transparency, traceability, and security. Our platform empowers businesses to gain control over their supply chains and ensure the authenticity and integrity of their products.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  How does Holografs ensure data security?
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                At Holografs, data security is a top priority. We employ cutting-edge encryption and authentication measures to safeguard your data. Our platform is fortified with role-based access control and two-factor authentication for added protection.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  What sets Holografs apart from other supply chain management solutions?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                Holografs stands out with its comprehensive approach to supply chain management. We offer a wide range of features, from product tracking and inventory management to analytics and augmented intelligence, all in one user-friendly platform.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  How can I get started with Holografs?
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                Getting started is easy! Simply register, set up your profile, and explore our platform's features. Our user-friendly interface makes onboarding a breeze.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse5"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  Is Holografs suitable for businesses of all sizes?
                </button>
              </h2>
              <div
                id="flush-collapse5"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                Absolutely! Holografs is designed to cater to businesses of all sizes, from startups to large enterprises. Our platform scales with your needs, ensuring you have the tools required to manage your supply chain effectively.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse6"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  Can Holografs help with regulatory compliance?
                </button>
              </h2>
              <div
                id="flush-collapse6"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                Yes, Holografs assists businesses in maintaining regulatory compliance. Our platform maintains transparent records of product manufacturing, distribution, and authenticity, simplifying audits and inspections.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="subscribeSec">
        <div className="container">
          <div className="subscribeSec__inner">
            <div className="subscribeSec__content fade-in">
              <h2 className="head1">
                Let's connect to make things better
                <img className="d-inline-block" src={leaf} alt="..." />
              </h2>
              <p className="desc">
              Enter your email address below to start a conversation about improving your supply chain with Holografs.
              </p>
              <form>
                <div className="subscribeSec__emailWrapper">
                  <input
                    type="email"
                    required
                    placeholder="Enter you email to subscribe"
                    value={emailID}
                    onChange={(e)=>setEmailID(e.target.value)}
                  />
                  <button  onClick={subscriptionEmail} disabled={buttonClicked ? true : false} >
                    <img src={email} alt="..." />
                  </button>
                </div>
              </form>
            </div>
            <img className="subscribeSec__deco1" src={deco1} alt="" />
            <div className="subscribeSec__deco2">
              <img className="subscribeSec__layer0" src={layer0} alt="" />
              <img className="subscribeSec__layer1" src={layer1} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="infoSec">
        <div className="container fade-in">
          <div className="row">
            <div className="col">
              <div className="infoSec__single">
                <div>
                  <img src={infoLocation} alt="..." />
                </div>
                <div className="infoSec__title">FIND US</div>
                <div className="infoSec__text">
                  2nd floor plot no, 254, Phase IV, Udyog Vihar, Sector 18,
                  Gurugram
                </div>
              </div>
            </div>
            <div className="col">
              <div className="infoSec__single">
                <div>
                  <img src={infoUser} alt="..." />
                </div>
                <div className="infoSec__title">Contact US</div>
                <div className="infoSec__text">Call us : 1800 137 0011</div>
              </div>
            </div>
            <div className="col">
              <div className="infoSec__single">
                <div>
                  <img src={infoEmail} alt="..." />
                </div>
                <div className="infoSec__title">Email US</div>
                <div className="infoSec__text">support@Holografs.com</div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
         <section className="queAnsSec" id="faq">
          <div className="container">
            <div className="section-title-wrapper">
              <h2 className="head1 fade-in">
                Let's answers your tricky questions!
              </h2>
              {/* <p className="desc fade-in">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut.
              </p> */}
            </div>

            <div className="accordion accordion-flush fade-in" id="accordionFlushExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    What is the layer of Blockchain used in Holografs?
                  </button>
                </h2>
                <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Placeholder content for this accordion, which is intended
                    to demonstrate the <code>.accordion-flush</code> className.
                    This is the first item's accordion body.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    How does counterfeiting reported and prevented in Holografs?
                  </button>
                </h2>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Placeholder content for this accordion, which is intended
                    to demonstrate the <code>.accordion-flush</code> className.
                    This is the second item's accordion body. Let's imagine
                    this being filled with some actual content.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    How can we add multiple SKUs via batch upload?
                  </button>
                </h2>
                <div id="flush-collapseThree" className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Placeholder content for this accordion, which is intended
                    to demonstrate the <code>.accordion-flush</code> className.
                    This is the third item's accordion body. Nothing more
                    exciting happening here in terms of content, but just
                    filling up the space to make it look, at least at first
                    glance, a bit more representative of how this would look
                    in a real-world application.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                     How can we ensure the logistics happen smoothly?
                  </button>
                </h2>
                <div id="flush-collapseFour" className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Placeholder content for this accordion, which is intended
                    to demonstrate the <code>.accordion-flush</code> className.
                    This is the third item's accordion body. Nothing more
                    exciting happening here in terms of content, but just
                    filling up the space to make it look, at least at first
                    glance, a bit more representative of how this would look
                    in a real-world application.
                  </div>
                </div>
              </div>
              {/* <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapseFour">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut?
                  </button>
                </h2>
                <div id="flush-collapse5" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Placeholder content for this accordion, which is intended
                    to demonstrate the <code>.accordion-flush</code> className.
                    This is the third item's accordion body. Nothing more
                    exciting happening here in terms of content, but just
                    filling up the space to make it look, at least at first
                    glance, a bit more representative of how this would look
                    in a real-world application.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapseFour">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut?
                  </button>
                </h2>
                <div id="flush-collapse6" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Placeholder content for this accordion, which is intended
                    to demonstrate the <code>.accordion-flush</code> className.
                    This is the third item's accordion body. Nothing more
                    exciting happening here in terms of content, but just
                    filling up the space to make it look, at least at first
                    glance, a bit more representative of how this would look
                    in a real-world application.
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section className="subscribeSec">
          <div className="container">
            <div className="subscribeSec__inner">
              <div className="subscribeSec__content fade-in">
                <h2 className="head1">
                  Let's connect to make things better
                  <img className="d-inline-block" src={leaf} alt="..." />
                </h2>
                <p className="desc">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                  sed diam nonummy nibh euismod tincidunt ut.
                </p>
                <form>
                  <div className="subscribeSec__emailWrapper">
                  <input
                    type="email"
                    required
                    placeholder="Enter you email to subscribe"
                    value={emailID}
                    onChange={(e)=>setEmailID(e.target.value)}
                  />
                  <button  onClick={subscriptionEmail} disabled={buttonClicked ? true : false} >
                    <img src={email} alt="..." />
                  </button>
                  </div>
                </form>
              </div>
              <img className="subscribeSec__deco1" src={deco1} alt="" />
              <div className="subscribeSec__deco2">
                <img className="subscribeSec__layer0" src={layer0} alt="" />
                <img className="subscribeSec__layer1" src={layer1} alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="infoSec">
          <div className="container fade-in">
            <div className="row">
              <div className="col">
                <div className="infoSec__single">
                  <div>
                    <img src={infoLocation} alt="..." />
                  </div>
                  <div className="infoSec__title">FIND US</div>
                  <div className="infoSec__text">
                    2nd floor plot no, 254, Phase IV, Udyog Vihar, Sector 18,
                    Gurugram
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="infoSec__single">
                  <div>
                    <img src={infoUser} alt="..." />
                  </div>
                  <div className="infoSec__title">Contact US</div>
                  <div className="infoSec__text">Call us : 1800 137 0011</div>
                </div>
              </div>
              <div className="col">
                <div className="infoSec__single">
                  <div>
                    <img src={infoEmail} alt="..." />
                  </div>
                  <div className="infoSec__title">Email US</div>
                  <div className="infoSec__text">support@Holografs.com</div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  );
}
