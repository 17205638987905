import React, { useRef } from "react";
import apiDocHead from "../../../assets/images/icons/api-doc-head.svg";
import urlPaste from "../../../assets/images/icons/url-paste.svg";
import urlPasteBlack from "../../../assets/images/icons/url-paste-black.svg";
import bulb from "../../../assets/images/icons/icon-bulb-dark.svg";
import { Link } from "react-router-dom";
import PageList from "../../../reusable_components/pageList";
import Navbar from "../../../layouts/navbar";
import BottomNavbar from "../../../layouts/bottomNavbar";
import DocSidebar from "../docSidebar";

export default function ApiEndpoints() {
  const textAreaRef = useRef(null);

  const handleCopyClick = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
  };

  return (
    <>
      <div className="paye-layout">
        <PageList />
        <div className="content-layout">
          <Navbar />
          <section className="product-screen api-doc-screen">
            <DocSidebar />

            <div className="w-100 api-document bdrs10 border-white-2 m-0">
              <div className="api-doc-head d-flex align-items-center gap-2 gx-0">
                <img src={apiDocHead} alt="" />
                <span className="fw600 fz28 text-dark">API Endpoints</span>
              </div>

              <div className="endpoints-wrapper">
                {/* <!-- Accordion in Documentation --> */}
                <div className="accordion accordion--api" id="apiDocumentation">
                  {/* -----------------0------- */}
                  <div className="bdrs10 main-shadow accordion-item accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseZero"
                      aria-expanded="true"
                      aria-controls="collapseZero"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className=" fz16 fw500 gradientText--radial">
                          Add Product
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapseZero"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          " https://api.holografs.com/product/addproduct"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value=" https://api.holografs.com/product/addproduct"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/product/addproduct
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Post</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="black-box-inside mb-2">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <Link
                              to="#"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Fields</span>
                                </div>
                              </div>
                            </Link>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              fields ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">[</span>
                                <br />
                                'id', 'name', 'brand','sku', 'warranty',
                                'expire', 'location', 'category', 'color',
                                'image', 'doc'
                                <br />
                                <span className="pink-text">]</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="black-box-inside">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <Link
                              to="#"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Example</span>
                                </div>
                              </div>
                            </Link>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              example ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text"> {" {"}</span>
                                <br />
                                "name": "OnePlus","brand": "CE","sku":
                                "Bar","warranty": "2024-08-19","expire":
                                "2025-08-19","category": "1","color": "2"
                                <br />
                                <span className="pink-text">{"}"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* -----------------0------- */}

                  {/* -----------------1------- */}
                  <div className="bdrs10 main-shadow accordion-item accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className=" fz16 fw500 gradientText--radial">
                          Update Product
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/product/updateproduct/1"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/product/updateproduct/1"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/product/updateproduct/1
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">PATCH</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="black-box-inside">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <Link
                              to="#"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Example</span>
                                </div>
                              </div>
                            </Link>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              example ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <div className="orange-text">
                                  <span className="pink-text">{"{"}</span>
                                  <br />
                                  <span>
                                    {" "}
                                    name: "21",brand: "Apple", sku: "baz",
                                  </span>
                                  <br />
                                  <span className="pink-text"> {"}"}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 1--------*/}
                  {/* ----------------- 2--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To Upload Image of Product
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/product/addproductimage"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/product/addproductimage"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/product/addproductimage
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">POST</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="black-box-inside mb-2">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Fields</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              fields ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">[</span>
                                <br />
                                'product', 'image'
                                <br />
                                <span className="pink-text">]</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 2--------*/}
                  {/* ----------------- 3--------*/}
                  {/* <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To View Product with Inventry Items
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img src={urlPaste} alt="" />
                                  </button>
                                  <p className="grayText m-0">
                                    https://api.holografs.com/product/updateproduct/1
                                  </p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img src={urlPaste} alt="" />
                                  </button>
                                  <p className="grayText m-0">
                                    https://api.holografs.com/product/updateproduct/1
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* ----------------- 3--------*/}
                  {/* ----------------- 4--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="true"
                      aria-controls="collapseFour"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          Add Product Details
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/product/addproductdetail"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/product/addproductdetail"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/product/addproductdetail
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">POST</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="black-box-inside mb-2">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Example</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              example ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text"> {"{"}</span>
                                <br />
                                "product": 1, "detail": "foo bar baz"
                                <br />
                                <span className="pink-text"> {" }"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 4--------*/}
                  {/* ----------------- 5--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="true"
                      aria-controls="collapseFive"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To Send Request for Ownership
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/product/requistowner"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/product/requistowner"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/product/requistowner
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">POST</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="black-box-inside mb-2">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Fields</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              fields ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">[</span> <br />
                                "product"
                                <br />
                                <span className="pink-text">]</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="black-box-inside">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Example</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              example ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">{"{"}</span> <br />
                                "product": "38"
                                <br /> <span className="pink-text">{"}"}</span>
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 5--------*/}
                  {/* ----------------- 6--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="true"
                      aria-controls="collapseSix"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To Check all requist for ownership
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/product/getallownerrequest"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/product/getallownerrequest"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/product/getallownerrequest
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Get</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 6--------*/}
                  {/* ----------------- 7--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="true"
                      aria-controls="collapseSeven"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To check Status of ownership request
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      s
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/product/getownerrequeststatus/3"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/product/getownerrequeststatus/3"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/product/getownerrequeststatus/3
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Get</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 7--------*/}
                  {/* ----------------- 8--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="true"
                      aria-controls="collapseEight"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To Approve Ownership request
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/product/approveowner"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/product/approveowner"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/product/approveowner
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Post</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="black-box-inside mb-2">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Fields</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              fields ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">[</span> <br />
                                'request_id'
                                <br />
                                <span className="pink-text">]</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="black-box-inside">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Example</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              example ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">{"{"}</span>
                                <br />
                                "request_id": "2"
                                <br />
                                <span className="pink-text">{"}"}</span>
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 8--------*/}
                  {/* ----------------- 9--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="true"
                      aria-controls="collapseNine"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To add Item to Inventory
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapseNine"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          " https://api.holografs.com/inventory/additem"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/inventory/additem"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/inventory/additem
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Post</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="black-box-inside mb-2">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Fields</span>
                                </div>
                              </div>
                            </button>
                          </div>
                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              fields ={" "}
                            </p>
                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">[</span> <br />
                                product_id","no_of_item","location"
                                <br /> <span className="pink-text">]</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="black-box-inside">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Example</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              example ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">{"{"}</span>
                                <br />
                                "product_id": "4","no_of_item": "5","location":
                                "Gurgaon"
                                <br />
                                <span className="pink-text">{"}"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 9--------*/}
                  {/* ----------------- 10--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTen"
                      aria-expanded="true"
                      aria-controls="collapseTen"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To change Ownership of inventory Item
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapseTen"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/inventory/makeowner"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/inventory/makeowner"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/inventory/makeowner
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Post</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="black-box-inside mb-2">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Fields</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              fields ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">[</span> <br />
                                'email', 'inventory_id'
                                <br />
                                <span className="pink-text">]</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="black-box-inside">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Example</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              example ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">{"{"}</span> <br />
                                'email': 'aditya.uday9999@gmail.com' <br />
                                'inventory ID': '28'
                                <br />
                                <span className="pink-text">{"}"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 10--------*/}
                  {/* ----------------- 11--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse11"
                      aria-expanded="true"
                      aria-controls="collapse11"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To Get Inventory Item by id or display id
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapse11"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/inventory/getitem/INVE51"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/inventory/getitem/INVE51"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/inventory/getitem/INVE51
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Get</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 11--------*/}
                  {/* ----------------- 12--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse12"
                      aria-expanded="true"
                      aria-controls="collapse12"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To Upload CSV file to Inventory
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapse12"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/inventory/uploadcsvtostock"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/inventory/uploadcsvtostock"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/inventory/uploadcsvtostock
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          {/* <!-- <div className="left">
                                                <h3 className="fw600 fz16 m-0">
                                                </h3>
                                            </div> --> */}
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center">
                                  <p className="other-content primaryText m-0">
                                    <img src={bulb} alt="" />
                                    CSV header field must follow format i.e.
                                    {"{"}
                                    product,status,batch,manufecture_date,expire_date,warranty
                                    {"}"}
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Post</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="black-box-inside mb-2">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Fields</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              fields ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">[</span>
                                <br />
                                'csv_file'
                                <br />
                                <span className="pink-text">]</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="black-box-inside">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Example</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              example ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">{"{"}</span> <br />
                                &nbsp; <span className="pink-text">{"{"}</span>
                                <br />
                                &nbsp; &nbsp;
                                product,status,batch,manufecture_date,expire_date,warranty
                                <br />
                                <span className="pink-text">{"}"}</span>
                                <br />
                                &nbsp; &nbsp;
                                2,active,BATCH7,2023-09-30,2024-09-30,"2024-09-30"{" "}
                                <br />
                                &nbsp; &nbsp;
                                2,active,BATCH7,2023-09-30,2024-09-30,"2024-09-30"
                                <br />
                                &nbsp; &nbsp;
                                2,active,BATCH7,2023-09-30,2024-09-30,"2024-09-30"
                                <br /> &nbsp;{" "}
                                <span className="pink-text">{"}"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 12--------*/}
                  {/* ----------------- 13--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse13"
                      aria-expanded="true"
                      aria-controls="collapse13"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To Create Package in inventory
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapse13"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/inventory/createpackage"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/inventory/createpackage"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/inventory/createpackage
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Post</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="black-box-inside mb-2">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Fields</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              fields ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">[</span>
                                <br />
                                "brand_name","total_product","manufecture_date","expire_date","details"
                                <br />
                                <span className="pink-text">]</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="black-box-inside">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Example</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              example ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">{"{"}</span>
                                <br />
                                "brand_name": "Apple","total_product":
                                10,"manufecture_date":
                                "2023-09-10","expire_date":
                                "2024-09-10","details": "hello this is test"
                                <br />
                                <span className="pink-text">{"}"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 13--------*/}
                  {/* ----------------- 14--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse14"
                      aria-expanded="true"
                      aria-controls="collapse14"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To Get all Package info of user
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapse14"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/inventory/getpackage"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/inventory/getpackage"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/inventory/getpackage
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Get</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 14--------*/}
                  {/* ----------------- 15--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse15"
                      aria-expanded="true"
                      aria-controls="collapse15"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To Get a Package info user
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapse15"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/inventory/getpackage/5"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/inventory/getpackage/5"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/inventory/getpackage/5
                                  </p>
                                  <p className="primaryText m-0">
                                    Note: 5 is the Package ID
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Get</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 15--------*/}
                  {/* ----------------- 16--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse16"
                      aria-expanded="true"
                      aria-controls="collapse16"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To Add Item to Package
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapse16"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/inventory/additemtopackage"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/inventory/additemtopackage"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/inventory/additemtopackage
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Post</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="black-box-inside mb-2">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img s src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Fields</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              fields ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">[</span>
                                <br />
                                'package', 'content_data'
                                <br /> <span className="pink-text">]</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="black-box-inside">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Example</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              example ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">{"{"}</span>
                                <br />
                                "package": 5, <br />
                                "content_data": <br /> &nbsp; &nbsp;&nbsp;{" "}
                                <span className="pink-text">[</span>
                                <br />
                                &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                                <span className="pink-text">{"{"}</span> "type":
                                "item", "id": "123",{" "}
                                <span className="pink-text">{"}"}</span>
                                <br />
                                &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                                <span className="pink-text">{"{"}</span> "type":
                                "item", "id": "2345"{" "}
                                <span className="pink-text">{"}"}</span>
                                <br /> &nbsp; &nbsp;{" "}
                                <span className="pink-text">]</span>
                                <br />
                                <span className="pink-text">{"}"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 16--------*/}
                  {/* ----------------- 17--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse17"
                      aria-expanded="true"
                      aria-controls="collapse17"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To Unpack Package
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapse17"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          " https://api.holografs.com/inventory/unpackage"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/inventory/unpackage"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/inventory/unpackage
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Post</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="black-box-inside mb-2">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Fields</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              fields ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">[</span>
                                <br />
                                'package_id'
                                <br />
                                <span className="pink-text">]</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="black-box-inside">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Example</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              example ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">{"{"}</span>
                                <br />
                                "package_id": "2"
                                <br />
                                <span className="pink-text"> {"}"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 17--------*/}
                  {/* ----------------- 18--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse18"
                      aria-expanded="true"
                      aria-controls="collapse18"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To Get Itme of Package
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapse18"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          " https://api.holografs.com/inventory/getpackagecontent/5"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/product/addproductimage"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/inventory/getpackagecontent/5
                                  </p>
                                  <p className="primaryText m-0">
                                    Note: 5 is the Package ID
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Get</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 18--------*/}
                  {/* ----------------- 19--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse19"
                      aria-expanded="true"
                      aria-controls="collapse19"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To Send Package ownership Request
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapse19"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/inventory/requistpackageowner"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/inventory/requistpackageowner"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/inventory/requistpackageowner
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Post</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="black-box-inside">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Example</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              example ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">{"{"}</span>
                                <br />
                                "package": "12"
                                <br />
                                <span className="pink-text"> {" }"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 19--------*/}
                  {/* ----------------- 20--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse20"
                      aria-expanded="true"
                      aria-controls="collapse20"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To Get all Package owner request Send
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapse20"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/inventory/getallpackageownerrequestsend"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/inventory/getallpackageownerrequestsend"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/inventory/getallpackageownerrequestsend
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Get</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 20--------*/}
                  {/* ----------------- 21--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse21"
                      aria-expanded="true"
                      aria-controls="collapse21"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To get package owner request status
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapse21"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/inventory/getpackageownerrequeststatus/1"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/inventory/getpackageownerrequeststatus/1"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/inventory/getpackageownerrequeststatus/1
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">get</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 21--------*/}
                  {/* ----------------- 22--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse22"
                      aria-expanded="true"
                      aria-controls="collapse22"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To Approve package owner request
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapse22"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      s
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/inventory/approvepackageowner"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/inventory/approvepackageowner"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/inventory/approvepackageowner
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Post</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="black-box-inside">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Example</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              example ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">{"{"}</span>
                                <br />
                                "request_id": "1"
                                <br />
                                <span className="pink-text"> {"}"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 22--------*/}
                  {/* ----------------- 23--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse23"
                      aria-expanded="true"
                      aria-controls="collapse23"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To Add new WareHouse
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapse23"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/account/addwarehouse"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/account/addwarehouse"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/account/addwarehouse
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Post</span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="black-box-inside mb-2">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Fields</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              fields ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">[</span>
                                <br />
                                'id', 'name', 'capacity', 'contact',
                                'verification', 'address', 'address_location',
                                'details', 'type'
                                <br /> <span className="pink-text">]</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="black-box-inside">
                          <div className="d-flex align-items-center gap-2">
                            <button className="white-box">
                              <img src={urlPasteBlack} alt="" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-gradient-border endpoints-insideCardBtn rounded-1"
                            >
                              <div className="fw400 fz14">
                                <div className="px-3 gradientText--radial">
                                  <span className="fz16 fw600">Example</span>
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="endpoints-inside-text">
                            <p className="text-green fw400 fz12 mb-0">
                              example ={" "}
                            </p>

                            <div className="fz12 array-values">
                              <div className="orange-text">
                                <span className="pink-text">{"{"}</span> <br />
                                "name": "Aditya2", "capacity": "I don't Know",
                                "contact": "9999999999", "verification": "yes",
                                "address": "Patna", "address_location" :"Bihar",
                                "details":
                                <br />
                                <span className="pink-text">[</span> <br />
                                <span className="pink-text">{"{"}</span>{" "}
                                "a":"Apple","b":"Ball","c":"Cat"{" "}
                                <span className="pink-text">{"}"}</span>
                                <br />
                                <span className="pink-text">]</span> ,
                                "type":"Mobile"
                                <br />
                                <span className="pink-text">{"}"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 23--------*/}
                  {/* ----------------- 24--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse24"
                      aria-expanded="true"
                      aria-controls="collapse24"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To get all WareHouse
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapse24"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/account/getwarehouse"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value=" https://api.holografs.com/account/getwarehouse"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/account/getwarehouse
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">get</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 24--------*/}
                  {/* ----------------- 25--------*/}
                  <div className="accordion-item bdrs10 main-shadow accordian-widget">
                    <button
                      className="w-100 p-0 btn border-0 accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse25"
                      aria-expanded="true"
                      aria-controls="collapse25"
                    >
                      <div className="w-100 ts03 api-cards-header">
                        <div className="linebefore">
                          <span></span>
                          <span></span>
                        </div>
                        <span className="fz16 fw500 gradientText--radial">
                          To get an WareHouse
                        </span>
                      </div>
                    </button>

                    <div
                      id="collapse25"
                      className="accordion-collapse collapse"
                      data-bs-parent="#apiDocumentation"
                    >
                      <div className="api-cards-body bdrs10">
                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">URL</h3>
                          </div>
                          <ul className="list-inline linksURL">
                            <li>
                              <div className="endpoints-Insidewrapper">
                                <div className="d-flex align-items-center gap-2 right">
                                  <button className="box">
                                    <img
                                      src={urlPaste}
                                      onClick={() =>
                                        handleCopyClick(
                                          "https://api.holografs.com/account/getwarehouse/id"
                                        )
                                      }
                                      alt=""
                                    />
                                  </button>
                                  <textarea
                                    ref={textAreaRef}
                                    value="https://api.holografs.com/account/getwarehouse/id"
                                    readOnly
                                    style={{
                                      position: "absolute",
                                      left: "-9999px",
                                    }}
                                  />
                                  <p className="grayText m-0">
                                    https://api.holografs.com/account/getwarehouse/id
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <hr className="endpoint-card-divider" />

                        <div className="endpoint-card-wrapper">
                          <div className="left">
                            <h3 className="fw600 fz16 m-0">Method</h3>
                          </div>
                          <div className="endpoints-Insidewrapper">
                            <div className="d-flex align-items-center gap-2 right">
                              <span className="brnd_btn_black content-box m-0 text-uppercase">
                                <span className="m-0">Get</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ----------------- 25--------*/}
                </div>
              </div>
            </div>
          </section>
        </div>
        <BottomNavbar />
      </div>
    </>
  );
}
