import React from 'react'

export default function PageList() {
  return (
    <ul className="list-inline m-0 blobs-bg">
    <li>
      <div className="ellipse-blue01"></div>
    </li>
    <li>
      <div className="ellipse-purple01"></div>
    </li>
    <li>
      <div className="ellipse-yellow01"></div>
    </li>
    <li>
      <div className="ellipse-yellow02"></div>
    </li>
    <li>
      <div className="ellipse-purple02"></div>
    </li>
    <li>
      <div className="ellipse-blue02"></div>
    </li>
    <li>
      <div className="ellipse-orange01"></div>
    </li>
    <li>
      <div className="ellipse-purple03"></div>
    </li>
  </ul>
  )
}
