import React from "react";
import PageList from "../../../reusable_components/pageList";
import Navbar from "../../../layouts/navbar";
import BottomNavbar from "../../../layouts/bottomNavbar";
import apiDocHead from "../../../assets/images/icons/api-doc-head.svg";
import exclamatory from "../../../assets/images/icons/exclamatory-icon.svg";
import DocSidebar from "../docSidebar";

export default function SdksLibraries() {
  return (
    <div className="paye-layout">
      <PageList />
      <div className="content-layout">
        <Navbar />
        <section className="product-screen api-doc-screen">
          <DocSidebar />
          <div className="w-100 api-document m-0">
            <div className="api-doc-head d-flex align-items-center gap-2 gx-0">
              <img src={apiDocHead} alt="" />
              <span className="fw600 fz28 text-dark">SDKs and Libraries</span>
            </div>
            <div className="documentation">
              <h3 className="fw600 fz22 mb-2">Python</h3>
              <h3 className="fw300 fz20 mb-2">
                <span className="fw600">version - 3.10.0</span>{" "}
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                Python is a high-level, interpreted programming language known
                for its simplicity and readability. Python supports multiple
                programming paradigms, including procedural, object-oriented,
                and functional programming. It has a comprehensive standard
                library, which includes modules and packages for various tasks
                such as file I/O, networking, web development, and more.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                Overall, Python is known for its simplicity, readability, and
                the ability to facilitate rapid development, making it a
                language of choice for developers working in different domains.
              </h3>
              <br />
              <h3 className="fw600 fz22 mb-2">Django</h3>
              <h3 className="fw300 fz20 mb-2">
                <span className="fw600">version - 4.1</span>{" "}
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                Django is a high-level web framework written in Python that
                encourages rapid development and clean, pragmatic design. It
                follows the Model-View-Controller (MVC) architectural pattern,
                but in Django, it is often referred to as the
                Model-View-Template (MVT) pattern.
              </h3>
              <h3>
                <h3 className="fw600 fz24 mb-3">
                  Key features of Django include:
                </h3>

                <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                  <span className="fw600">
                    Object-Relational Mapping (ORM):
                  </span>{" "}
                  Django provides an abstraction layer for database
                  communication, allowing developers to interact with databases
                  using Python code instead of SQL queries directly.
                </h3>
                <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                  <span className="fw600">URL Routing:</span> Django uses a URL
                  dispatcher to route incoming web requests to the appropriate
                  view based on the URL pattern.
                </h3>
                <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                  <span className="fw600">Admin Interface:</span> Django comes
                  with a built-in admin interface that allows developers to
                  manage database records through a web-based UI. This is
                  particularly useful for quickly setting up an administration
                  panel for applications.
                </h3>
                <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                  <span className="fw600">Security Features:</span> Django
                  includes built-in protection against common web
                  vulnerabilities, such as SQL injection, cross-site scripting,
                  and cross-site request forgery.
                </h3>
                <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                  <span className="fw600">Middleware:</span> Django middleware
                  allows developers to process requests globally before they
                  reach the views, enabling features like authentication,
                  security checks, and more.
                </h3>
                <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                  <span className="fw600">
                    Internationalization and Localization:
                  </span>{" "}
                  Django supports the development of multilingual websites with
                  features for internationalization (i18n) and localization
                  (l10n).
                </h3>
              </h3>
              <br />
              <h3 className="fw600 fz22 mb-3"> PostgreSQL </h3>
              <h3 className="fw300 fz20 mb-2">
                <span className="fw600">version - 16.1</span>{" "}
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                PostgreSQL is an open-source relational database management
                system (RDBMS) that is known for its advanced features,
                extensibility, and standards compliance.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                PostgreSQL is commonly used for a wide range of applications,
                including web development, data warehousing, GIS (Geographic
                Information Systems), business intelligence, and more. It is
                considered a reliable and feature-rich choice for organizations
                and developers seeking a powerful open-source relational
                database system.
              </h3>
              <h3 className="fw600 fz24 mb-3">
                Key characteristics and features of PostgreSQL:
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">
                  Relational Database Management System (RDBMS):
                </span>{" "}
                PostgreSQL is a powerful and robust RDBMS that follows the
                relational model. It allows you to define, manipulate, and query
                data in a structured manner using tables, rows, and columns.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">Open Source:</span> PostgreSQL is
                released under the PostgreSQL License, which is a permissive
                open-source license. This means that it is free to use, modify,
                and distribute.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">ACID Compliance:</span>PostgreSQL
                ensures data integrity and consistency by adhering to the
                principles of ACID (Atomicity, Consistency, Isolation,
                Durability). This makes it suitable for applications that
                require a high level of reliability and transactional support.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">Extensibility:</span> : PostgreSQL is
                highly extensible, allowing users to define their data types,
                operators, functions, and aggregates. This extensibility makes
                it possible to customize and extend the functionality of the
                database.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">Scalability:</span> PostgreSQL is
                designed to scale both vertically (by adding more resources to a
                single server) and horizontally (by distributing data across
                multiple servers). It can handle large amounts of data and high
                transaction volumes.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">Concurrency Control: </span> PostgreSQL
                implements sophisticated concurrency control mechanisms,
                allowing multiple users to access the database simultaneously
                without compromising data consistency. It supports multi-version
                concurrency control (MVCC) to manage concurrent transactions.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">Indexes and Optimization: </span>
                PostgreSQL provides a variety of indexing techniques to optimize
                query performance. It includes B-tree, hash, and GiST
                (Generalized Search Tree) indexes, among others.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">Data Types: </span> In addition to
                standard SQL data types, PostgreSQL supports a wide range of
                custom data types, including geometric, network address, and
                JSON data types. This flexibility is useful for handling diverse
                data requirements.
              </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">Community and Ecosystem:</span>{" "}
                PostgreSQL has a vibrant and active open-source community that
                contributes to its development and maintenance. It also has a
                rich ecosystem of extensions, plugins, and tools that enhance
                its functionality.
              </h3>
              <br />{" "}
              <h3 className="fw600 fz22 mb-2"> Amazon Web Services (AWS) </h3>
              <h3 className="fz16 fw400 grayText first-letter-capital mb-3">
                Amazon Web Services (AWS) is a comprehensive and widely used
                cloud computing platform provided by Amazon.com. Launched in
                2006, AWS offers a broad set of infrastructure services,
                computing power, storage solutions, and other functionalities
                that allow businesses, organizations, and individuals to run
                applications and services in the cloud. AWS is known for its
                scalability, flexibility, and cost-effectiveness, making it a
                popular choice for a diverse range of use cases.
              </h3>
            </div>
          </div>
        </section>
      </div>
      <BottomNavbar />

      {/* <!-- Modal --> */}
      <div
        className="modal fade m-modal"
        id="sureModal"
        tabindex="-1"
        aria-labelledby="sureModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="border-0 modal-content">
            <div className="modal_wrapper border-white-2 bdrs10 main-shadow">
              <div className="main-block">
                <div className="content-inner">
                  <img src={exclamatory} alt="" className="mx-auto" />

                  <div className="inner-text">
                    <h3 className="fz60 fw300">Are You Sure!</h3>
                    <p className="fz46 mb-0 lh-1 fw600">
                      You want to cancel the process?
                    </p>
                  </div>

                  <div className="btn-row">
                    <button
                      data-bs-dismiss="modal"
                      className="btn my-2 fz14 bdrs10 btn-dark-outlined black-default"
                    >
                      <span className="fz14 gradientText--radial fw400">
                        No!
                      </span>
                    </button>

                    <a
                      href="./index.html"
                      className="btn my-2 fz14 bdrs10 btn-dark-outlined modal-confirm-btn"
                    >
                      <span className="fz14">Yes</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
