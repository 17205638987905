import {
  PROFILE_UPDATE_USER_SUCCESS,
  PROFILE_UPDATE_USER_FAILURE,
  PROFILE_GET_USER_SUCCESS,
  PROFILE_GET_USER_FAILURE,
  COMPANY_INFO_FAILURE,
  COMPANY_INFO_SUCCESS,
} from "./profile.actionTypes";

const initialState = {
Linkedin: "",
address: "",
bio: "",
email: "",
facebook: "",
fullname: "",
id: "",
is_active: false,
mc_hash: "",
mobile: "",
profile_image:"",
social_link:"",
role:"",
companyInfo:{},
};

export default function profileReducer(
  state = initialState,
  { type, responseData, payload}
) {
  switch (type) {
    case PROFILE_UPDATE_USER_SUCCESS:
      return {
          message: "",
        };
    case PROFILE_UPDATE_USER_FAILURE:
      return {
        message: "",
        }; 
      case PROFILE_GET_USER_SUCCESS:
      return {
        ...state,
        message: "",
        fullname: responseData.fullname,
        Linkedin: responseData.Linkedin,
        address: responseData.address,
        bio: responseData.bio,
        email: responseData.email,
        facebook: responseData.facebook,
        id: responseData.id,
        is_active: responseData.is_active,
        mc_hash: responseData.mc_hash,
        mobile: responseData.mobile,
        profile_image: responseData?.profile_image,
        social_link: responseData?.social_link,
        role: responseData.role,
      };
        case PROFILE_GET_USER_FAILURE:
      return {
        ...state, 
          message: "",
        };
        case COMPANY_INFO_SUCCESS:
          return {
            ...state,
            companyInfo: payload,
          };
    
        case COMPANY_INFO_FAILURE:
          return state;

    default:
      return state;
  }
}
