import {
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAILURE,
  GET_COLOR_SUCCESS,
  GET_COLOR_FAILURE,
  ADD_PRODUCT_DETAILS_SUCCESS,
  ADD_PRODUCT_DETAILS_FAILURE,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_FAILURE,
  GET_QR_SUCCESS,
  GET_QR_FAILURE,
  UPLOAD_QR_SUCCESS,
  UPLOAD_QR_FAILURE,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_DETAILS_FAILURE,
  UPDATE_PRODUCT_DETAILS_SUCCESS,
  LOADER_FALSE,
  LOADER_TRUE,
  GET_ALL_PRODUCTS_FAILURE,
  GET_ALL_PRODUCTS_SUCCESS,
} from "./singleProduct.actionTypes";

const initialState = {
  ColorArray: [],
  CategoryArray: [],
  AddedProduct: null,
  ProductDetails: null,
  product: null,
  details: null,
  QRpath: "",
  ownerDetailsArray: [],
  loader: false,
  AllProducts: [],
};

export default function singleProductReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        AddedProduct: payload,
      };

    case ADD_PRODUCT_FAILURE:
      return state;

    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        CategoryArray: payload,
      };

    case GET_CATEGORY_FAILURE:
      return state;

    case GET_COLOR_SUCCESS:
      return {
        ...state,
        ColorArray: payload,
      };

    case GET_COLOR_FAILURE:
      return state;

    case ADD_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        ProductDetails: payload,
      };

    case ADD_PRODUCT_DETAILS_FAILURE:
      return state;

    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: payload.productData,
        ownerDetailsArray: payload.ownerData,
      };

    case GET_PRODUCT_FAILURE:
      return state;

    case GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        details: payload,
      };
    case GET_PRODUCT_DETAILS_FAILURE:
      return state;

    case GET_QR_SUCCESS:
      return {
        ...state,
        QRpath: payload,
      };

    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        AllProducts: payload,
      };
    case GET_ALL_PRODUCTS_FAILURE:
      return {
        ...state,
        AllProducts: [],
      };
    case GET_QR_FAILURE:
      return state;

    case UPDATE_PRODUCT_SUCCESS:
      return state;

    case UPDATE_PRODUCT_FAILURE:
      return state;

    case UPDATE_PRODUCT_DETAILS_SUCCESS:
      return state;

    case UPDATE_PRODUCT_DETAILS_FAILURE:
      return state;

    case LOADER_FALSE:
      return {
        ...state,
        loader: false,
      };

    case LOADER_TRUE:
      return {
        ...state,
        loader: true,
      };

    default:
      return state;
  }
}
