import React from "react";
import PageList from "../../../reusable_components/pageList";
import Navbar from "../../../layouts/navbar";
import BottomNavbar from "../../../layouts/bottomNavbar";
import apiDocHead from "../../../assets/images/icons/api-doc-head.svg";
import exclamatory from "../../../assets/images/icons/exclamatory-icon.svg";
import DocSidebar from "../docSidebar";

export default function KeyManagement() {
  return (
    <div className="paye-layout">
      <PageList />
      <div className="content-layout">
        <Navbar />
        <section className="product-screen api-doc-screen">
          <DocSidebar />
          <div className="w-100 api-document m-0">
            <div className="api-doc-head d-flex align-items-center gap-2 gx-0">
              <img src={apiDocHead} alt="" />
              <span className="fw600 fz28 text-dark">Key Management</span>
            </div>

            <div className="documentation">
              <h2 className="fz16 fw400 grayText first-letter-capital mb-3">
                Managing API keys securely is crucial to prevent unauthorized
                access and ensure the integrity of your systems. Here are
                general instructions on how developers can manage API keys,
                including steps for revoking and regenerating keys:
              </h2>
              <br />
              <h4>Steps for Generating API Keys:</h4>
              <h2 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">1. Generate API Keys:</span>
                When creating API keys, ensure that they have the minimum
                necessary permissions to perform required actions. Avoid using
                overly permissive keys to minimize security risks.
              </h2>

              <h2 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">2. Secure Storage:</span>
                Store API keys securely. Avoid hardcoding them directly in your
                code or exposing them in public repositories. Use environment
                variables or a secure configuration file.
              </h2>

              <h2 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">3. Access Control:</span>
                Regularly review and update access controls associated with API
                keys. Remove unnecessary access or permissions that are no
                longer required.
              </h2>

              <h2 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">4. Key Rotation:</span>
                Implement a key rotation policy to regularly update API keys.
                This minimizes the risk associated with long-lived keys and
                potential exposure.
              </h2>

              <h2 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">5. Monitoring:</span>
                Implement monitoring and logging for API key usage. Regularly
                review logs for any unusual or suspicious activities that may
                indicate unauthorized access.
              </h2>

              <h2 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">6. Revocation:</span>
                Establish a process for revoking API keys when they are
                compromised, or access needs to be terminated for any reason.
              </h2>
            </div>

            <div className="documentation">
              <h4>Steps for Revoking API Keys:</h4>
              <h2 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">1. Identify Compromised Key:</span>
                Monitor logs and system activities to identify any suspicious
                behavior associated with an API key.
              </h2>

              <h2 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">2. Locate the Key:</span>
                Navigate to the section that manages API keys and locate the
                specific key that needs to be revoked.
              </h2>

              <h2 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">3. Revoke the Key:</span>
                Follow the provided options to revoke or disable the key. This
                action will immediately invalidate the key, preventing further
                use.
              </h2>

              <h2 className="fz16 fw400 grayText first-letter-capital mb-3">
                <span className="fw600">4. Update Applications:</span>
                If the key was used in applications, update them with the new
                key or alternative authentication methods.
              </h2>
            </div>
          </div>
        </section>
      </div>
      <BottomNavbar />

      {/* <!-- Modal --> */}
      <div
        className="modal fade m-modal"
        id="sureModal"
        tabindex="-1"
        aria-labelledby="sureModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="border-0 modal-content">
            <div className="modal_wrapper border-white-2 bdrs10 main-shadow">
              <div className="main-block">
                <div className="content-inner">
                  <img src={exclamatory} alt="" className="mx-auto" />

                  <div className="inner-text">
                    <h3 className="fz60 fw300">Are You Sure!</h3>
                    <p className="fz46 mb-0 lh-1 fw600">
                      You want to cancel the process?
                    </p>
                  </div>

                  <div className="btn-row">
                    <button
                      data-bs-dismiss="modal"
                      className="btn my-2 fz14 bdrs10 btn-dark-outlined black-default"
                    >
                      <span className="fz14 gradientText--radial fw400">
                        No!
                      </span>
                    </button>

                    <a
                      href="./index.html"
                      className="btn my-2 fz14 bdrs10 btn-dark-outlined modal-confirm-btn"
                    >
                      <span className="fz14">Yes</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
