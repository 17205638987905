import axios from "axios";
import { GET_ALL_COUNTERFEIT_COMPLAIN, GET_ITEM_DETAILS, GET_PRODUCT, GET_PRODUCT_DETAILS, GET_USER_DETAILS, POST_COUNTERFEIT_COMPLAIN } from "../../services/Api";
import { TRACKING_BY_ID_FAILURE, TRACKING_BY_ID_SUCCESS,PACKAGE_USER_DETAILS_FAILURE,PACKAGE_USER_DETAILS_SUCCESS,INVENTORY_USER_DETAILS_FAILURE,INVENTORY_USER_DETAILS_SUCCESS,INVENTORY_GET_SINGLE_PRODUCT_SUCCESS,INVENTORY_GET_SINGLE_PRODUCT_FAILURE, INVENTORY_GET_SINGLE_PRODUCT_DETAILS_SUCCESS, INVENTORY_GET_SINGLE_PRODUCT_DETAILS_FAILURE, COUNTERFEIT_COMPLAIN_SUCCESS, COUNTERFEIT_COMPLAIN_FAILURE, ALL_COUNTERFEIT_COMPLAIN_SUCCESS, ALL_COUNTERFEIT_COMPLAIN_FAILURE } from "./tracking.action";

export const getAllProductsById = (id) => async (dispatch) => {
    const token = JSON.parse(localStorage.getItem("token")) || null;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      let resp = await axios.get(`${GET_ITEM_DETAILS}/${id}`, { headers });
      let data = resp?.data;
      
      if (data) {
        dispatch({
          type: TRACKING_BY_ID_SUCCESS,
          payload: data,
        });
      }
    } catch (err) {
      console.log({ Error: err });
      dispatch({
        type: TRACKING_BY_ID_FAILURE,
      });
    }
  };

export const getpackageUserDetails =(id) => async (dispatch) => {
       const token = JSON.parse(localStorage.getItem("token")) || null;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      try {
        let res = await axios.get(`${GET_USER_DETAILS}/${id}`, {
          headers,
        });
        let ownerDetails = res?.data;
    
        dispatch({
          type: PACKAGE_USER_DETAILS_SUCCESS,
          payload: ownerDetails,
        });
        
      } catch (err) {
        console.log({ EROOR: err });
        dispatch({
          type: PACKAGE_USER_DETAILS_FAILURE,
        });
      }
    };


export const getInventoryserDetails = (id) => async (dispatch) => {
      const token = JSON.parse(localStorage.getItem("token")) || null;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      try {
        let res = await axios.get(`${GET_USER_DETAILS}/${id}`, {
          headers,
        });
        let ownerDetails = res?.data;

        dispatch({
          type: INVENTORY_USER_DETAILS_SUCCESS,
          payload: ownerDetails,
        });
      } catch (err) {
        console.log({ EROOR: err });
        dispatch({
          type: INVENTORY_USER_DETAILS_FAILURE,
        });
      }
    };
    

export const getSingleProductInfo = (id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let res = await axios.get(`${GET_PRODUCT}/${id}`, {
      headers,
    });
    let data = res?.data;

    dispatch({
      type: INVENTORY_GET_SINGLE_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log({ EROOR: err });
    dispatch({
      type: INVENTORY_GET_SINGLE_PRODUCT_FAILURE,
    });
  }
};  

export const getSingleProductDetails = (id) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let res = await axios.get(`${GET_PRODUCT_DETAILS}/${id}`, {
      headers,
    });
    let data = res?.data?.detail;

    dispatch({
      type: INVENTORY_GET_SINGLE_PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log({ EROOR: err });
    dispatch({
      type: INVENTORY_GET_SINGLE_PRODUCT_DETAILS_FAILURE,
    });
  }
}; 

export const counterfeitComplain = (data) => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  // const headers = {
  //   Authorization: `Bearer ${token}`,
  //   "Content-Type": "application/json",
  // };
  // dispatch({ type: LOADER_TRUE });
  try {
    const resp = await axios.post(POST_COUNTERFEIT_COMPLAIN, data, );
    if (resp.data) {
      dispatch({
        type: COUNTERFEIT_COMPLAIN_SUCCESS,
        payload: resp?.data,
      });
    } 
     else {
      dispatch({
        type: COUNTERFEIT_COMPLAIN_FAILURE,
        payload: {
          message: "",
        },
      });
    }
    // dispatch({ type: LOADER_FALSE });
  } catch (error) {
    dispatch({
      type: COUNTERFEIT_COMPLAIN_FAILURE,
      payload: {
        message: error,
      },
    });
    // dispatch({ type: LOADER_FALSE });
  }
};

export const getAllCounterfeit = () => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("token")) || null;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    let res = await axios.get(`${GET_ALL_COUNTERFEIT_COMPLAIN}`, {
      headers,
    });
    let data = res?.data;

    dispatch({
      type: ALL_COUNTERFEIT_COMPLAIN_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log({ EROOR: err });
    dispatch({
      type: ALL_COUNTERFEIT_COMPLAIN_FAILURE,
    });
  }
}; 


