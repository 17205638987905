import {
  GET_OWNER,
  REQUEST_OWNERSHIP,
  GET_ALL_REQUEST,
  APPROVE_OWNERSHIP,
  GET_OWNERSHIP_STATUS,
  GET_ALL_SENT_REQUEST,
} from "../../services/Api";

import {
  GET_OWNER_SUCCESS,
  GET_OWNER_FAILURE,
  REQUEST_OWNERSHIP_SUCCESS,
  REQUEST_OWNERSHIP_FAILURE,
  APPROVE_OWNERSHIP_SUCCESS,
  APPROVE_OWNERSHIP_FAILURE,
  GET_OWNERSHIP_STATUS_SUCCESS,
  GET_OWNERSHIP_STATUS_FAILURE,
  GET_ALL_SENT_REQUEST_SUCCESS,
  GET_ALL_SENT_REQUEST_FAILURE,
  GET_ALL_RECEIVED_REQUEST_SUCCESS,
  GET_ALL_RECEIVED_REQUEST_FAILURE,
  GET_OWNER_DETAILS_FAILURE,
  GET_OWNER_DETAILS_SUCCESS,
} from "./ownership.actionTypes";

const initialState = {
  currentStatus: "",
  ownerId: null,
  requestId: null,
  ownerDetails: [].slice(0).reverse(),
  ProductDetails: null,
  ownerActive: false,
  AllReceivedRequestArray: [],
};

export default function ownerShipReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case GET_ALL_SENT_REQUEST_SUCCESS:
      return {
        ...state,
        currentStatus: payload,
      };

    case GET_ALL_SENT_REQUEST_FAILURE:
      return state;

    case GET_OWNER_DETAILS_SUCCESS:
      return {
        ...state,
        ownerDetails: [...state.ownerDetails, payload.ownerDetails],
        ownerActive: payload.ownerActive,
      };
    case GET_OWNER_DETAILS_FAILURE:
      return {
        ...state,
        ownerDetails: [],
        ownerActive: false,
      };

    case GET_OWNERSHIP_STATUS_SUCCESS:
      return {
        ...state,
        currentStatus: payload,
      };
    case GET_OWNERSHIP_STATUS_FAILURE:
      return {
        ...state,
        currentStatus: "",
      };

    case REQUEST_OWNERSHIP_SUCCESS:
      return {
        ...state,
        requestId: payload,
      };
    case REQUEST_OWNERSHIP_FAILURE:
      return {
        ...state,
        requestId: null,
      };

    case GET_OWNER_SUCCESS:
      return {
        ...state,
        currentStatus: "",
        ownerDetails: [],
      };

    case GET_OWNER_FAILURE:
      return {
        ...state,
        currentStatus: "",
        ownerDetails: [],
      };

    case GET_ALL_RECEIVED_REQUEST_SUCCESS:
      return {
        ...state,
        AllReceivedRequestArray: payload,
      };

    case GET_ALL_RECEIVED_REQUEST_FAILURE:
      return {
        ...state,
        AllReceivedRequestArray: [],
      };

    case APPROVE_OWNERSHIP_SUCCESS:
      return state;

    case APPROVE_OWNERSHIP_FAILURE:
      return state;

    default:
      return state;
  }
}
