import React from "react";
import PageList from "../../../reusable_components/pageList";
import Navbar from "../../../layouts/navbar";
import BottomNavbar from "../../../layouts/bottomNavbar";
import apiDocHead from "../../../assets/images/icons/api-doc-head.svg";
import exclamatory from "../../../assets/images/icons/exclamatory-icon.svg";
import urlPasteBlack from "../../../assets/images/icons/url-paste-black.svg";
import DocSidebar from "../docSidebar";

export default function ApiKeyGeneration() {
  return (
    <div className="paye-layout">
      <PageList />
      <div className="content-layout">
        <Navbar />
        <section className="product-screen api-doc-screen">
          <DocSidebar />
          <div className="w-100 api-document m-0">
            <div className="api-doc-head d-flex align-items-center gap-2 gx-0">
              <img src={apiDocHead} alt="" />
              <span className="fw600 fz28 text-dark">API Key Generation</span>
            </div>

            <div className="documentation">
              <h3 className="fz16 fw400 grayText first-letter-capital mb-2">
                - We use Token Authentication, every request must have access
                token in request header
              </h3>
            </div>
            <div className="black-box-inside mb-2">
              <div className="d-flex align-items-center gap-2">
                <button className="white-box">
                  <img src={urlPasteBlack} alt="" />
                </button>
              </div>
              <div className="endpoints-inside-text">
                <div className="fz12 array-values">
                  <div className="orange-text">
                    <span className="yellow-text">const</span> token =
                    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzAzMjQzMzI1LCJpYXQiOjE3MDMyMjE3MjUsImp0aSI6ImE4NmVlNDQyMDNkMTQ4YWNhMWQ5MTk3YjY3Y2Y3ZDk5IiwidXNlcl9pZCI6Nn0.X90yfNJYCwhr2Ho-PyEsBWZMV7dvuhtbO7QWnomJodk"
                    <div className="mt-3">
                      <span className="yellow-text">const</span> headers ={" "}
                      <br /> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;{" "}
                      <span className="pink-text">{"{"}</span>
                      <br /> &nbsp; &nbsp;{" "}
                      <span>
                        {" "}
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Authorization:
                        `Bearer ${"{"}token{"}"}`,
                      </span>
                      <br />{" "}
                      <span>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp;&nbsp;"Content-Type": "application/json",
                      </span>{" "}
                      <br /> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                      <span className="pink-text">{"}"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <BottomNavbar />

      {/* <!-- Modal --> */}
      <div
        className="modal fade m-modal"
        id="sureModal"
        tabindex="-1"
        aria-labelledby="sureModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="border-0 modal-content">
            <div className="modal_wrapper border-white-2 bdrs10 main-shadow">
              <div className="main-block">
                <div className="content-inner">
                  <img src={exclamatory} alt="" className="mx-auto" />

                  <div className="inner-text">
                    <h3 className="fz60 fw300">Are You Sure!</h3>
                    <p className="fz46 mb-0 lh-1 fw600">
                      You want to cancel the process?
                    </p>
                  </div>

                  <div className="btn-row">
                    <button
                      data-bs-dismiss="modal"
                      className="btn my-2 fz14 bdrs10 btn-dark-outlined black-default"
                    >
                      <span className="fz14 gradientText--radial fw400">
                        No!
                      </span>
                    </button>

                    <a
                      href="./index.html"
                      className="btn my-2 fz14 bdrs10 btn-dark-outlined modal-confirm-btn"
                    >
                      <span className="fz14">Yes</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
