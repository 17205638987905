import React from "react";
import apiDocHead from "../../../assets/images/icons/api-doc-head.svg";
import enhancedEffeciency from "../../../assets/images/icons/enhanced-efficiency.svg";
import transparencyTrust from "../../../assets/images/icons/transparency-trust.svg";
import dataBackend from "../../../assets/images/icons/data-backend.svg";
import exclamatory from "../../../assets/images/icons/exclamatory-icon.svg";
import { Link } from "react-router-dom";
import PageList from "../../../reusable_components/pageList";
import Navbar from "../../../layouts/navbar";
import BottomNavbar from "../../../layouts/bottomNavbar";
import DocSidebar from "../docSidebar";

export default function Introduction() {
  return (
    <>
      <div className="paye-layout">
        <PageList />
        <div className="content-layout">
          <Navbar />
          <section className="product-screen api-doc-screen">
            <DocSidebar />
            <div className="w-100 api-document m-0">
              <div className="api-doc-head d-flex align-items-center gap-2 gx-0">
                <img src={apiDocHead} alt="" />
                <span className="fw600 fz28 text-dark">API Documentation</span>
              </div>
              <div className="documentation">
                <h3 className="fw600 fz22 m-0">Introduction:</h3>
                <hr className="doc-divider" />
                <p className="fz16 fw400 grayText first-letter-capital mb-3">
                  Holografs offers a range of APIs that empower businesses to
                  seamlessly integrate and leverage our cutting-edge supply
                  chain management platform. These APIs provide access to
                  real-time tracking, traceability, and analytics
                  functionalities, ensuring end- to-end transparency in the
                  product journey. Holografs' APIs are designed to enhance
                  supply chain efficiency, product authenticity verification,
                  and data-driven decision-making. They play a pivotal role in
                  revolutionizing how businesses manage their supply chains,
                  fostering trust, and reliability.
                </p>

                <h3 className="fw600 fz22 m-0">Purpose</h3>
                <hr className="doc-divider" />
                <p className="fz16 fw400 grayText first-letter-capital mb-3">
                  Holograf's APIs offer seamless integration, real-time
                  visibility, product authenticity verification, and data-driven
                  insights. They bridge supply chain systems, provide real-time
                  tracking, ensure product authenticity, and offer valuable data
                  for optimized decision-making.
                </p>

                <h3 className="fw600 fz22 m-0">Benifits</h3>
                <hr className="doc-divider" />

                <div className="row benificial-cards-row row-cols-1 row-cols-md-3 g-4">
                  <div className="col">
                    <div className="border-0 card block-widget p-0 h-100">
                      <div className="api-cards-header">
                        <img src={enhancedEffeciency} alt="" />
                        <span className="fz16 fw500 gradientText--radial">
                          Enhanced Efficiency
                        </span>
                      </div>
                      <div className="card-body api-cards-body bdrs10 border-white-2">
                        <p className="card-text grayText fw400 fz16">
                          Holograf's APIs streamline supply chain operations,
                          reducing manual tasks and errors. This efficiency
                          translates to cost savings and faster delivery times.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="border-0 card block-widget p-0 h-100">
                      <div className="api-cards-header">
                        <img src={transparencyTrust} alt="" />
                        <span className="fz16 fw500 gradientText--radial">
                          Transparency and Trust
                        </span>
                      </div>
                      <div className="card-body api-cards-body bdrs10 border-white-2">
                        <p className="card-text grayText fw400 fz16">
                          With real-time tracking and authenticity verification,
                          your customers and partners have increased trust in
                          the integrity of your products and supply chain.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card  block-widget p-0 h-100">
                      <div className="api-cards-header">
                        <img src={dataBackend} alt="" />
                        <span className="fz16 fw500 gradientText--radial">
                          Data Backend Decisions
                        </span>
                      </div>
                      <div className="card-body api-cards-body bdrs10 border-white-2">
                        <p className="card-text grayText fw400 fz16">
                          The data and analytics provided by these APIs enable
                          you to make informed decisions, identify trends, and
                          respond proactively to supply chain challenges.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <BottomNavbar />

        {/* <!-- Modal --> */}
        <div
          className="modal fade m-modal"
          id="sureModal"
          tabindex="-1"
          aria-labelledby="sureModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="border-0 modal-content">
              <div className="modal_wrapper border-white-2 bdrs10 main-shadow">
                <div className="main-block">
                  <div className="content-inner">
                    <img src={exclamatory} alt="" className="mx-auto" />

                    <div className="inner-text">
                      <h3 className="fz60 fw300">Are You Sure!</h3>
                      <p className="fz46 mb-0 lh-1 fw600">
                        You want to cancel the process?
                      </p>
                    </div>

                    <div className="btn-row">
                      <button
                        data-bs-dismiss="modal"
                        className="btn my-2 fz14 bdrs10 btn-dark-outlined black-default"
                      >
                        <span className="fz14 gradientText--radial fw400">
                          No!
                        </span>
                      </button>

                      <a
                        href="./index.html"
                        className="btn my-2 fz14 bdrs10 btn-dark-outlined modal-confirm-btn"
                      >
                        <span className="fz14">Yes</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
