import {
  TRACKING_BY_ID_FAILURE,
  TRACKING_BY_ID_SUCCESS,
  INVENTORY_USER_DETAILS_FAILURE,
  INVENTORY_USER_DETAILS_SUCCESS,
  PACKAGE_USER_DETAILS_FAILURE,
  PACKAGE_USER_DETAILS_SUCCESS,
  INVENTORY_GET_SINGLE_PRODUCT_SUCCESS,
  INVENTORY_GET_SINGLE_PRODUCT_FAILURE,
  INVENTORY_GET_SINGLE_PRODUCT_DETAILS_FAILURE,
  INVENTORY_GET_SINGLE_PRODUCT_DETAILS_SUCCESS,
  COUNTERFEIT_COMPLAIN_SUCCESS,
  COUNTERFEIT_COMPLAIN_FAILURE,
  ALL_COUNTERFEIT_COMPLAIN_SUCCESS,
  ALL_COUNTERFEIT_COMPLAIN_FAILURE
} from "./tracking.action";

const initialState = {
    trackingDetails:null,
    PackageUserInfo:{},
    InventoryUserInfo:{},
    SingleproductInfo:{},
    SingleProductAdditionalDetails:"",
    complainList: null,
    allComplaint: null,
};

export default function trackingReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case TRACKING_BY_ID_SUCCESS:
      return {
        ...state,
        trackingDetails: payload,
      };
    case TRACKING_BY_ID_FAILURE:
      return {
        ...state,
        message: "",
      };

    case PACKAGE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        PackageUserInfo: payload,
      };

    case PACKAGE_USER_DETAILS_FAILURE:
      return {
        ...state,
        PackageUserInfo: {},
      };

    case INVENTORY_USER_DETAILS_SUCCESS:
      return {
        ...state,
        InventoryUserInfo: payload,
      };

    case INVENTORY_USER_DETAILS_FAILURE:
      return {
        ...state,
        InventoryUserInfo: {},
      };

    case INVENTORY_GET_SINGLE_PRODUCT_SUCCESS:
      return {
        ...state,
        SingleproductInfo: payload,
      };

    case INVENTORY_GET_SINGLE_PRODUCT_FAILURE:
      return {
        ...state,
        SingleproductInfo: {},
      };

    case INVENTORY_GET_SINGLE_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        SingleProductAdditionalDetails: payload,
      };
    case INVENTORY_GET_SINGLE_PRODUCT_DETAILS_FAILURE:
      return {
        ...state,
        SingleProductAdditionalDetails: "",
      };

      case COUNTERFEIT_COMPLAIN_SUCCESS:
        return {
          ...state,
          complainList: payload,
        };
      case COUNTERFEIT_COMPLAIN_FAILURE:
        return {
          ...state,
          complainList: "",
        };
        case ALL_COUNTERFEIT_COMPLAIN_SUCCESS:
          return {
            ...state,
            allComplaint: payload,
          };
          case ALL_COUNTERFEIT_COMPLAIN_FAILURE:
            return {
              ...state,
              allComplaint: "",
            };

    default:
      return state;
  }
}
