import {
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
} from "./userInfo.actionTypes";
import { GET_USER_DETAILS, GET_ACTIVE_USER } from "../.././services/Api";



const initialState = {
 
};

export default function userInfoReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
}
