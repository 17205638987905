import {
    PACKAGING_LEVEL1_ALL_PACKAGE_SUCCESS,
    PACKAGING_LEVEL1_ALL_PACKAGE_FAILURE
  } from "./packagingLevel1.actionTypes";
  
  const initialState = {
    allPackage: null
  };
  
  export default function packagingLevel1Reducer(state = initialState, { type, payload }) {
    switch (type) {
        case PACKAGING_LEVEL1_ALL_PACKAGE_SUCCESS:
            return {
              ...state,
              allPackage: payload,
            };
      
          case PACKAGING_LEVEL1_ALL_PACKAGE_FAILURE:
            return state;
  
      default:
        return state;
    }
  }
  