import React, { useEffect } from 'react';
import ContactSection from './contentSection';
import { ToastContainer } from 'react-toastify';

export default function SecLandingPage() {

  useEffect(() => {
    if (!window.initFadeInAnimation) {
      let script = document.createElement("script");
      script.id = "scriptId";
      script.innerHTML = `
      window.initFadeInAnimation = () => {
        const fadeElements = document.querySelectorAll(".fade-in");
        
        fadeElements.forEach((element) => {
          let classesArray = Array.from(element.classList);
          let matchingClass = classesArray.find(className => className.startsWith("fade-in-delay-"));
          let delayValue = 0;
          if (matchingClass) {
            delayValue = matchingClass.slice("fade-in-delay-".length);
          }
          
          gsap.fromTo(element, {
              opacity: 0, 
              y: 100,
            }, {
              opacity: 1,
              y: 0,
              duration: 1,
              delay: delayValue,

              scrollTrigger: {
                trigger: element,
                start: "top 90%",
                end: "bottom 0%",
                toggleActions: "play none none reverse",
              },
          });
        });
      }
      window.initFadeInAnimation();
      `;
      document.body.append(script);
    }

    return () => {
      const scriptElement = document.getElementById("scriptId");
      if (scriptElement) {
        document.body.removeChild(scriptElement);
        delete window.initFadeInAnimation; // Clean up the function from global scope
      }
    };
  }, []);

  return (
    <>
      <ContactSection logo={true} />
      <ToastContainer />
    </>
  )
};
