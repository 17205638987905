// OwnerShip Flow



export const GET_INVENTORY_OWNER_SUCCESS = "inventory/ownership/GET_OWNER_SUCCESS";
export const GET_INVENTORY_OWNER_FAILURE = "inventory/ownership/GET_OWNER_FAILURE";
export const REQUEST_INVENTORY_OWNERSHIP_SUCCESS =
  "inventory/ownership/REQUEST_OWNERSHIP_SUCCESS";
export const REQUEST_INVENTORY_OWNERSHIP_FAILURE =
  "inventory/ownership/REQUEST_OWNERSHIP_FAILURE";
export const APPROVE_INVENTORY_OWNERSHIP_SUCCESS =
  "inventory/ownership/APPROVE_OWNERSHIP_SUCCESS";
export const APPROVE_INVENTORY_OWNERSHIP_FAILURE =
  "inventory/ownership/APPROVE_OWNERSHIP_FAILURE";
export const GET_INVENTORY_OWNERSHIP_STATUS_SUCCESS =
  "inventory/ownership/GET_OWNERSHIP_STATUS_SUCCESS";
export const GET_INVENTORY_OWNERSHIP_STATUS_FAILURE =
  "inventory/ownership/GET_OWNERSHIP_STATUS_FAILURE";
export const GET_INVENTORY_ALL_SENT_REQUEST_SUCCESS =
  "inventory/ownership/GET_ALL_SENT_REQUEST_SUCCESS";
export const GET_INVENTORY_ALL_SENT_REQUEST_FAILURE =
  "inventory/ownership/GET_ALL_SENT_REQUEST_FAILURE";
export const GET_INVENTORY_OWNER_DETAILS_SUCCESS =
  "inventory/ownership/GET_OWNER_DETAILS_SUCCESS";
export const GET_INVENTORY_OWNER_DETAILS_FAILURE =
  "inventory/ownership/GET_OWNER_DETAILS_FAILURE";
export const GET_INVENTORY_ALL_RECEIVED_REQUEST_SUCCESS =
  "inventory/ownership/GET_ALL_RECEIVED_REQUEST_SUCCESS";
export const GET_INVENTORY_ALL_RECEIVED_REQUEST_FAILURE =
  "inventory/ownership/GET_ALL_RECEIVED_REQUEST_FAILURE";
