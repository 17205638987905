import {UPDATE_NEWUSER_FAILURE,UPDATE_NEWUSER_SUCCESS,GET_ALL_NEWUSER_FAILURE,GET_ALL_NEWUSER_SUCCESS,POST_NEWUSER_FAILURE,POST_NEWUSER_SUCCESS,REMOVE_NEWUSER_FAILURE,REMOVE_NEWUSER_SUCCESS} from "./adduser.actionTypes";


const initialState = {
    AllNewUser:[],

  };
  
export default function AddUserReducer(
    state = initialState,
    { type, payload }
  ) {
    switch (type) {
      case POST_NEWUSER_SUCCESS:
        return {
          ...state,
         
        };

        case POST_NEWUSER_FAILURE:
        return {
          ...state,
         
        };

        case GET_ALL_NEWUSER_SUCCESS:
        return {
          ...state,
          AllNewUser:payload
        };

        case GET_ALL_NEWUSER_FAILURE:
        return {
          ...state,
          AllNewUser:[]
        };
        case REMOVE_NEWUSER_SUCCESS:
        return {
          ...state,
         
        };
        case REMOVE_NEWUSER_FAILURE:
        return {
          ...state,
         
        };

        case UPDATE_NEWUSER_SUCCESS:
        return {
          ...state,
         
        };

        case UPDATE_NEWUSER_FAILURE:
            return {
              ...state,
             
            };
      
      default:
        return state;
    }
  }